import React, { useCallback } from "react";
import "./ContactForm.scss";
import { Form, Formik } from "formik";
import { contactSchema } from "../../../../helpers/validation";
import AppFormInput from "../../../../components/ui/forms/controls/AppFormInput";
import AppButton from "../../../../components/ui/components/Buttons/AppButton/AppButton";
import AppFormTextArea from "../../../../components/ui/forms/controls/AppFormTextArea";
import contactAPI from "../../../../api/contactAPI";
import appToastr from "../../../../services/appToastr";
import { FormikHelpers } from "formik/dist/types";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ContactFormRequestData } from "../../../../types/contact-form";
import { phoneMask } from "../../../../config";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [t] = useTranslation();

  const onSubmit = useCallback(async (data: ContactFormRequestData, formikHelpers: FormikHelpers<ContactFormRequestData>) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('');
    if (token) {
      contactAPI.sendForm({...data, 'g-recaptcha-response': token}).then(() => {
        appToastr.success('Форма відправлена', 'Успішно')
      }).catch((error) => {
        if (error?.data?.errors) {
          formikHelpers.setErrors(error?.data?.errors)
        }
      })
    }

  }, [executeRecaptcha])


  const initialValues = {
    name: '',
    phone: '',
    email: '',
    comment: '',
  };

  return (
    <div className="contact-form">
      <h3>{t("Звяжіться з нами")}</h3>
      <h5>{t("Єдина мета Bronui – зробити вас успішними! Тому працює наша спеціальна команда підтримки")}</h5>
      <Formik
        initialValues={initialValues}
        validationSchema={contactSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div>
            <AppFormInput name="name" label="Ім'я"/>
            <AppFormInput
              name="phone"
              label={t("Номер телефону")}
              alwaysShowMask={true}
              mask={phoneMask}
              unmask
            />
            <AppFormInput name="email" label="E-mail"/>
            <AppFormTextArea name="comment" label={t("Коментар")} rows={4}/>
          </div>
          <div style={{ alignSelf: 'flex-end' }}>
            <AppButton theme="primary" size="middle" className="sign-up__submit sign-up__full" showIndicator={false}>
              {t("Відправити")}
            </AppButton>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default ContactForm;