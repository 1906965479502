import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { phoneSchema } from '../../../../helpers/validation';
import AppButton from '../../../../components/ui/components/Buttons/AppButton/AppButton';
import AppFormInput from '../../../../components/ui/forms/controls/AppFormInput';
import { FormikHelpers } from 'formik/dist/types';
import { useTranslation } from "react-i18next";
import { phoneMask } from "../../../../config";

interface Values {
   login: string;
}

interface IProps {
   onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
   isSending: boolean;
}

const initialValues = {
   login: '',
};

const FormPhone: FC<IProps> = ({ onSubmit, children, isSending }) => {
   const [t] = useTranslation();
   return (
      <Formik initialValues={initialValues} validationSchema={phoneSchema} onSubmit={onSubmit}>
         <Form>
            <div>
               <AppFormInput
                  role="phone"
                  name="login"
                  label={t("Номер вашого телефону")}
                  alwaysShowMask={true}
                  mask={phoneMask}
                  unmask
               />
               <AppButton theme="primary" size="middle" className="sign-up__submit" showIndicator={isSending}>
                  {t("Продовжити")}
               </AppButton>
            </div>
            {/*{children && <div style={{ alignSelf: 'flex-end' }}>{children}</div>}*/}
         </Form>
      </Formik>
   );
};

export default FormPhone;
