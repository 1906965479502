import React, { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import './Page.scss';
import Header from '../../layout/components/Header/Header';
import { Media } from '../Media/Media';
import HeaderMobile from '../../layout/components/HeaderMobile/HeaderMobile';
import useInitial from '../../../hooks/useInitial';
import ToolbarMobile from '../../layout/components/ToolbarMobile/ToolbarMobile';
import useResizeObserver from '../../../hooks/useResizeObserver';
import Footer from '../../layout/components/Footer/Footer';
import { isBronui, isSudtirol } from "../../../config";
import FooterSudtirol from "../../layout/components/sudtirol/FooterSudtirol/FooterSudtirol";

interface Props {
    isSearchFormOpen?: boolean;
    className?: string;
    hideSearchHeader?: boolean
    hideSearchForm?: boolean
}

const Page: FC<Props> = props => {
    const location = useLocation();
    useInitial();

    const {children, isSearchFormOpen, className, hideSearchHeader, hideSearchForm} = props;
    const isIndexPage = location.pathname === '/';
    const isHotelsListPage = location.pathname === '/hotels';

    const classes = classNames('page', {'page--home': isIndexPage}, className);

    const ref = useRef();

    useResizeObserver(ref, (height, width) => {
        document.documentElement.style.setProperty('--header-height', height + "px");
        window.scrollTo({top: window.scrollY - 1});
    });

    return (
        <div className={classes}>
            {!hideSearchHeader && (
                <div className="page__header">
                    <Media at="sm">
                        <HeaderMobile isIndexPage={isIndexPage} ref={ref} hideSearchForm={hideSearchForm}/>
                    </Media>

                    <Media greaterThan="sm">
                        {/*@ts-ignore*/}
                        <Header
                            isIndexPage={isIndexPage}
                            isHotelsListPage={isHotelsListPage}
                            isSearchFormOpen={isSearchFormOpen}
                            hideSearchForm={hideSearchForm}
                            ref={ref}
                        />
                    </Media>
                </div>
            )}

            <div className="page__content">{children}</div>
            {
                isBronui ?   <Footer/> : null
            }
            {
                isSudtirol ?  <FooterSudtirol/> : null
            }

            <Media at="sm">
                <ToolbarMobile/>
            </Media>
        </div>
    );
};

export default Page;
