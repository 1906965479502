import React, { FC } from "react"
import ModalLayoutPrimary
  from "../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary";
import AppButton from "../../../../components/ui/components/Buttons/AppButton/AppButton";
import Modal from "../../../../components/ui/modals/Modal/Modal";
import "./ShouldAuthModal.scss";
import { useTranslation } from "react-i18next";

interface IProps {
  onClose: () => void;
  title: string;
}


const ShouldAuthModal: FC<IProps> = ({ onClose,title }) => {
  const [t] = useTranslation();

  return (
    <Modal onClose={onClose} className="should-auth-modal" disableFullSizeOnMobile>
      <ModalLayoutPrimary title={t("Увійдіть або зареєструйтеся")} noPadding>
        <div className="should-auth-modal__wrapper">
          <h2>{title}</h2>
          <div className="should-auth-modal__navigation">
            <AppButton theme="outline" to="/auth/signup">{t("Зареєструватися")}</AppButton>
            <AppButton to="/auth/signin">{t("Увійти")}</AppButton>
          </div>
        </div>

      </ModalLayoutPrimary>
    </Modal>
  )
}

export default ShouldAuthModal;
