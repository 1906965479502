import {createSlice} from "@reduxjs/toolkit";
import { getInitialThunk } from './initial-thunks';
import { InitialData } from '../../types/initial';
import { defaultLanguage, defaultCurrency } from '../../config';
import {Cookies} from "react-cookie";

interface Initial {
  language: string;
  currency: string;
  data: InitialData;
  error?: any;

}

const cookies = new Cookies();
// cookies.set('currency', 'EUR', {path: '/'});

const initialState: Initial = {
  language: cookies.get('language') || defaultLanguage,
  currency: cookies.get('currency') || defaultCurrency,
  data: {
    cartLifetime: null,
    currency: {
      data: []
    },
    locales: {
      data: []
    },
    regions: {
      data: []
    }
  }
};


const initialSlice = createSlice({
  name: 'initial',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload
    },
    setCurrency: (state, action) => {
      state.currency = action.payload
    }
  },
  extraReducers: builder => {
    // request
    builder.addCase(getInitialThunk.pending, (state, action) => {
    });

    // success response
    builder.addCase(getInitialThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });

    // response with errors
    builder.addCase(getInitialThunk.rejected, (state, action) => {
      state.error = action.error;
    });
  },
})

export const {setLanguage, setCurrency} = initialSlice.actions;
export default initialSlice.reducer;





