import React, { FC } from 'react';
import './ProfilePasswords.scss';
import AppFormInput from '../../../../../../components/ui/forms/controls/AppFormInput';
import { Form, Formik } from 'formik';
import FormButtons from '../../FormButtons/FormButtons';
import AppFormCode from '../../../../../../components/ui/forms/controls/AppFormCode';
import FormatPhone from '../../../../../../components/ui/components/FormatPhone/FormatPhone';
import { Trans, useTranslation } from "react-i18next";

interface Props {
   isSending: boolean;
   cancelEdit: (s) => void;
   submitData: (props: any, propsMethods: any) => void;
   initialValues: any;
   validationSchema: any;
   sendCode: () => void;
}

const ProfilePasswords: FC<Props> = props => {
   const { initialValues, validationSchema, submitData, isSending, cancelEdit, sendCode } = props;
   const [t] = useTranslation()
   return (
      <div>
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitData}
            enableReinitialize
         >
            <Form>

               {/*на телефон {initialValues.phone}*/}
               <div className="profile-send-code">

                  <div className="profile-password-message">
                     <Trans i18nKey="code_on_phone_info">
                       На ваш мобільний <span className="profile-password-message__phone"><FormatPhone>{{phone: initialValues.login}}</FormatPhone></span> телефон було надіслано
                        СМС підтвердження для скидання пароля
                     </Trans>
                  </div>

                  <div className="profile-send-code__form">
                     <AppFormCode name="code" autoFocus={false} label={t("Введіть код, який ми надіслали через SMS")} />
                  </div>
                  <p className="profile-send-code__send-code">
                     <span>{t("Не отримали SMS-повідомлення")} </span><span onClick={sendCode}>{t("Надіслати повторно")}</span>
                  </p>
               </div>
               <AppFormInput type="password" name="password" label="Пароль" />
               <AppFormInput type="password" name="password_confirmation" label={t("Введіть пароль ще раз")} />

               <FormButtons onCancel={cancelEdit} isSending={isSending} onSave={() => {}} />
            </Form>
         </Formik>
      </div>
   );
};

export default ProfilePasswords;
