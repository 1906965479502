import React, { FC } from 'react';
import './ProfilePlace.scss';
import AppFormSelect from '../../../../../../components/ui/forms/controls/AppFormSelect';
import AppFormInput from '../../../../../../components/ui/forms/controls/AppFormInput';
import { Form, Formik } from 'formik';
import FormButtons from '../../FormButtons/FormButtons';
import { useSelector } from 'react-redux';
import { profileCountriesSelector } from '../../../../../../store/profile/profile-selector';
import { useTranslation } from "react-i18next";

interface Props {
   isSending: boolean;
   cancelEdit: (s) => void;
   submitData: (props: any, propsMethods: any) => void;
   initialValues: any;
   validationSchema: any;
   countries: Array<{key: string; value: string}>
}


const ProfilePlace: FC<Props> = props => {
   const { initialValues, validationSchema, submitData, isSending, cancelEdit,countries } = props;
   const [t] = useTranslation();

   return (
      <div>
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitData}
            enableReinitialize
            // validateOnMount={true}
         >
            <Form>
               <AppFormSelect options={countries} name="countryId" label={t("Країна")} />
               <AppFormInput name="city" label={t("Місто")} />
               <FormButtons onCancel={() => cancelEdit(false)} isSending={isSending} onSave={() => {}} />
            </Form>
         </Formik>
      </div>
   );
};

export default ProfilePlace;
