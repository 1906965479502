import React, { useEffect, useState } from 'react';
import { createBemClassProp } from '../../../../../helpers/common';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';
import './OrderIndexPageSubmitSection.scss';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { getPaymentTypeSelector } from '../../../../../store/order/order-selector';
import { goToPaymentThunk } from '../../../../../store/order/order-thunk';
import ReservationConditionsModal from '../ReservationConditionsModal';
import FieldCheckbox from '../../../../../components/ui/forms/fields/FieldCheckbox/FieldCheckbox';
import { Log } from '@3ddie/react-utils';
import { Trans, useTranslation } from "react-i18next";

type Props = {
    onSubmit?: () => void;
    mobileStep?: number;
};

const OrderIndexPageSubmitSection: React.FC<Props> = ({onSubmit, mobileStep}) => {
    const dispatch = useAppDispatch();
    const [t] = useTranslation();

    const cn = createBemClassProp('order-index-page-submit-section');
    const {selectedPaymentMethod} = useAppSelector(state => state.order);
    const selectedPaymentType = useAppSelector(getPaymentTypeSelector);

    const [showRulesModal, setShowRulesModal] = useState(false);
    const order = useAppSelector(state => state.order);
    const conditionsText = order.serverOrderData.data.reservationConditions;

    let buttonDisabled = !selectedPaymentType;

    if (selectedPaymentType?.method) buttonDisabled = buttonDisabled || !selectedPaymentMethod;

    if (mobileStep === 1) buttonDisabled = false;

    const isBookingWithoutPayment = false;//selectedPaymentType && selectedPaymentType?.method === 0;

    const [isChecked, setChecked] = useState(false);

    return (
        <div {...cn()}>
            {mobileStep !== 2 && conditionsText && (
                <div {...cn('order-text')}>
                    <label>
                        <FieldCheckbox
                            id={'rules'}
                            name={'rules'}
                            onClick={() => {
                                setChecked(!isChecked);
                            }}
                            checked={isChecked}
                        />
                        <div>
                          <Trans i18nKey={"Я приймаю умови бронювання та правила проживання в готелі"}>
                            Я приймаю <span onClick={() => setShowRulesModal(true)}>умови бронювання та правила проживання в готелі</span>

                          </Trans>

                            {/*Натискаючи кнопку нижче, я приймаю &nbsp;*/}
                            {/*<span onClick={() => setShowRulesModal(true)}>умови бронювання та правила проживання в готелі</span>*/}
                        </div>
                    </label>

                    {/*Я погоджуюся сплатити повну вказану суму, якщо готель прийме мій запит на бронювання.*/}
                </div>
            )}

            <AppButton
                {...cn('order-button')}
                onClick={() => {
                    if (mobileStep === 1 && onSubmit) {
                        onSubmit();
                    } else {
                        dispatch(
                            goToPaymentThunk({
                                payment_method: selectedPaymentType?.method

                                // paymentCardId: selectedPaymentType?.payment ? selectedPaymentMethod?.id : null,
                                // percent: typeof selectedPaymentType?.percent !== 'undefined' ? String(selectedPaymentType?.percent) : '0'
                                //paymentTypeId: selectedPaymentType?.id,
                            }),
                        );
                    }
                }}
                size="big"
                disabled={conditionsText && !isChecked}
            >
                {mobileStep === 1
                    ? t("Продовжити")
                    : isBookingWithoutPayment
                        ? t('Бронювати без оплати')
                        : t("Бронювати й оплатити")}
            </AppButton>

            {conditionsText && showRulesModal && <ReservationConditionsModal onClose={() => setShowRulesModal(false)}/>}
        </div>
    );
};

export default OrderIndexPageSubmitSection;
