import React, { FC } from 'react';
import './ProfilePersonal.scss';
import AppFormInput from '../../../../../../components/ui/forms/controls/AppFormInput';
import AppFormSelect from '../../../../../../components/ui/forms/controls/AppFormSelect';
import { Form, Formik } from 'formik';
import FormButtons from '../../FormButtons/FormButtons';
import { genderOptions } from '../../../helpers/common';
import { useTranslation } from "react-i18next";

interface Props {
   isSending: boolean;
   cancelEdit: (s) => void;
   submitData: (props: any, propsMethods: any) => void;
   initialValues: any;
   validationSchema: any;
}

const ProfilePersonal: FC<Props> = props => {
   const { initialValues, validationSchema, submitData, cancelEdit, isSending } = props;
   const [t] = useTranslation();

   return (
      <div>
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitData}
            enableReinitialize
            // validateOnMount={true}
         >
            <Form>
               <AppFormInput name="name" label={t("Ім'я")} />
               <AppFormInput name="lastname" label={t("Прізвище")} />
               <AppFormSelect options={genderOptions} name="gender" label={t("Стать")} />
               <AppFormInput mask="99.99.9999" name="birthday" label={t("Дата народження")} />
               <FormButtons onCancel={() => cancelEdit(false)} isSending={isSending} onSave={() => {}} />
            </Form>
         </Formik>
      </div>
   );
};

export default ProfilePersonal;
