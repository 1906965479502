import React, { FC, forwardRef, Ref, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';
import './HeaderMobile.scss';

import useScroll from '../../../../hooks/useScroll';
import SearchMobilePlace from '../../../../screens/Hotels/common/mobile/SearchMobilePlace/SearchMobilePlace';
import SearchModalDates from '../../../../screens/Hotels/common/mobile/SearchModalDates/SearchModalDates';
import SearchModalMain from '../../../../screens/Hotels/common/mobile/SearchModalMain/SearchModalMain';
import useSearchForm from '../../../../screens/Hotels/common/HotelSearchForm/hooks/useSearchForm';
import SearchButton from '../common/SearchButton/SearchButton';
import { formatDate } from '../../../../helpers/date';
import { getUrlParams } from '../../../../helpers/url';
import IconArrowLeft from '../../../ui/icons/account/IconArrowLeft';
import useWindowSize from '../../../../hooks/useWindowSize';
import SearchFormMobile from './SearchFormMobile';
import { RouteNames } from '../../../../routes';
import IconLogo from '../../../ui/icons/header/IconLogo';
import { useAppTranslate } from '../../../../hooks/useAppTranslate';
import { useTranslation } from "react-i18next";
import { isBronui, isSudtirol } from "../../../../config";
import IconSudtirolLogo from "../../../ui/icons/header/sudtirol/IconSudtirolLogo";

interface Props {
    isIndexPage?: boolean;
    hideSearchForm?: boolean
}

export type Step = '' | 'search' | 'dates' | 'guests' | 'forms';
const getDate = date => (date ? `${formatDate(new Date(date), 'dd MMMM')}` : '');

const HeaderMobile = forwardRef((props: Props, ref: Ref<any>) => {
    const {scrollY} = useScroll();
    const {isIndexPage, hideSearchForm} = props;
    const {url} = useRouteMatch();
    const history = useHistory();
    const {search} = useLocation();
    const [t] = useTranslation()

    const isMap = url === RouteNames.HOTELS_LIST_MAP;

    const scrolled = scrollY > 0;

    const headerClassName = classnames(
        'header-mobile',
        {
            'header-mobile--index-variant': isIndexPage
        }
    );

    const {
        changeDates,
        loadOptions,
        placeObject,
        searchHotel,
        place,
        startDate,
        endDate,
        query,
        guestsParams,
        changeGuestForm,
        changeKidsAge,
        selectChildren,
        defaultOptions,
        openSearchDropdown,
    } = useSearchForm();

    const location = useLocation();

    // functionality
    const [step, setStep] = useState<Step>('');

    const {adults, arrival_date, departure_date, rooms, place_id} = getUrlParams(search) as any;

    // якщо ще не була заповнена query params
    const isAllFilled = !!departure_date && !!arrival_date && !!rooms && !!adults && !!place_id;

    const openSearchModal = () => setStep(isAllFilled ? 'forms' : 'search');

    // change place functionality
    const handleChangePlace = data => setStep(isAllFilled ? '' : 'dates');

    // used instead of cb
    const isFirstRender = useRef(true);

    useEffect(() => {
        // prevent initial (hack)
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (isAllFilled) history.push(`/hotels${query}`);
    }, [Number(place.value)]);
    // end change place functionality

    const submitDates = values => {
        if (isAllFilled) {
            history.push(`/hotels${query}`);
            setStep('');
        } else {
            setStep('guests');
        }
    };

    const guestsChange = () => {
        history.push(`/hotels${query}`);
        setStep('');
    };

    const onClose = () => {
        setStep(isAllFilled ? 'forms' : '');
    };

    return (
        <header className={headerClassName} ref={ref}>
            <div
                className={`header-mobile__search-button ${isIndexPage && 'header-mobile__search-button--index-variant'}`}
            >
                {isIndexPage && (
                    <div className="header-mobile__logo-container">
                        <Link to="/">
                            {
                                isBronui &&  <IconLogo
                                  className="header__logo"
                                  lettersFill={'#ffffff'}
                                />
                            }

                            {
                                isSudtirol && <IconSudtirolLogo lettersFill="#ffffff"/>
                            }

                            {isBronui && <div className="header-mobile__logo-description">{t("Ваш помічник з планування подорожей")}</div>}
                        </Link>

                        <span className='header-mobile__logo-container-text'>
                            {t('Пошук готелів')}
                        </span>
                    </div>
                )}

                {/* DID IT BY CSS BECAUSE WITH JS GOT BUG */}
                {/*<div style={{display: step === 'forms' ? 'block' : 'none'}}>*/}
                {step === 'forms' && (
                    <SearchFormMobile
                        //isIndexPage={isIndexPage}
                        onClose={() => setStep('')}
                        setStep={setStep}
                        searchButtonText={placeObject?.label}
                        datesButtonText={`${getDate(startDate)} - ${getDate(endDate)}`}
                        guestButtonText={`${guestsParams.adults + guestsParams.kids.length} гостей`}
                    />
                )}

                {/*</div>*/}
                {!hideSearchForm && (
                    <>
                        {isAllFilled ? (
                            <div className="header-mobile__exist-button">
                                <IconArrowLeft onClick={() => {
                                    const url = isMap ? RouteNames.HOTELS_LIST + search : '/';
                                    if (isMap) history.replace(url)
                                    else history.push(url);
                                }}/>
                                <SearchButton onClick={openSearchModal} theme={'outline'}/>
                            </div>
                        ) : (
                            <SearchButton
                                onClick={openSearchModal}
                                theme={isIndexPage && !scrolled ? 'light' : 'gray'}
                            />
                        )}
                    </>
                )}

                {/*<div onClick={()=> setStep('dates')}>asdasdasdasdasd</div>*/}
            </div>

            {step === 'search' && (
                <SearchMobilePlace
                    onClose={onClose}
                    loadOptions={loadOptions}
                    searchHotel={searchHotel}
                    openSearchDropdown={openSearchDropdown}
                    defaultOptions={defaultOptions}
                    placeObject={placeObject}
                    onChange={handleChangePlace}
                />
            )}

            {step === 'dates' && (
                <SearchModalDates
                    start={startDate}
                    end={endDate}
                    onSubmit={submitDates}
                    changeDates={changeDates}
                    onCloseModal={onClose}
                    title={placeObject?.label}
                />
            )}

            {step === 'guests' && (
                <SearchModalMain
                    start={startDate}
                    end={endDate}
                    place={placeObject.label}
                    onClose={onClose}
                    guestsMethods={{
                        changeGuestForm,
                        changeKidsAge,
                        selectChildren,
                    }}
                    guestsParams={guestsParams}
                    onSubmit={guestsChange}
                />
            )}
        </header>
    );
});

export default HeaderMobile;
