import React from 'react';
import { OrderPaymentResponse } from '../../../../../types/order';
import './OrderSuccessWithPaymentData.scss';
import TopInfoRow from './components/TopInfoRow/TopInfoRow';
import TotalPriceBlock from './components/TotalPriceBlock/TotalPriceBlock';
import PaymentMethodInfo from './components/PaymentMethodInfo/PaymentMethodInfo';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';
import { useTranslation } from "react-i18next";

type Props = {
    paymentData: OrderPaymentResponse
}

const Block = ({children}) => (
    <div style={{marginBottom: 8, borderRadius: 8, overflow: 'hidden'}}>{children}</div>
)

const OrderSuccessWithPaymentData: React.FC<Props> = ({paymentData}) => {
  const [t] = useTranslation();

    return (
        <div className={'order-success-payment-data'}>
            <TopInfoRow endDate={paymentData?.cancellationDate}/>

            <Block>
                <TotalPriceBlock totalPrice={paymentData.price}/>
            </Block>

            {Array.isArray(paymentData?.paymentDetails?.data) && paymentData?.paymentDetails?.data.map((paymentDetailItem, index) => {
                return (
                    <Block key={paymentDetailItem?.id}>
                        <PaymentMethodInfo
                            paymentDetailItem={paymentDetailItem}
                            index={index + 1}
                            orderId={paymentData?.orderId}
                        />
                    </Block>
                )
            })}

            <div className="order-success__text" style={{width: '100%', textAlign: 'left', padding: '0 8px'}}>
              {t("Також інформацію про бронювання та оплату ми продублювали вам на e-mail")}
                <br/>
                <br/>
              {t("Детальну інформацію про бронювання ви можете знайти у розділі")}
            </div>

            <AppButton
                className="order-success__button" theme={'primary'} size="middle" to={'/'}
                style={{width: '100%'}}
            >
              {t("Готово")}
            </AppButton>
        </div>
    )
};

export default OrderSuccessWithPaymentData;
