import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './HotelBookSection.scss';
import { getHotelSelector } from '../../../../../store/hotel/hotel-selector';
import HotelDetailPhoneOrder from '../HotelDetailPhoneOrder/HotelDetailPhoneOrder';
import HotelDetailOrderSummary from '../HotelDetailOrderSummary/HotelDetailOrderSummary';
import Tabs from '../../../../../components/ui/tabs/Tabs';
import { changeOrderBookType } from '../../../../../store/hotel/hotel-reducer';
import { useTranslation } from "react-i18next";


type Props = {
    isMobile?: boolean
}

const HotelBookSection: React.FC<Props> = ({isMobile}) => {
  const [t] = useTranslation();
    const {bookingState, selectedOrderBookType} = useSelector(getHotelSelector);
    const dispatch = useDispatch();

  if (bookingState === 'both') {
        return (
            <div className={'hotel-book-section'}>
              {t("Як бажаєте бронювати?")}

                <Tabs
                    selected={selectedOrderBookType}
                    buttons={[
                      {
                        label: t("Онлайн"),
                        value: 'cart',
                        content: !isMobile && <HotelDetailOrderSummary/>
                      },
                      {
                        label: t("Телефон"),
                        value: 'phone',
                        content: !isMobile && <HotelDetailPhoneOrder/>
                      },
                    ]}
                    onChange={(val) => {
                        dispatch(changeOrderBookType(val));
                    }}

                />
            </div>
        )
    }

    return (
        <div>
            {!isMobile && (
                <>
                    {bookingState === 'phone' && <HotelDetailPhoneOrder/>}
                    {bookingState === 'cart' && <HotelDetailOrderSummary/>}
                </>
            )}

            {/*{bookingState === 'both' && <AdditionalPhoneSection/>}*/}
        </div>
    )
};

export default HotelBookSection;
