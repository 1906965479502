import React, { useState } from "react";
import "./FormCompletedHotelRegistration.scss";
import { registrationFormCompleteSchema } from "../../../../../../helpers/validation";
import { Form, Formik } from "formik";
import AppFormInput from "../../../../../../components/ui/forms/controls/AppFormInput";
import AppButton from "../../../../../../components/ui/components/Buttons/AppButton/AppButton";
import AppFormCheckbox from "../../../../../../components/ui/forms/controls/AppFormCheckbox";
import Сontract from "../../Сontract/Сontract";
import { useTranslation } from "react-i18next";
import { phoneMask } from "../../../../../../config";


const FormCompletedHotelRegistration = ({ onSubmit, phone, showIndicator, selectedHotel }) => {
  const [showContract, setShowContract] = useState(false);
  const initial = {
    hotel_name: selectedHotel?.name ? selectedHotel?.name : '',
    phone: phone,
    admin_name: '',
    admin_lastname: '',
    email: '',
    password: '',
    password_confirmation: '',
    agree: false,
  }

  if (selectedHotel) {
    //@ts-ignore
    initial.hotel_id = selectedHotel.id
    initial.hotel_name = selectedHotel.name
  }

  const [t] = useTranslation();

  return (
    <Formik initialValues={initial}
            validationSchema={registrationFormCompleteSchema}
            onSubmit={onSubmit}
            enableReinitialize>
      {
        (formikProps) => {
          const { values, setFieldValue } = formikProps;


          return (
            <Form>
              {
                showContract
                  ? <div className="hotel-registration-init__modal">
                    <Сontract onCloseContractStep={() => {
                    setShowContract(false)
                    setFieldValue("agree", true)
                  }}/>
                </div>
                  : <>
                    <div>
                      {selectedHotel && <AppFormInput label="" name="hotel_id" type="hidden"/>}
                      <AppFormInput role="phone" disabled={true} name="phone" label={t("Номер телефону")} unmask
                                    mask={phoneMask}/>
                      <AppFormInput label={t("Назва готелю")} name="hotel_name"/>
                      <AppFormInput label={t("Ім'я")} name="admin_name"/>
                      <AppFormInput label={t("Прізвище")} name="admin_lastname"/>
                      <AppFormInput label={t("Email")} name="email"/>
                      <AppFormInput label={t("Пароль")} name="password" type="password"/>
                      <AppFormInput label={t("Пароль")} name="password_confirmation" type="password"/>

                      <div className="hotel-registration-init__agree">
                        <AppFormCheckbox name="agree"/> <span>{t("Я погоджуюсь із")} <a href="" onClick={(e) => {
                        e.preventDefault()
                        setShowContract(true)
                      }}>{t("правилами-оферти")}</a></span>
                      </div>
                    </div>
                    <AppButton disabled={!values.agree} theme="primary"
                               className="hotel-registration-init__submit"
                               size="middle" showIndicator={showIndicator}>
                      {t("Продовжити")}
                    </AppButton>
                    <div className="hotel-registration-init__feedback">
                      <div>
                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.09 8.97559C8.3251 8.30725 8.78915 7.74369 9.39995 7.38472C10.0108 7.02574 10.7289 6.89452 11.4272 7.0143C12.1255 7.13407 12.7588 7.49711 13.2151 8.03911C13.6713 8.58112 13.9211 9.26711 13.92 9.97559C13.92 11.9756 10.92 12.9756 10.92 12.9756M11 16.9756H11.01M21 11.9756C21 17.4984 16.5228 21.9756 11 21.9756C5.47715 21.9756 1 17.4984 1 11.9756C1 6.45274 5.47715 1.97559 11 1.97559C16.5228 1.97559 21 6.45274 21 11.9756Z"
                            stroke="#051C35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                      <div>
                        {t("Якщо ви не можете отримати sms на вибраний номер, напишіть нам")} <a href="mailto:info@bronui.com"
                                               target="_blank"
                                               style={{ color: 'rgb(108, 94, 203)' }}>info@bronui.com</a>
                      </div>
                    </div>
                  </>
              }
            </Form>
          )
        }
      }
    </Formik>
  )
}

export default FormCompletedHotelRegistration;