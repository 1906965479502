import React, { FC, useCallback, useState } from 'react';
import './AccountOrdersDetailMain.scss';
import AccountOrdersDetailPrime from '../blocks/AccountOrdersDetailPrime/AccountOrdersDetailPrime';
import AccountOrdersDetailReservations from '../blocks/AccountOrdersDetailReservations/AccountOrdersDetailReservations';
import AccountOrdersDetailLocation from '../blocks/AccountOrdersDetailLocation/AccountOrdersDetailLocation';
import AccountOrdersDetailPaymentInfo from '../blocks/AccountOrdersDetailPaymentInfo/AccountOrdersDetailPaymentInfo';
// import AccountOrdersDetailSupport from '../blocks/AccountOrdersDetailSupport/AccountOrdersDetailSupport';
import { AccountOrdersDetailData } from '../../../../../../types/account-orders/account-orders-detail';
import { formatDate } from '../../../../../../helpers/date';
import { useAppDispatch } from '../../../../../../hooks/reduxHooks';
import {
   cancelAccountOrdersDetailThunk,
   getAccountOrdersDetailThunk
} from '../../../../../../store/account/account-orders-detail/account-orders-detail-thunks';
import ModalConfirm from '../../../../../../components/ui/modals/ModalConfirm/ModalConfirm';
import { useTranslation } from "react-i18next";

interface Props {
   orderDetail: AccountOrdersDetailData;
   isSending: boolean;
}

const AccountOrdersDetailMain: FC<Props> = props => {
   const { isSending } = props;
   const {
      alias,
      photos,
      arrivalDate,
      departureDate,
      adults,
      kids,
      status,
      hotel,
      reservations,
      canCancel,
      address,
      id,
      amount,
      latitude,
      longitude,
   } = props.orderDetail;
   const [showConfirm, setShowConfirm] = useState(false);
   const line = useCallback(() => <div className="account-orders-detail-main-line"></div>, []);
   const dispatch = useAppDispatch();
   const [t] = useTranslation();

   const handleCancel = () => {
      dispatch(cancelAccountOrdersDetailThunk(id)).then(() => {
         setShowConfirm(false);
         dispatch(getAccountOrdersDetailThunk(id))
      });
   };

   return (
      <div className="account-orders-detail-main">
         <AccountOrdersDetailPrime
            alias={alias}
            onCancel={() => setShowConfirm(true)}
            isSending={isSending}
            canCancel={canCancel}
            status={status}
            photos={photos}
            guestsString={`${adults} ${t("дорослий", {count: Number(adults)})}, ${kids} ${t("дитина", {count: Number(kids)})}`}
            title={hotel}
            arrival={`${t("Реєстрація заїзду з")} ${formatDate(new Date(arrivalDate), 'hh:mm')}`}
            departure={`${t("Реєстрація виїзду до")} ${formatDate(new Date(departureDate), 'hh:mm')}`}
         />
         {line()}
         <AccountOrdersDetailReservations
            reservations={reservations.data}
            status={status}
            id={id}
            canCancel={canCancel}
         />
         {line()}
         <AccountOrdersDetailLocation address={address} direction={{ latitude, longitude }} />
         {line()}
         <AccountOrdersDetailPaymentInfo  id={id} amount={amount}/>
         {line()}
         {/*<AccountOrdersDetailSupport />*/}
         {showConfirm && (
            <ModalConfirm
               className="orders-detail-confirm"
               title={t("Скасувати бронювання")}
               onCancel={() => {
                  setShowConfirm(false);
               }}
               onConfirm={handleCancel}
               confirmButtonLabel={t("Скасувати")}
               cancelButtonLabel={t("Відмінити")}
            >
               {t("Ви дійсно бажаєте скасувати бронювання")}
            </ModalConfirm>
         )}
      </div>
   );
};

export default AccountOrdersDetailMain;
