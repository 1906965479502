import React from 'react';
import './OrderIsEmpty.scss';
import AppText from '../../../../../components/ui/typography/AppText/AppText';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';
import image from '../../../../../assets/images/order-empty.svg';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

type Props = {}

const OrderIsEmpty: React.FC<Props> = () => {
  const [t] = useTranslation();
    const history = useHistory();

    return (
        <div className={'order-empty-page'}>
            <AppText variant={'H300'} weight={'600'} className={'order-empty-page__title'}>
              {t('Ваше бронювання')}
            </AppText>

            <AppText variant={'B500'} className={'order-empty-page__description-text'}>
              {t('Замовлення пусте')}
            </AppText>

            <img src={image} alt="" className={'order-empty-page__image'}/>

            <AppText variant={'B500'} className={'order-empty-page__description-text'}>
                 <br/>{t('Ми впевнені, що хоч один підійде вам')}
            </AppText>

            <AppButton onClick={() => history.push('/')}>
              {t('Пошук готелів')}
            </AppButton>
        </div>

    )
};

export default OrderIsEmpty;
