import React, { FC } from 'react';
import './AccountOrdersDetailPaymentInfo.scss';
import TextWithDescription from '../../TextWithDescription/TextWithDescription';
import ClickableArrowItem from '../../../../../../../components/common/ClickableArrowItem/ClickableArrowItem';
import TextWithIcon from '../../TextWithIcon/TextWithIcon';
import IconBill from '../../ui/icons/IconBill';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

interface Props {
   id: number;
  amount: number;
}

const AccountOrdersDetailPaymentInfo: FC<Props> = ({ id, amount }) => {
  const [t] = useTranslation();

   return (
      <div>
         <h4 className="account-orders-detail-subtitle">{t("Дані про оплату")}</h4>

         <ul className="account-orders-detail-info-list">
            <li style={{ paddingBottom: '24px' }}>
               <TextWithDescription text={t("Загальна вартість")} description={`${amount}₴`} />
            </li>
            {/*<li>*/}
            {/*   <ClickableArrowItem*/}
            {/*      content={<TextWithIcon text={'Підтвердження бронювання'} Icon={IconBill} />}*/}
            {/*      onClick={() => {*/}
            {/*         history.push(`/profile/orders/${id}/print`);*/}
            {/*      }}*/}
            {/*   />*/}
            {/*</li>*/}
         </ul>
      </div>
   );
};

export default AccountOrdersDetailPaymentInfo;
