import React from 'react';
import './MobileRoomDetailBookSectionTexts.scss';
import AppText from '../../../../../../../../components/ui/typography/AppText/AppText';
import { pluralize } from '../../../../../../../../helpers/translatioHelpers';
import useSearchForm from '../../../../../../common/HotelSearchForm/hooks/useSearchForm';
import { createBemClassProp } from '../../../../../../../../helpers/common';
import { _formatDate } from '../../../../../../../../helpers/date';
import { DATE_FORMAT_CLIENT } from '../../../../../../../../config';
import { useTranslation } from "react-i18next";

type Props = {}

const MobileRoomDetailBookSectionTexts: React.FC<Props> = () => {
    const {nights, guestsParams, startDate, endDate} = useSearchForm();
    const [t] = useTranslation();

    const cn = createBemClassProp('mobile-room-detail-book-section-texts');

    return (
        <div {...cn()}>
            <span {...cn('title')}>{t("Забронювати")}</span>

            <div>
                <AppText variant={'gray'}>
                    {pluralize('дорослий', guestsParams.adults)}

                    {guestsParams.kids.length ? ', ' + pluralize('дитина', guestsParams.kids.length) : ''}
                </AppText>

                <AppText variant={'gray'}>
                  {t('заїзд')} {_formatDate(startDate, DATE_FORMAT_CLIENT)}
                    {` ${t("виїзд")} ${_formatDate(endDate, DATE_FORMAT_CLIENT)}`}
                </AppText>

                <AppText variant={'gray'}>
                    {pluralize('ніч', nights)}
                </AppText>
            </div>
        </div>
    )
};

export default MobileRoomDetailBookSectionTexts;
