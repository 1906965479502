import React from 'react';
import AppMedia from '../../../../common/Media/Media';
import IconClose from '../../../../ui/icons/modal/IconClose';
import { useTranslation } from "react-i18next";

type Props = {
    onClose: () => void
}

const ModalGalleryCloseButton: React.FC<Props> = ({onClose}) => {
  const [t] = useTranslation();

    return (
        <button onClick={onClose} className={'modal-gallery__close-button'}>
            <AppMedia.Media lessThan={'md'}>
                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.77745 7.87501L6.78934 12.5555C7.10383 12.9225 7.06134 13.4748 6.69443 13.7893C6.32752 14.1038 5.77513 14.0613 5.46064 13.6944L0.21065 7.56944C-0.0702167 7.24176 -0.0702167 6.75823 0.21065 6.43056L5.46064 0.305566C5.77513 -0.0613432 6.32752 -0.103834 6.69443 0.210659C7.06134 0.525153 7.10383 1.07754 6.78934 1.44445L2.77742 6.12501L16.625 6.12501C17.1082 6.12501 17.5 6.51676 17.5 7.00001C17.5 7.48326 17.1082 7.87501 16.625 7.87501L2.77745 7.87501Z"
                        fill="#6D7188"/>
                </svg>
            </AppMedia.Media>

            <AppMedia.Media greaterThanOrEqual={'md'}>
                <IconClose fill={'#fff'}/> <span>{t("Закрити")}</span>
            </AppMedia.Media>
        </button>
    )
};

export default ModalGalleryCloseButton;
