import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './AccountProfilePreview.scss';
import { profileSelector } from '../../../../store/profile/profile-selector';
import Page from '../../../../components/common/Page/Page';
import Container from '../../../../components/ui/layouts/Container/Container';
import ProfileUser from './ProfileUser/ProfileUser';
import ProfilePreviewList from './ProfilePreviewList/ProfilePreviewList';
import { Media } from '../../../../components/common/Media/Media';
import useWindowSize from '../../../../hooks/useWindowSize';

const AccountProfilePreview = () => {
   const [t] = useTranslation('common');
   const profile = useSelector(profileSelector);
   const { data } = profile;
   const { name, lastname, email, photo } = data.data;
   const { isMobile } = useWindowSize();

   return (
      <Page hideSearchHeader={isMobile}>
         <Helmet>
            <title>{t('Кабінет')}</title>
         </Helmet>
         <Container>
            <div className="profile-preview">
               <div className="profile-preview__profile">
                  <ProfileUser
                     description={
                        <>
                           <Media greaterThan="sm">
                              <span>
                                 <b>
                                    {name} {lastname}
                                 </b>{' '}
                                 {email}
                              </span>
                           </Media>

                           <Media at="sm">
                              <span>{email}</span>
                           </Media>
                        </>
                     }
                     title={
                        <>
                           <Media greaterThan="sm">{t("Акаунт")}</Media>

                           <Media at="sm">
                              {name} {lastname}
                           </Media>
                        </>
                     }
                     name={name}
                     image={photo}
                     lastname={lastname}
                  />
               </div>

               <div className="profile-preview__list">
                  <ProfilePreviewList />
               </div>
            </div>
         </Container>
      </Page>
   );
};

export default AccountProfilePreview;
