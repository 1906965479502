import React from 'react';
import AppText from '../../../../../../../../../../components/ui/typography/AppText/AppText';
import PriceViewer from '../../../../../../../../../../components/ui/components/PriceViewer/PriceViewer';
import { pluralize } from '../../../../../../../../../../helpers/translatioHelpers';
import AppButton from '../../../../../../../../../../components/ui/components/Buttons/AppButton/AppButton';
import { useSelector } from 'react-redux';
import { getHotelSelector } from '../../../../../../../../../../store/hotel/hotel-selector';
import { useTranslation } from "react-i18next";

type Props = {
    totalPrice: number,
    oldPrice?: number,
    nights: number,
    mealTypeName: string,
    disabled?: boolean,
    onSelectRoom?: () => void
}

const MobileRoomListItemPrice: React.FC<Props> = (props) => {
    const {totalPrice, oldPrice = 0, nights, mealTypeName, onSelectRoom, disabled} = props;
    const {selectedOrderBookType} = useSelector(getHotelSelector);
    const [t] = useTranslation();

    return (
        <>
            <div className={`mobile-room-list-item__price ${disabled ? 'disabled' : ''}`}>
                {oldPrice > totalPrice && <PriceViewer price={oldPrice} oldPrice/>}

                {selectedOrderBookType === 'cart' && (
                    <AppText variant={'gray'}>
                        {mealTypeName}
                    </AppText>
                )}

                <AppText variant={'gray'} className={'mobile-room-list-item__price-with-info-text'}>
                    <PriceViewer price={totalPrice} totalAfterDiscount/>
                    &nbsp;/ {pluralize('ніч', nights)}
                </AppText>
            </div>

            {selectedOrderBookType !== 'cart' && (
                <AppText variant={'gray'} style={{color: '#2F2C37'}}>
                    {mealTypeName}
                </AppText>
            )}

            {(onSelectRoom && selectedOrderBookType === 'cart') &&
                (
                    <AppButton
                        onClick={onSelectRoom}
                        disabled={disabled}
                        theme={'primary'}
                    >
                        {t("Вибрати")}
                    </AppButton>
                )}
        </>
    )
};

export default MobileRoomListItemPrice;
