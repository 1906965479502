import React from 'react';

import { PaymentDetailsItem } from '../../../../../../../types/order';
import AppText from '../../../../../../../components/ui/typography/AppText/AppText';
import './PaymentMethodInfo.scss';
import { formatCreditCardNumber } from '../../../../../../../helpers/common';
import CopyableValueText from '../CopyableValueText/CopyableValueText';
import { useTranslation } from "react-i18next";

type Props = {
    paymentDetailItem: PaymentDetailsItem,
    index: number
    orderId?: string | number
}

type InfoRowProps = {
    label: string
    text: string
    copyable?: boolean
}

const InfoRow: React.FC<InfoRowProps> = ({label, text, copyable}) => {
    return (
        <div style={{marginTop: 12}}>
            <AppText variant={'B500'} weight={'400'}>{label}</AppText>
            <div>
                {copyable
                    ? <CopyableValueText text={text}/>
                    : <AppText variant={'H100'}>{text}</AppText>}
            </div>
        </div>
    )
}

const PaymentMethodInfo: React.FC<Props> = ({paymentDetailItem, index, orderId}) => {
    const [isOpen, setIsOpen] = React.useState(true);
    const [t] = useTranslation();

    return (
        <div className={'order-success-payment-data__payment-method-info'}>
            <div
                onClick={() => setIsOpen(!isOpen)}
                className={'order-success-payment-data__payment-method-info__collapse-header'}
            >
                <AppText variant={'B500'} weight={'600'}>{t("Спосіб оплати")} №{index}</AppText>

                <svg style={{transform: `rotate(${isOpen ? 180 : 0}deg)`}} width="24" height="25" viewBox="0 0 24 25"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.97266 8.99512L12.0044 15.9951L19.0263 8.99751" stroke="#C5C6CF" strokeWidth="2"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>

            {isOpen && (
                <div className={'order-success-payment-data__payment-method-info__collapse-content'}>
                    <AppText variant={'B500'} weight={'400'}>
                        {paymentDetailItem.method === 'card' && t("Картка")}
                        {paymentDetailItem.method === 'iban' && t("Розрахунковий рахунок")}
                    </AppText>

                    <div>
                        <CopyableValueText
                            text={paymentDetailItem.method === 'card' ? formatCreditCardNumber(paymentDetailItem.number) : paymentDetailItem.number}
                        />
                    </div>

                    {paymentDetailItem?.details?.bank_name &&
                       <InfoRow text={paymentDetailItem?.details?.bank_name} label={t("Назва банку")}/>}

                    {paymentDetailItem?.details?.recipient &&
                       <InfoRow text={paymentDetailItem.details.recipient} label={t("Отримувач")}/>}

                    {paymentDetailItem.method === 'iban' && (
                        <>
                            <InfoRow text={paymentDetailItem?.details?.bank_institution_name} label={t("Установа банку")}/>
                            <InfoRow text={paymentDetailItem?.details?.mfo} label={t("МФО банку")}/>
                            <InfoRow text={paymentDetailItem?.details?.edrpou} label={t("ЄРДПОУ/ HRGG")} copyable/>
                        </>
                    )}

                    <InfoRow
                        text={paymentDetailItem?.details?.purpose_of_payment}
                        label={t("Призначення платежу")}
                    />
                </div>
            )}

            {/*<Log value={paymentDetailItem}/>*/}
        </div>
    )
};

export default PaymentMethodInfo;
