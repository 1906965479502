import React, { useCallback } from 'react';
import './MobileRoomSelect.scss';
import { getOptionsForRoomCountSelect } from '../../../components/HotelRooms/components/HotelRoomItem/hotelRoomHelpers';
import { createBemClassProp } from '../../../../../../helpers/common';
import { useTranslation } from "react-i18next";

type Props = {
    maxRooms: number,
    onSelect: (count: number) => void,
    onClose: () => void
    selectedCount?: number
}

const MobileRoomSelect: React.FC<Props> = ({maxRooms, onClose, onSelect, selectedCount}) => {
    const options = getOptionsForRoomCountSelect(maxRooms, true);
    const createBemClassPropM = useCallback(createBemClassProp, []);
    const cn = createBemClassPropM('mobile-room-select');
    const [t] = useTranslation();

    return (
        <div {...cn()}>
            <div {...cn('background')} onClick={onClose}/>
            <div {...cn('content')} >
                <span {...cn('title')}>{t("Вибрати кількість номерів")}</span>

                <div {...cn('content-inner')}>
                    {options.map((option, index) => (
                        <button
                            key={option.value}
                            {...cn('button', selectedCount === option.value && index !== options.length - 1 ? 'selected' : null)}
                            onClick={() => onSelect(option.value)}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
};

export default MobileRoomSelect;
