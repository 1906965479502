import authApi from '../../api/authApi';
import {
   ResendPassword,
   SighInRequest,
   SignInSocialParams,
   SignUpCompleteRequest,
   SignUpConfirm,
   SignUpRequest,
} from '../../types/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProfileThunk } from '../profile/profile-thunks';
import { saveAuthTokenAndHeader } from '../../helpers/auth';
import { registrationStage, setIsSending } from './auth-reducer';
import { REGISTRATION_CONFIRM, REGISTRATION_COMPLETE, REGISTRATION_RESTORE } from './auth-types';
import { setAxiosInstanceHeader } from '../../helpers/network';
import appToastr from '../../services/appToastr';
import { _formatDate } from '../../helpers/date';
import i18n from "../../i18n";
import { DATE_FORMAT_SERVER } from "../../config";

export const signInThunk = createAsyncThunk(
   'auth/signIn',
   async (data: SighInRequest, { rejectWithValue, dispatch }) => {

      try {
         setAxiosInstanceHeader('Authorization', '');
         const res = await authApi.signIn(data);

         const { type, token, expires_in } = res.data;
         const accessToken = `${type} ${token}`;

         // створення кук і хедерів для авторизації
         saveAuthTokenAndHeader(accessToken, expires_in);

         // Отримую дані користувача
         dispatch(getProfileThunk());

         appToastr.success(i18n.t("Ви успішно увійшли в свій акаунт"), i18n.t("Успішно"))

         return res.data;
      } catch (error) {
         appToastr.error(i18n.t("Не вдалось увійти в акаунт"), i18n.t("Помилка"))
         return rejectWithValue(error.response);
      }
   },
);

export const signInSocialThunk = createAsyncThunk(
   'auth/signInSocial',
   async (data: SignInSocialParams, { rejectWithValue, dispatch }) => {
      dispatch(setIsSending(true));
      try {
         const res = await authApi.signInSocial(data);

         const { type, token, expires_in } = res.data;
         const accessToken = `${type} ${token}`;

         // створення кук і хедерів для авторизації
         saveAuthTokenAndHeader(accessToken, expires_in);

         // Отримую дані користувача
         dispatch(getProfileThunk());
         dispatch(setIsSending(false));
         appToastr.success(i18n.t("Ви успішно увійшли в свій акаунт"),i18n.t("Успішно"))
         return res.data;
      } catch (error) {
         dispatch(setIsSending(false));
         appToastr.error(i18n.t("Не вдалось увійти в акаунт"),i18n.t("Помилка"))
         return rejectWithValue(error.response);
      }
   },
);

// Реєстрація (крок 1)
export const signUpInitialThunkCreator = createAsyncThunk(
   'auth/signUpInitial',
   async (data: SignUpRequest, { rejectWithValue, dispatch }) => {
      dispatch(setIsSending(true));
      try {
         const res = await authApi.signUpInitial(data);
         dispatch(registrationStage(REGISTRATION_CONFIRM));
         dispatch(setIsSending(false));
         appToastr.info(i18n.t("Ми відправили код підтвердження на ваш телефон"))
         return res;
      } catch (error) {
         dispatch(setIsSending(false));


         if(error.response?.status === 408){
            appToastr.error(error.response?.data?.message)
         }

         return rejectWithValue(error.response);
      }
   },
);

// Реєстрація (крок 1.5)
export const signUpResendCodeThunkCreator = createAsyncThunk(
   'auth/signUpResendCode',
   async (data: SignUpRequest, { rejectWithValue, dispatch }) => {
      dispatch(setIsSending(true));
      try {
         const res: any = await authApi.signUpResendCode(data);
         dispatch(setIsSending(false));
         return res;
      } catch (error) {
         dispatch(setIsSending(false));
         return rejectWithValue(error.response);
      }
   },
);

// тимчасовий токен
let headers = {};

// Реєстрація (крок 2)
export const signUpConfirmCodeThunkCreator = createAsyncThunk(
   'auth/signUpConfirmation',
   async (data: SignUpConfirm, { rejectWithValue, dispatch }) => {
      dispatch(setIsSending(true));
      try {
         const res = await authApi.signUpConfirmCode(data);
         const { type, token } = res.data;
         headers = { headers: { Authorization: `${type} ${token}` } };
         dispatch(registrationStage(REGISTRATION_COMPLETE));
         dispatch(setIsSending(false));
      } catch (error) {
         dispatch(setIsSending(false));
         return rejectWithValue(error.response);
      }
   },
);

// Реєстрація (крок 3)
export const actionSignUpComplete = createAsyncThunk(
   'auth/signUpComplete',
   async (data: SignUpCompleteRequest, { rejectWithValue, dispatch }) => {
      dispatch(setIsSending(true));
      try {
         const dta = {...data};
         if (dta.birthday) {
            dta.birthday = _formatDate(dta.birthday,DATE_FORMAT_SERVER, 'DD.MM.YYYY') ;
         }

         const res = await authApi.signUpComplete(dta, headers);

         const { type, token, expires_in } = res.data;
         const accessToken = `${type} ${token}`;

         // створення кук і хедерів для авторизації
         saveAuthTokenAndHeader(accessToken, expires_in);

         // dispatch(registrationStage(REGISTRATION_CONFIRM))
         // Отримую дані користувача
         dispatch(getProfileThunk());

         dispatch(setIsSending(false));
         appToastr.success(i18n.t("Реєстрація пройшла успішно"),i18n.t("Успішно"))
      } catch (error) {
         dispatch(setIsSending(false));

         appToastr.error(i18n.t("Не вдалось зареєструватись"),i18n.t("Помилка"))

         return rejectWithValue(error.response);
      }
   },
);

// відновлення паролю
export const resetPasswordRequestThunk = createAsyncThunk(
   'auth/resetPasswordRequestThunk',
   async (data: SignUpRequest, { rejectWithValue, dispatch }) => {
      dispatch(setIsSending(true));
      try {
         const res = await authApi.restoreRequest(data);
         dispatch(registrationStage(REGISTRATION_RESTORE));
         dispatch(setIsSending(false));
         appToastr.info(i18n.t("Ми відправили код підтвердження на ваш телефон"))
         return res;
      } catch (error) {
         dispatch(setIsSending(false));
         return rejectWithValue(error.response);
      }
   },
);

export const resetPasswordCompleteThunk = createAsyncThunk(
   'auth/resetPasswordCompleteThunk',
   async (data: ResendPassword, { rejectWithValue, dispatch }) => {
      dispatch(setIsSending(true));
      try {
         const res = await authApi.restoreComplete(data);

         // TODO КОД ПОВТОРЮЄТЬСЯ
         const { type, token, expires_in } = res.data;
         const accessToken = `${type} ${token}`;

         // створення кук і хедерів для авторизації
         saveAuthTokenAndHeader(accessToken, expires_in);

         // dispatch(registrationStage(REGISTRATION_CONFIRM))
         // Отримую дані користувача
         dispatch(getProfileThunk());
         dispatch(setIsSending(false));
         appToastr.success(i18n.t("Успішно відновили пароль"),i18n.t("Успішно"))
         return res;
      } catch (error) {
         dispatch(setIsSending(false));
         appToastr.error(i18n.t("Не вдалось скинути пароль"), i18n.t("Помилка"))
         return rejectWithValue(error.response);
      }
   },
);
