import React, { FC } from 'react';
import './QueryNoFound.scss';
import i18n from "../../../../i18n";

interface Props {
   inputValue: string;
}

const QueryNoFound: FC<Props> = (props) => {
   const { inputValue } = props;

   if (inputValue === '') {
      return null;
   }

   return (
      <div className="query-not-found">
         <h3>{i18n.t("За запитом нічого не знайдено", {search: inputValue})}</h3>
         <p>{i18n.t("Переконайтесь, що ви правильно ввели назву населеного пункту, і спробуйте ще раз")}</p>
      </div>
   );
};

export default QueryNoFound;
