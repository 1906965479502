import { createAsyncThunk } from "@reduxjs/toolkit";
import hotelsAPI from "../../api/hotelsAPI";
import { parse } from 'qs';
import { SaveToWishListParams } from '../../types/favorites';
import favoriteApi from '../../api/favoriteApi';
import { updateFavoriteGroup } from '../account/favorite-group-list/favorite-group-list-reducer';
import appToastr from '../../services/appToastr';
import { isClient } from '../../helpers/common';
import i18n from "../../i18n";

export const getHotelsThunk = createAsyncThunk(
    'hotels/getHotelsThunk',
    async (search: string, {rejectWithValue}) => {
        try {
            const hotelData = await hotelsAPI.fetchHotels(search);

            return {
                hotelData,
                lastSavedPlaceId: parse(search, {ignoreQueryPrefix: true})?.place_id || '',
            }
        } catch (error) {
            throw rejectWithValue(error)
        }
    }
)

export const getHotelsForMapThunk = createAsyncThunk(
    'hotels/getHotelsForMapThunk',
    async (search: string, {rejectWithValue, signal}) => {
        try {
            return await hotelsAPI.fetchHotels(search, signal);
        } catch (error) {
            throw rejectWithValue(error)
        }
    }
)

export const setToWishListThunk = createAsyncThunk(
    'hotels/setToWishListThunk',
    async (data: SaveToWishListParams, {rejectWithValue, dispatch}) => {
        try {
            const res = await favoriteApi.saveToWishList(data);

            dispatch(updateFavoriteGroup(res.data));
            appToastr.success(i18n.t('Додано в список обраного'));
            return data;
        } catch (error) {
            appToastr.error(i18n.t('Не вдалось добавити в список обраного'));
            throw rejectWithValue(error);
        }
    },
);

interface Params {
    isFavorite: boolean;
    hotelId: number;
}

export const removeFromWishListThunk = createAsyncThunk(
    'hotels/removeFromWishListThunk',
    async (data: Params, {rejectWithValue, dispatch}) => {
        try {
            const res = await favoriteApi.removeFromWishList(data.hotelId);
            appToastr.success(i18n.t('Видалено з списку обраного'));
            return {
                hotel_id: data.hotelId,
                isFavorite: data.isFavorite,
            };

            // return data
        } catch (error) {
            appToastr.error(i18n.t('Не вдалось видалити з списку обраного'));
            throw rejectWithValue(error);
        }
    },
);

export const getHotelsNextPageThunk = createAsyncThunk(
    'hotels/getHotelsNextPageThunk',
    async (nextPageUrl: string, thunkAPI) => {
        try {
            return hotelsAPI.fetchHotelsNextPage(nextPageUrl);
        } catch (error) {
            throw thunkAPI.rejectWithValue(error);
        }
    }
)
