import React from 'react';
import './HotelRoomNoData.scss';
import { useTranslation } from "react-i18next";

type Props = {}

const HotelRoomNoData: React.FC<Props> = () => {
    const [t] = useTranslation();

    return (
        <div className={'hotel-room-no-data'}>
            <svg width="387" height="271" viewBox="0 0 387 271" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M22.5854 270.997C22.5854 270.997 -19.2152 242.066 10.9175 180.511C30.8407 139.811 52.773 144.428 49.1791 64.4448C46.1042 -3.99777 157.809 -9.91173 193.114 9.94324C228.419 29.7982 225.114 45.0416 298.025 37.3801C348.765 32.0475 374.429 105.628 355.467 139.241C328.564 186.932 411.433 221.097 379.604 271H22.5854V270.997Z"
                    fill="#FBFBFB"/>
                <path
                    d="M257 70.2019C257 70.2019 261.05 63.0257 270.735 63.8221C270.735 63.8221 284.795 51.8607 299.789 62.2276C299.789 62.2276 315.728 59.6585 327.531 64.4522C335.401 67.648 338 71 338 71L257 70.2019V70.2019Z"
                    fill="white"/>
                <g opacity="0.5">
                    <path opacity="0.5"
                          d="M153.872 108.174C153.872 108.174 150.285 90.9921 140.485 81.4077C139.182 80.1335 136.996 81.2084 137.192 83.0192C137.195 83.0491 137.199 83.0807 137.202 83.1106C137.587 86.1974 148.687 98.3935 153.87 108.174H153.872Z"
                          fill="white"/>
                </g>
                <g opacity="0.5">
                    <path opacity="0.5"
                          d="M155.388 113.05C155.388 113.05 144.631 92.1215 128.652 83.8446C127.678 83.3395 126.446 83.6618 125.906 84.6154C125.554 85.2368 125.452 86.1705 126.03 87.561C127.57 91.2725 145.578 102.648 155.39 113.048L155.388 113.05Z"
                          fill="white"/>
                </g>
                <g opacity="0.5">
                    <path opacity="0.5"
                          d="M284.971 186.003C284.971 186.003 291.742 169.809 303.179 162.252C304.699 161.247 306.643 162.715 306.108 164.456C306.1 164.485 306.09 164.514 306.08 164.544C305.118 167.503 291.912 177.378 284.971 186.003Z"
                          fill="white"/>
                </g>
                <g opacity="0.5">
                    <path opacity="0.5"
                          d="M282.562 190.503C282.562 190.503 297.084 171.987 314.341 166.883C315.393 166.573 316.542 167.121 316.893 168.161C317.12 168.839 317.044 169.773 316.213 171.03C313.999 174.385 294.165 182.148 282.562 190.503Z"
                          fill="white"/>
                </g>
                <path
                    d="M347.461 191.373C343.484 180.088 324.271 185.117 324.271 185.117C324.271 185.117 312.532 206.24 308.719 207.001C304.907 207.763 315.22 186.987 315.22 186.987C307.713 186.816 289.386 197.326 289.386 197.326C289.386 197.326 292.323 220.748 288.44 220.551C284.558 220.353 286.901 199.68 286.901 199.68C279.845 202.368 273.73 211.26 273.73 211.26C266.322 225.551 285.103 234.303 285.103 234.303C285.103 234.303 285.946 242.442 281.585 252.168C281.585 252.168 280.572 253.979 279.631 257L281.597 256.855C281.719 256.318 281.858 255.795 282.018 255.292C282.018 255.289 282.018 255.286 282.021 255.284C282.023 255.278 282.026 255.269 282.028 255.263C282.14 254.95 282.302 254.505 282.504 253.961C282.504 253.961 282.504 253.957 282.504 253.956C283.018 252.738 283.36 251.841 283.571 251.197C283.571 251.195 283.571 251.193 283.571 251.19C285.1 247.375 287.34 242.384 288.996 241.25C288.996 241.25 288.756 240.96 288.508 240.381C288.663 240.658 288.825 240.947 288.996 241.25C292.893 248.2 309.816 248.163 319.054 245.555C328.291 242.948 324.009 240.609 318.642 236.696C313.276 232.783 302.777 234.69 299.527 233.24C296.277 231.79 300.722 229.776 307.01 229.797C313.299 229.817 316.884 235.826 327.057 236.043C337.229 236.261 343.525 216.745 343.525 216.745C336.71 219.417 323.921 216.496 323.264 214.133C322.606 211.769 341.141 210.648 343.973 209.717C346.805 208.786 351.431 202.656 347.454 191.371L347.461 191.373Z"
                    fill="#F4F2F6"/>
                <path
                    d="M133.373 172.561C122.088 176.589 127.117 196.051 127.117 196.051C127.117 196.051 148.24 207.942 149.001 211.805C149.763 215.666 128.987 205.219 128.987 205.219C128.816 212.824 139.326 231.389 139.326 231.389C139.326 231.389 162.748 228.413 162.551 232.346C162.353 236.28 141.68 233.906 141.68 233.906C144.368 241.053 153.26 247.247 153.26 247.247C167.551 254.752 176.303 235.727 176.303 235.727C176.303 235.727 184.442 234.874 194.168 239.291C194.168 239.291 195.979 240.317 199 241.271L198.855 239.279C198.318 239.155 197.795 239.014 197.292 238.852C197.289 238.852 197.286 238.852 197.284 238.849C197.278 238.847 197.269 238.844 197.263 238.842C196.95 238.728 196.505 238.564 195.961 238.36H195.959C195.959 238.36 195.959 238.358 195.956 238.358C194.738 237.838 193.841 237.491 193.197 237.277C193.195 237.277 193.193 237.277 193.19 237.277C189.375 235.729 184.384 233.459 183.25 231.782C183.25 231.782 182.96 232.025 182.381 232.276C182.658 232.119 182.947 231.955 183.25 231.782C190.2 227.834 190.162 210.692 187.555 201.335C184.948 191.977 182.609 196.315 178.696 201.751C174.783 207.188 176.69 217.822 175.24 221.114C173.79 224.406 171.775 219.904 171.797 213.534C171.817 207.164 177.826 203.532 178.043 193.227C178.261 182.924 158.745 176.546 158.745 176.546C161.417 183.45 158.496 196.404 156.133 197.07C153.769 197.736 152.648 178.961 151.717 176.092C150.786 173.223 144.656 168.537 133.371 172.566L133.373 172.561Z"
                    fill="#F4F2F6"/>
                <path
                    d="M79.4816 98.1716C83.3025 87.1957 101.769 92.0857 101.769 92.0857C101.769 92.0857 113.052 112.63 116.714 113.371C120.379 114.112 110.465 93.9045 110.465 93.9045C117.681 93.7362 135.293 103.961 135.293 103.961C135.293 103.961 132.47 126.741 136.202 126.549C139.934 126.357 137.682 106.249 137.682 106.249C144.463 108.864 150.338 117.512 150.338 117.512C157.456 131.412 139.408 139.923 139.408 139.923C139.408 139.923 138.598 147.84 142.788 157.3C142.788 157.3 143.761 159.062 144.666 162L142.775 161.86C142.658 161.337 142.524 160.829 142.371 160.341C142.371 160.338 142.371 160.334 142.367 160.333C142.366 160.326 142.362 160.319 142.361 160.311C142.253 160.006 142.097 159.573 141.902 159.045C141.902 159.045 141.902 159.042 141.902 159.04C141.407 157.856 141.079 156.983 140.878 156.357C140.878 156.355 140.878 156.354 140.878 156.35C139.408 152.64 137.256 147.786 135.664 146.684C135.664 146.684 135.895 146.402 136.133 145.838C135.983 146.107 135.827 146.389 135.664 146.684C131.917 153.442 115.655 153.407 106.778 150.871C97.9001 148.334 102.016 146.059 107.174 142.255C112.331 138.451 122.42 140.305 125.544 138.894C128.667 137.483 124.396 135.525 118.353 135.545C112.309 135.565 108.863 141.407 99.0879 141.62C89.3128 141.832 83.2613 122.85 83.2613 122.85C89.811 125.448 102.1 122.608 102.734 120.308C103.366 118.01 85.5529 116.921 82.8307 116.014C80.1085 115.108 75.6639 109.147 79.4849 98.1716H79.4816Z"
                    fill="#F4F2F6"/>
                <path d="M293 72H176V271H293V72Z" fill="white"/>
                <path d="M281.296 90.1133H258.705V122.837H281.296V90.1133Z" fill="#F4F2F6"/>
                <path d="M269.109 90.1133C269.109 90.1133 269.351 101.022 258.705 109.486V90.1133H269.109Z"
                      fill="#F4F2F6"/>
                <path d="M271.34 90.1133C271.34 90.1133 271.109 101.022 281.298 109.486V90.1133H271.34Z"
                      fill="#F4F2F6"/>
                <path d="M270.567 90.1152H270V122.839H270.567V90.1152Z" fill="white"/>
                <path d="M281.298 99.8008H258.705V100.256H281.298V99.8008Z" fill="white"/>
                <path d="M281.298 111.662H258.705V112.117H281.298V111.662Z" fill="white"/>
                <path
                    d="M281.811 123.352H258.189V89.5996H281.811V123.352ZM259.22 122.322H280.782V90.628H259.22V122.322Z"
                    fill="white"/>
                <path d="M245.759 90.1133H223.168V122.837H245.759V90.1133Z" fill="#F4F2F6"/>
                <path d="M233.571 90.1133C233.571 90.1133 233.814 101.022 223.168 109.486V90.1133H233.571Z"
                      fill="#F4F2F6"/>
                <path d="M235.803 90.1133C235.803 90.1133 235.572 101.022 245.761 109.486V90.1133H235.803Z"
                      fill="#F4F2F6"/>
                <path d="M235.029 90.1152H234.463V122.839H235.029V90.1152Z" fill="white"/>
                <path d="M245.759 99.8008H223.166V100.256H245.759V99.8008Z" fill="white"/>
                <path d="M245.759 111.662H223.166V112.117H245.759V111.662Z" fill="white"/>
                <path
                    d="M246.274 123.352H222.652V89.5996H246.274V123.352ZM223.682 122.322H245.245V90.628H223.682V122.322Z"
                    fill="white"/>
                <path d="M210.216 90.1133H187.625V122.837H210.216V90.1133Z" fill="#F4F2F6"/>
                <path d="M198.028 90.1133C198.028 90.1133 198.269 101.022 187.625 109.486V90.1133H198.028Z"
                      fill="#F4F2F6"/>
                <path d="M200.26 90.1133C200.26 90.1133 200.029 101.022 210.218 109.486V90.1133H200.26Z"
                      fill="#F4F2F6"/>
                <path d="M199.488 90.1152H198.922V122.839H199.488V90.1152Z" fill="white"/>
                <path d="M210.22 99.8008H187.627V100.256H210.22V99.8008Z" fill="white"/>
                <path d="M210.22 111.662H187.627V112.117H210.22V111.662Z" fill="white"/>
                <path
                    d="M210.733 123.352H187.111V89.5996H210.733V123.352ZM188.141 122.322H209.704V90.628H188.141V122.322Z"
                    fill="white"/>
                <path d="M281.296 139.252H258.705V171.976H281.296V139.252Z" fill="#F4F2F6"/>
                <path d="M269.109 139.252C269.109 139.252 269.351 150.16 258.705 158.625V139.252H269.109Z"
                      fill="#F4F2F6"/>
                <path d="M271.34 139.252C271.34 139.252 271.109 150.16 281.298 158.625V139.252H271.34Z" fill="#F4F2F6"/>
                <path d="M270.567 139.254H270V171.978H270.567V139.254Z" fill="white"/>
                <path d="M281.298 148.939H258.705V149.395H281.298V148.939Z" fill="white"/>
                <path d="M281.298 160.803H258.705V161.258H281.298V160.803Z" fill="white"/>
                <path
                    d="M281.811 172.492H258.189V138.738H281.811V172.492ZM259.22 171.462H280.782V139.768H259.22V171.462Z"
                    fill="white"/>
                <path d="M245.759 139.252H223.168V171.976H245.759V139.252Z" fill="#F4F2F6"/>
                <path d="M233.571 139.252C233.571 139.252 233.814 150.16 223.168 158.625V139.252H233.571Z"
                      fill="#F4F2F6"/>
                <path d="M235.803 139.252C235.803 139.252 235.572 150.16 245.761 158.625V139.252H235.803Z"
                      fill="#F4F2F6"/>
                <path d="M235.029 139.254H234.463V171.978H235.029V139.254Z" fill="white"/>
                <path d="M245.759 148.939H223.166V149.395H245.759V148.939Z" fill="white"/>
                <path d="M245.759 160.803H223.166V161.258H245.759V160.803Z" fill="white"/>
                <path
                    d="M246.274 172.492H222.652V138.738H246.274V172.492ZM223.682 171.462H245.245V139.768H223.682V171.462Z"
                    fill="white"/>
                <path d="M210.216 139.252H187.625V171.976H210.216V139.252Z" fill="#F4F2F6"/>
                <path d="M198.028 139.252C198.028 139.252 198.269 150.16 187.625 158.625V139.252H198.028Z"
                      fill="#F4F2F6"/>
                <path d="M200.26 139.252C200.26 139.252 200.029 150.16 210.218 158.625V139.252H200.26Z" fill="#F4F2F6"/>
                <path d="M199.488 139.254H198.922V171.978H199.488V139.254Z" fill="white"/>
                <path d="M210.22 148.939H187.627V149.395H210.22V148.939Z" fill="white"/>
                <path d="M210.22 160.803H187.627V161.258H210.22V160.803Z" fill="white"/>
                <path
                    d="M210.733 172.492H187.111V138.738H210.733V172.492ZM188.141 171.462H209.704V139.768H188.141V171.462Z"
                    fill="white"/>
                <path d="M245.759 188.389H223.168V221.113H245.759V188.389Z" fill="#F4F2F6"/>
                <path d="M233.571 188.389C233.571 188.389 233.814 199.297 223.168 207.762V188.389H233.571Z"
                      fill="#F4F2F6"/>
                <path d="M235.803 188.389C235.803 188.389 235.572 199.297 245.761 207.762V188.389H235.803Z"
                      fill="#F4F2F6"/>
                <path d="M235.029 188.389H234.463V221.113H235.029V188.389Z" fill="white"/>
                <path d="M245.759 198.076H223.166V198.531H245.759V198.076Z" fill="white"/>
                <path d="M245.759 209.938H223.166V210.393H245.759V209.938Z" fill="white"/>
                <path
                    d="M246.274 221.627H222.652V187.875H246.274V221.627ZM223.682 220.597H245.245V188.903H223.682V220.597Z"
                    fill="white"/>
                <path d="M210.216 188.389H187.625V221.113H210.216V188.389Z" fill="#F4F2F6"/>
                <path d="M198.028 188.389C198.028 188.389 198.269 199.297 187.625 207.762V188.389H198.028Z"
                      fill="#F4F2F6"/>
                <path d="M200.26 188.389C200.26 188.389 200.029 199.297 210.218 207.762V188.389H200.26Z"
                      fill="#F4F2F6"/>
                <path d="M199.488 188.389H198.922V221.113H199.488V188.389Z" fill="white"/>
                <path d="M210.22 198.076H187.627V198.531H210.22V198.076Z" fill="white"/>
                <path d="M210.22 209.938H187.627V210.393H210.22V209.938Z" fill="white"/>
                <path
                    d="M210.733 221.627H187.111V187.875H210.733V221.627ZM188.141 220.597H209.704V188.903H188.141V220.597Z"
                    fill="white"/>
                <path d="M296 243H174V271H296V243Z" fill="#F4F2F6"/>
                <path d="M298 79H172L178.718 49H291.283L298 79Z" fill="#F4F2F6"/>
                <path d="M206.168 58.0469H194.592V69.6232H206.168V58.0469Z" fill="#FBFBFB"/>
                <path
                    d="M194.055 57.5117V70.158H206.703V57.5117H194.055V57.5117ZM205.631 69.0864H195.128V58.5816H205.631V69.0864Z"
                    fill="#D1CFD5"/>
                <path d="M200.902 69.6213V58.0449H199.854V69.6213H200.902Z" fill="#D1CFD5"/>
                <path d="M208.514 55.3438H191.756V57.771H208.514V55.3438Z" fill="#D1CFD5"/>
                <path d="M240.623 58.0469H229.047V69.6232H240.623V58.0469Z" fill="#FBFBFB"/>
                <path
                    d="M228.51 57.5117V70.158H241.158V57.5117H228.51V57.5117ZM240.084 69.0864H229.58V58.5816H240.084V69.0864Z"
                    fill="#D1CFD5"/>
                <path d="M235.357 69.6232V58.0469H234.309V69.6232H235.357Z" fill="#D1CFD5"/>
                <path d="M242.84 55.3438H226.082V57.771H242.84V55.3438Z" fill="#D1CFD5"/>
                <path d="M274.947 58.0469H263.371V69.6232H274.947V58.0469Z" fill="#FBFBFB"/>
                <path
                    d="M262.832 57.5117V70.158H275.48V57.5117H262.832V57.5117ZM274.408 69.0864H263.904V58.5816H274.408V69.0864Z"
                    fill="#D1CFD5"/>
                <path d="M269.68 69.6232V58.0469H268.631V69.6232H269.68Z" fill="#D1CFD5"/>
                <path d="M277.164 55.3438H260.406V57.771H277.164V55.3438Z" fill="#D1CFD5"/>
                <path
                    d="M59 52.5092C59 52.5092 61.3508 48.0924 66.9696 48.5832C66.9696 48.5832 75.1288 41.2218 83.8274 47.6016C83.8274 47.6016 93.0754 46.0213 99.9252 48.971C104.491 50.9374 106 53 106 53L59 52.5092Z"
                    fill="white"/>
                <path
                    d="M98 33.9586C98 33.9586 103.301 24.5732 115.975 25.6145C115.975 25.6145 134.375 9.97104 153.996 23.5285C153.996 23.5285 174.853 20.1686 190.3 26.4365C200.598 30.6151 204 35 204 35L98 33.957V33.9586Z"
                    fill="white"/>
                <path
                    d="M80 70.2019C80 70.2019 84.0502 63.0257 93.7346 63.8221C93.7346 63.8221 107.795 51.8607 122.789 62.2276C122.789 62.2276 138.728 59.6585 150.531 64.4522C158.401 67.648 161 71 161 71L80 70.2019V70.2019Z"
                    fill="white"/>
                <g clipPath="url(#clip0_4965_73902)">
                    <path
                        d="M66.335 256.924L65.5773 255.683C64.3689 253.705 65.1192 251.192 67.2522 250.075L150.899 206.256L155.52 214.576L72.3575 258.463C70.2365 259.582 67.5397 258.895 66.335 256.924Z"
                        fill="#D1CFD5"/>
                    <path
                        d="M213.992 230.76C190.132 243.793 159.741 235.297 146.21 211.067C132.355 186.254 141.755 155.155 167.088 142.366C191.964 129.807 222.056 140.121 234.44 164.677C246.543 188.675 237.441 217.953 213.992 230.76ZM210.38 223.953C230.192 213.197 237.857 188.491 227.582 168.283C217.107 147.681 191.815 139.143 170.993 149.725C149.853 160.47 141.978 186.458 153.488 207.241C164.772 227.614 190.275 234.869 210.38 223.953Z"
                        fill="#D1CFD5"/>
                    <g style={{mixBlendMode: 'multiply'}} opacity="0.3">
                        <path
                            d="M150.898 206.256L140.233 211.843C140.201 215.486 139.112 217.785 137.845 219.305C133.079 225.024 124.332 221.551 114.775 229.043C109.98 232.802 111.369 234.315 106.736 237.193C99.0756 241.952 94.3403 238.402 87.5778 242.692C80.7374 247.031 81.33 253.361 77.0547 253.976C75.2259 254.24 72.6264 253.437 69.0012 249.158L67.2522 250.075C65.1192 251.192 64.3689 253.705 65.5773 255.683L66.335 256.924C67.5397 258.895 70.2365 259.582 72.3575 258.463L155.519 214.576L150.898 206.257V206.256Z"
                            fill="#F4F2F6"/>
                    </g>
                    <path
                        d="M216.199 232.639C192.339 245.672 161.948 237.176 148.417 212.946C134.562 188.133 143.962 157.034 169.295 144.245C194.171 131.686 224.263 142 236.647 166.556C248.75 190.554 239.648 219.833 216.199 232.64V232.639ZM212.587 225.832C232.399 215.075 240.064 190.37 229.789 170.162C219.314 149.56 194.021 141.021 173.2 151.604C152.06 162.349 144.185 188.337 155.695 209.12C166.979 229.493 192.482 236.748 212.587 225.832Z"
                        fill="#D1CFD5"/>
                    <path
                        d="M214.924 230.238C192.388 242.521 163.727 234.459 150.995 211.593C137.975 188.207 146.835 158.922 170.679 146.855C194.118 134.993 222.514 144.673 234.226 167.831C245.686 190.491 237.091 218.157 214.924 230.238ZM212.587 225.833C232.399 215.077 240.064 190.371 229.789 170.163C219.313 149.561 194.021 141.023 173.2 151.605C152.06 162.35 144.184 188.338 155.695 209.121C166.979 229.494 192.482 236.749 212.587 225.833Z"
                        fill="#F4F2F6"/>
                </g>
                <defs>
                    <clipPath id="clip0_4965_73902">
                        <rect width="177" height="122" fill="white" transform="translate(65 137)"/>
                    </clipPath>
                </defs>
            </svg>

            <span className={'hotel-room-no-data__text'}>{t("За вашим пошуком нічого не знайдено")}</span>
            <span className={'hotel-room-no-data__subtext'}>{t("Будь ласка, змініть дати або уточніть параметри фільтрів")}</span>

        </div>
    )
};

export default HotelRoomNoData;
