import React, { FC, useEffect } from 'react';
import './HotelIndex.scss';
import Page from '../../../components/common/Page/Page';
import HotelIndexPlaces from './components/HotelIndexPlaces/HotelIndexPlaces';
import { scrollToTop } from '../../../helpers/common';
import Head from '../../../components/layout/components/Head/Head';
import { Trans, useTranslation } from "react-i18next";
import PriceViewer from "../../../components/ui/components/PriceViewer/PriceViewer";

const HotelIndex: FC = () => {
    useEffect(scrollToTop, []);

    const [t] = useTranslation();
    const title = t("home_page_title");
    const description = t("home_page_description")

    return (
        <Page isSearchFormOpen={true} className={'page--index-variant'}>
            <Head
              ogImage="/images/index/voyagehub-places/1.png"
              title={title}
              metaDescription={description}
              ogDescription={description}
              ogTitle={title}>
                <meta name="theme-color" content="#213353"/>
            </Head>

            <div className={'hotel-index-background-image'}/>
            <div className="hotel-index">
                <div className="hotel-index__wrapper"></div>
                <HotelIndexPlaces/>
            </div>
        </Page>
    );
};

export default HotelIndex;
