import React, { FC } from 'react';
import './HotelReviews.scss';
import HotelReviewItem from './HotelReviewItem/HotelReviewItem';
import useSwitcher from '../../../../../hooks/useSwitcher';
import HotelReviewsModal from './HotelReviewsModal/HotelReviewsModal';
import { Review } from '../../../../../types/reviews';
import HotelDetailContentBlock from '../HotelDetailContentBlock/HotelDetailContentBlock';
import useWindowSize from '../../../../../hooks/useWindowSize';
import NoReviews from './NoReviews/NoReviews';
import { useTranslation } from "react-i18next";

interface Props {
    reviews: Array<Review>;
}

const HotelReviews: FC<Props> = props => {
    const [t] = useTranslation();
    const {onClose, onOpen, isOpen} = useSwitcher(false);
    const {reviews} = props;
    const commentsArray = reviews.length ? reviews.slice(0, 4) : [];
    const commentsShowMoreArray = reviews.length ? reviews.slice(reviews.length - 3, reviews.length) : [];
    const {isMobile} = useWindowSize();

    if(!reviews || !reviews.length) {
        return (
            <HotelDetailContentBlock title={t('Відгуки')} noBorder={isMobile}>
                <NoReviews/>
            </HotelDetailContentBlock>
        )
    }

    return (
        <HotelDetailContentBlock title={t("Відгуки")} noBorder={isMobile}>
            <div className="hotel-reviews">
                <div className="hotel-reviews__list">
                    {commentsArray.map((el, index) => <HotelReviewItem
                        review={el} isModal={false} key={'reviewItem' + index + el.id}/>
                    )}
                </div>

                <div className="hotel-reviews__image-list">
                    {commentsShowMoreArray.map((el, index) => <img
                            className="hotel-reviews__image-list-item"
                            key={'commentsShowMoreArray' + index + el.id}
                            src={el.user.photo}
                            alt={''}
                        />
                    )}

                    {reviews.length > 4 ? (
                        <button className="hotel-reviews__button-show-all" onClick={onOpen}>
                            + {reviews.length - 4} {t("відгуки")}
                        </button>
                    ) : null}
                </div>

                {isOpen ? <HotelReviewsModal comments={reviews} onClose={onClose}/> : null}
            </div>
        </HotelDetailContentBlock>
    );
};

export default HotelReviews;
