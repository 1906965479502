import React, { FC, useState } from 'react';
import './AccountOrdersList.scss';
import Page from '../../../../components/common/Page/Page';
import Container from '../../../../components/ui/layouts/Container/Container';
import NextTrips from './NextTrips/NextTrips';
import LastTrips from './LastTrips/LastTrips';
import PageHeader from '../../common/PageHeader/PageHeader';
import ConversationsTabs from '../../common/ConversationsTabs/ConversationsTabs';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { getOrdersThunk } from '../../../../store/account/orders/orders-thunks';
import {
   reservationSelector,
   ordersFutureGrouped,
   ordersPrevGrouped,
   reservationCanceledSelector,
} from '../../../../store/account/orders/orders-selectors';
import NoTrips from './NextTrips/NoTrips/NoTrips';
import { hasAnyPropLength } from '../../../../helpers/common';
import { RouteNames } from '../../../../routes';
import OrdersListLoader from "./components/OrdersListLoader";
import { useTranslation } from "react-i18next";


const AccountOrdersList = () => {
   const [isFuture, setIsFuture] = useState<boolean>(true);
   const { isFetching} = useSelector(reservationSelector);
   const prevOrders = useSelector(ordersPrevGrouped)
   const futureOrders = useSelector(ordersFutureGrouped)
   const canceledReservations = useSelector(reservationCanceledSelector)
   const [t ] = useTranslation();

   const breadcrumbs = [
      {
         url: RouteNames.PROFILE_INDEX,
         title: t("Акаунт"),
      },
      {
         title: t("Мої подорожі"),
      },
   ];

   const renderView = () => {
      let orders = isFuture ? futureOrders : prevOrders
      const hasLength = hasAnyPropLength(orders)

      if(!isFetching && hasLength){
         return  <LastTrips orders={orders}/>
      } else if(!isFetching && !hasLength){
         return <NoTrips/>
      }else {
         return <OrdersListLoader/>
      }
   }


   return (
      <Page>
         <Container>
            <PageHeader breadcrumbs={breadcrumbs} title={t("Мої подорожі")} rightComponent={ <ConversationsTabs
               onClickRight={()=> setIsFuture(false)}
               onClickLeft={()=>setIsFuture(true)}
               leftTextButton={t("Найближчі")}
               rightTextButton={t("Минулі")}
            />} />
            {renderView()}

            {!!canceledReservations.length && <NextTrips reservations={canceledReservations}/>}
         </Container>
      </Page>
   );
};


AccountOrdersList.getInitialProps = ({ store, ...rest }) => {

   return store.dispatch(getOrdersThunk())
      .then(unwrapResult)
      .catch(error => {
         // this code will redirect to 404 page after any error
         return { statusCode: 404 };
      });
};

export default AccountOrdersList;
