import React from 'react';
import './HotelsListActions.scss';

import ButtonSecondary from '../../../../../components/ui/components/Buttons/ButtonsSecondary/ButtonSecondary';
import IconFilter from '../../../../../components/ui/icons/hotels/IconFilter';
import IconSort from '../../../../../components/ui/icons/hotels/IconSort';
import IconMap from '../../../../../components/ui/icons/hotels/IconMap';
import IconFrameDots from '../../../../../components/ui/icons/icons/IconFrameDots';
import { useHotelsFiltersVisibility } from '../../useHotelsFiltersVisibility';
import { MobileOnly, TabletAndUpper } from '../../../../../components/common/Media/MediaHelpers';
import { useTranslation } from "react-i18next";

type Props = {
    onClickOnFilter?: () => void;
    onClickOnSort?: () => void;
    onClickOnMapButton: () => void;
    isOnMap?: boolean;
    isNoHotels?: boolean
};

const HotelsListActions: React.FC<Props> = (props) => {
    const {
        onClickOnMapButton,
        onClickOnSort,
        isOnMap,
        isNoHotels
    } = props;
    const [t] = useTranslation();

    const {filtersInitData, openFilters, activeFiltersCount} = useHotelsFiltersVisibility();

    const renderListMapButton = (isMobile = false) => {
        const listTextOnMap = isMobile ? t("Список") : t('Плитка');

        return (
            <ButtonSecondary
                onClick={onClickOnMapButton}
                icon={isOnMap ? <IconFrameDots/> : <IconMap/>}
                text={isOnMap ? listTextOnMap : t('Карта')}
                disabled={isNoHotels}
            />
        )
    }

    return (
        <div className={`hotels-list-actions ${isOnMap ? 'hotels-list-actions--is-on-map' : ''}`}>
            <ButtonSecondary
                icon={<IconSort/>}
                className={'hotels-list-actions__sort-icon'}
                text={t("Сортувати")}
                onClick={() => {
                    if (onClickOnSort) onClickOnSort();
                }}
                disabled={isOnMap || isNoHotels}/>

            <ButtonSecondary
                onClick={() => {
                    if (filtersInitData) openFilters()
                }}
                icon={<IconFilter/>}
                text={t("Фільтр")}
                badgeContent={activeFiltersCount > 0 ? <span>{activeFiltersCount}</span> : ''}
                disabled={!filtersInitData || isNoHotels}
            />

            <MobileOnly>
                {renderListMapButton(true)}
            </MobileOnly>

            <TabletAndUpper>
                {renderListMapButton(false)}
            </TabletAndUpper>
        </div>
    );
}

export default HotelsListActions;
