import React, { FC, memo } from 'react';
import './ProfileLink.scss';
import { useLocation, useHistory } from 'react-router-dom';
import Image from '../../../../../ui/components/Image/Image';
import { useTranslation } from 'react-i18next';
import NoAvatar from '../../../../../../screens/Account/common/NoAvatar/NoAvatar';
import OutsideClick from '../../../../../common/OutsideClick/OutsideClick';
import useSwitcher from '../../../../../../hooks/useSwitcher';
import HeaderMenuItem from '../HeaderMenuItem/HeaderMenuItem';
import IconProfile from '../../../../../ui/icons/account/IconProfile';
import IconExit from '../../../../../ui/icons/account/IconExit';
import IconTravel from '../../../../../ui/icons/account/IconTravel';
import IconSaved from '../../../../../ui/icons/account/IconSaved';
import useLogout from '../../../../../../hooks/useLogout';
import { RouteNames } from '../../../../../../routes';
import IconFilter from "../../../../../ui/icons/hotels/IconFilter";

interface Props {
   name: string;
   lastname: string;
   avatar?: string;
}

interface ProfileProps {
   onClose: () => void;
}

const ProfileDropdown: FC<ProfileProps> = ({ onClose }) => {
   const history = useHistory();
   const { pathname } = useLocation();
   const [setLogout] = useLogout();
   const [t] = useTranslation();

   const redirectTo = path => {
      if (pathname !== path) {
         history.push(path);
      }
   };

   return (
      <div className="profile-dropdown">
         <OutsideClick onOutsideClick={onClose}>
            <ul className="profile-dropdown__list">
               <li>
                  <HeaderMenuItem
                    icon={<IconProfile />}
                    title={t("Акаунт")}
                    onClick={() => redirectTo(RouteNames.PROFILE_INDEX)}
                  />
               </li>
               <li>
                  <HeaderMenuItem
                     icon={<IconFilter/>}
                     title={t("Персональна інформація")}
                     onClick={() => redirectTo(RouteNames.PROFILE_EDIT)}
                  />
               </li>
               <li>
                  <HeaderMenuItem
                     icon={<IconSaved />}
                     title={t("Обране")}
                     onClick={() => redirectTo(RouteNames.PROFILE_FAVORITE)}
                  />
               </li>
               {/*<li>*/}
               {/*   <HeaderMenuItem*/}
               {/*      icon={<IconPayment />}*/}
               {/*      title={'Оплата'}*/}
               {/*      onClick={() => redirectTo(RouteNames.PROFILE_PAYMENT)}*/}
               {/*   />*/}
               {/*</li>*/}
               {/*<li>*/}
               {/*   <HeaderMenuItem*/}
               {/*      icon={<IconMessages />}*/}
               {/*      title={'Повідомлення'}*/}
               {/*      onClick={() => redirectTo(RouteNames.PROFILE_MESSAGE)}*/}
               {/*      rightIcon={<NotificationNumber>2</NotificationNumber>}*/}
               {/*   />*/}
               {/*</li>*/}
               <li>
                  <HeaderMenuItem
                     icon={<IconTravel />}
                     title={t("Подорожі")}
                     onClick={() => redirectTo(RouteNames.PROFILE_ORDERS_LIST)}
                  />
               </li>
               {/*<li>*/}
               {/*   <HeaderMenuItem*/}
               {/*      icon={<IconSettings />}*/}
               {/*      title={'Налаштування'}*/}
               {/*      onClick={() => redirectTo(RouteNames.PROFILE_SETTINGS)}*/}
               {/*   />*/}
               {/*</li>*/}
               <li className="profile-dropdown-item--active">
                  <HeaderMenuItem icon={<IconExit />} title={t("Вийти")} onClick={setLogout} />
               </li>
            </ul>
         </OutsideClick>
      </div>
   );
};

const ProfileLink: FC<Props> = memo(props => {
   const [t] = useTranslation();
   const { avatar, name = '', lastname = '' } = props;
   const { isOpen, onClose, onToggle, onOpen } = useSwitcher(false);

   const renderAvatar = () => {
      if (avatar) {
         return  <Image src={avatar} width={36} height={36} alt="your photo" />
      } else {
         if (name && lastname) {
            return <NoAvatar size={36} letters={`${name.slice(0, 1)}${lastname.slice(0, 1)}`} fontSize={16} />
         }
      }
   }

   return (
      <div className="profile-links-wrapper">
         <figure className="profile-links" onClick={onToggle}>
            <div className="profile-links__avatar">
               {renderAvatar()}
            </div>
            <figcaption>
               <ul>
                  <li>{t('Привіт')},</li>
                  <li>{`${name} ${lastname}`}</li>
               </ul>
            </figcaption>
         </figure>
         {isOpen && <ProfileDropdown onClose={onClose} />}
      </div>
   );
});

export default ProfileLink;
