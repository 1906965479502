import React from "react";
import * as Yup from 'yup';
import { parsePhoneNumber } from 'libphonenumber-js';
import { parse, isDate, format } from "date-fns";
import i18n from "../i18n";
import {Trans} from 'react-i18next';

console.log(<Trans>Створіть свій перший список обраного</Trans>);


const messages = {
    required: <Trans>Заповніть це поле</Trans>,
    invalidEmail: <Trans>E-mail невалідний</Trans>,
    invalidPhone: <Trans>Телефон не є валідним</Trans>,
    atLeastEight: <Trans>не менше 8 символів</Trans>,
    selectDateToNow: <Trans>Оберіть дату до сьогодні</Trans>,
    trueAge: <Trans>Вкажіть справжній свій вік</Trans>,
    invalidDate: <Trans>Дата не є валідною</Trans>,
    selectSex: <Trans>Оберіть стать</Trans>,
    atLeastFour: <Trans>не менше 4 символів</Trans>,
    passwordNoMach: <Trans>паролі не збігаються</Trans>,
} as any

Yup.setLocale({
    mixed: {
        required: messages.required,
    },
    string: {
        email: messages.invalidEmail
    }
})



// вілідації телефону
Yup.addMethod(Yup.string, 'phone', function (message: string) {
    return this.test({
        message,
        name: 'phone',
        test(value) {
            try {
                // @ts-ignore
                const cleanString = value.replace(/[|&;$%@"<>()+,]/g, '');
                const isValid = parsePhoneNumber(`+${cleanString}`).isValid();

                return isValid;
            } catch (error) {
                return false;
            }
        },
    });
});

// вілідації дати
Yup.addMethod(Yup.string, 'date', function (message: string) {
    return this.test({
        message,
        name: 'date',
        test(value) {
            // @ts-ignore
            return /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.\d{4}$/.test(value)
        },
    });
});

// @ts-ignore
const defaultPhoneSchema = Yup.string().required(messages.required).min(8, messages.invalidPhone).phone(messages.invalidPhone).nullable();

// @ts-ignore
export const signInSchema = Yup.object({
    // @ts-ignore
    login: defaultPhoneSchema,
    password: Yup.string().required(messages.required),
});

export const phoneSchema = Yup.object({
    // @ts-ignore
    login: defaultPhoneSchema,
});

export const codeSchema = Yup.object({
    code: Yup.string().required(messages.required).min(4, messages.atLeastFour),
});

export const completeSchema = Yup.object({
    name: Yup.string().required(messages.required),
    lastname: Yup.string().required(messages.required),
    email: Yup.string().email(messages.invalidEmail).required(messages.required),
    gender: Yup.number().oneOf([1, 2], messages.selectSex).required(messages.required),
    // @ts-ignore
    birthday: Yup.date()
      .transform(function (value, originalValue) {
          if (this.isType(value)) {
              return value;
          }
          const result = parse(originalValue, "dd.MM.yyyy", new Date());

          return result;
      })
      .typeError(messages.invalidDate)
      .required()
      .min("1900-01-01", messages.trueAge)
      .max(format(new Date, "yyyy-MM-dd"), messages.selectDateToNow),
    password: Yup.string().required(messages.required),
    password_confirmation: Yup.string()
        .required(messages.required)
        // @ts-ignore
        .oneOf([Yup.ref('password', '')], messages.passwordNoMach)
        .min(8, messages.atLeastEight),
});

export const formResetSchema = Yup.object({
    // @ts-ignore
    login: defaultPhoneSchema,
    code: Yup.string().required(messages.required).min(4, messages.atLeastFour),
    password: Yup.string().required(messages.required),
    password_confirmation: Yup.string()
        .required(messages.required)
        // @ts-ignore
        .oneOf([Yup.ref('password', '')], messages.passwordNoMach)
        .min(8, messages.atLeastEight),
})

// function parseDateString(value, originalValue) {
//   const parsedDate = isDate(originalValue)
//      ? originalValue
//      : parse(originalValue, "dd.MM.yyyy", new Date());
//
//   return parsedDate;
// }
// birthday: Yup.date('Дата не є валідною').transform(parseDateString),//.date('Дата не є валідною'), // .date('Дата не є валідною'),

export const editProfilePersonalSchema = Yup.object({
    name: Yup.string().required(messages.required),
    lastname: Yup.string().required(messages.required),
    //@ts-ignore
    // birthday: Yup.string().date('Дата не є валідною'), // .date('Дата не є валідною'),


    birthday: Yup.date()
      .transform(function (value, originalValue) {
          if (this.isType(value)) {
              return value;
          }
          const result = parse(originalValue, "dd.MM.yyyy", new Date());

          return result;
      })
      .typeError(messages.invalidDate)
      .required()
      .min("1900-01-01", messages.trueAge)
      .max(format(new Date, "yyyy-MM-dd"), messages.selectDateToNow),

    gender: Yup.number().oneOf([1, 2], messages.selectSex).required(messages.required),
})

export const editProfilePlaceSchema = Yup.object({
    countryId: Yup.number().required(messages.required),
    city: Yup.string().required(messages.required),
})

export const editProfileChangePasswordSchema = Yup.object({
    code: Yup.string().required(messages.required).min(4, messages.atLeastFour),
    password: Yup.string().required(messages.required),
    password_confirmation: Yup.string()
        .required(messages.required)
        // @ts-ignore
        .oneOf([Yup.ref('password', '')], messages.passwordNoMach)
        .min(8, messages.atLeastEight),
})

export const editProfileContactsSchema = Yup.object({
    // @ts-ignore
    phone: defaultPhoneSchema,
    email: Yup.string().email(messages.invalidEmail).required(messages.required),
    showCode: Yup.boolean(),
    code: Yup.string().when("showCode", {
        is: true,
        then: Yup.string().required(messages.required).min(4, messages.atLeastFour)
    }),
    password: Yup.string().when("showPassword", {
        is: true,
        then: Yup.string().required(messages.required).min(8, messages.atLeastEight)
    }),
})

export const switchCountrySchema = Yup.object({
    country: Yup.string().required(messages.required),
})

export const switchLanguageSchema = Yup.object({
    language: Yup.string().required(messages.required),
})

export const switchCurrencySchema = Yup.object({
    currency: Yup.string().required(messages.required),
})

export const uploadPhotoSchema = Yup.object({
    photo: Yup.mixed().required(<Trans>Загрузіть фото</Trans>),
})

export const editFavoriteGroupName = Yup.object({
    name: Yup.string().required(messages.required),
})

export const contactSchema = Yup.object({
    name: Yup.string().required(),
    // @ts-ignore
    phone: defaultPhoneSchema,
    email: Yup.string().email(messages.invalidEmail).required(messages.required),
    comment: Yup.string().required(),
})

export const feedbackFormSchema = Yup.object({
    // @ts-ignore
    phone: defaultPhoneSchema,
})


export const  selectRegistrationHotelForm =  Yup.object({
    hotel_id: Yup.string().nullable().required(messages.required),
})

export const  newHotelRegistrationForm =  Yup.object({
    // @ts-ignore
    phone: defaultPhoneSchema,
})


export const  registrationFormCompleteSchema =  Yup.object({
    // @ts-ignore
    phone: defaultPhoneSchema,
    admin_name: Yup.string().required(messages.required),
    admin_lastname: Yup.string().required(messages.required),
    email: Yup.string().email(messages.invalidEmail).required(messages.required),
    password: Yup.string().required(messages.required),
    password_confirmation: Yup.string()
      .required(messages.required)
      // @ts-ignore
      .oneOf([Yup.ref('password', '')], messages.passwordNoMach)
      .min(8, messages.atLeastEight),

})



export const phoneCallbackSchema = Yup.object({
    // @ts-ignore
    phone: defaultPhoneSchema,
});