import React, { useRef } from 'react';
import './ActionsDropdown.scss';
import useOutside from '../../../../hooks/useOutside';
import useSwitcher from '../../../../hooks/useSwitcher';
import IconEdit from '../../icons/icons/IconEdit';
import IconTrash from '../../icons/icons/IconTrash';
import { useTranslation } from "react-i18next";

type Props = {
    onEdit: () => void,
    onRemove: () => void
}

const ActionsDropdown: React.FC<Props> = ({onEdit, onRemove}) => {
    const {isOpen, onClose, onToggle} = useSwitcher();
    const ref = useRef();
    const [t] = useTranslation();

    useOutside(ref, onClose);

    return (
        <div ref={ref} className={'actions-dropdown'}>
            <button className={'actions-dropdown__button'} onClick={onToggle}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="12" r="2" fill="#6D7188"/>
                    <circle cx="12" cy="12" r="2" fill="#6D7188"/>
                    <circle cx="18" cy="12" r="2" fill="#6D7188"/>
                </svg>
            </button>

            {isOpen && <ul className={'actions-dropdown__list'}>
               <li className={'actions-dropdown__list-item'}>
                  <button
                     onClick={() => {
                         onClose();
                         onEdit();
                     }}
                  >
                     <i><IconEdit fill={'#C2C7CF'} size={18}/></i>
                     <span>{t("Редагувати")}</span>
                  </button>
               </li>

               <li className={'actions-dropdown__list-item'}>
                  <button
                     onClick={() => {
                         onClose()
                         onRemove()
                     }}
                     className={'actions-dropdown__list-item-remove-button'}
                  >
                     <i><IconTrash/></i>
                     <span>{t("Видалити")}</span>
                  </button>
               </li>
            </ul>}
        </div>
    )
};

export default ActionsDropdown;
