import React, { FC } from "react";
import { useSelector } from 'react-redux';

import "./AccountSettings.scss";
import Page from "../../../components/common/Page/Page";
import Container from "../../../components/ui/layouts/Container/Container";
import PageHeader from "../common/PageHeader/PageHeader";
import LinksBlock from "./componnents/LinksBlock/LinksBlock";
import ModalRoutes from "./componnents/ModalRoutes/ModalRoutes";
import { currencySelector, initialSelector, languageSelector } from '../../../store/initial/initial-selectors';
import { RouteNames } from '../../../routes';

const AccountSettings: FC = () => {
    const initial = useSelector(initialSelector);
    const languages = useSelector(languageSelector);
    const currencies = useSelector(currencySelector);

    const selectedLanguageName = languages.find((language) => language.value == initial.language);
    const selectedCurrencyName = currencies.find((currency) => currency.value == initial.currency);

    const breadcrumbs = [{
        url: RouteNames.PROFILE_INDEX,
        title: 'Акаунт',
    }, {
        title: 'Налаштування аккаунта'
    }]

    const blocks = [
        //{
        //   title:"Налаштування аккаунта",
        //   links: [
        //     {
        //
        //       title: 'Мова',
        //       value: selectedLanguageName.key,
        //       blank: false,
        //       to: '/profile/settings/language',
        //     },
        //     {
        //       title: 'Валюта',
        //       value: selectedCurrencyName.key,
        //       blank: false,
        //       to: '/profile/settings/currency',
        //     },
        //     {
        //       title: 'Країна',
        //       value: 'Україна',
        //       blank: false,
        //       to: '/profile/settings/country',
        //     }
        //   ]
        // },
        {
            title: "Підтримка",
            links: [
                {
                    title: 'Отримати допомогу',
                    value: '',
                    blank: false,
                    to: '/',
                },
                {
                    title: 'Дайте відгук',
                    value: '',
                    blank: false,
                    to: '/',
                }
            ]
        },
        {
            title: "Legal",
            links: [
                {
                    title: 'Умови обслуговування',
                    value: '',
                    blank: false,
                    to: '/profile/rules',
                },
                {
                    title: 'Політика конфіденційності',
                    value: '',
                    blank: false,
                    to: '/profile/rules',
                }
            ]
        }
    ]

    return (
        <Page>
            <div className="account-settings">
                <Container>
                    <PageHeader breadcrumbs={breadcrumbs} title={"Налаштування акаунта"}/>
                    <div className="account-settings__body">
                        {
                            blocks.map((block) => {
                                return (
                                    <LinksBlock
                                        key={block.title}
                                        title={block.title} links={block.links}
                                    />
                                )
                            })
                        }
                    </div>
                </Container>
            </div>

            <ModalRoutes/>
        </Page>
    )
}

export default AccountSettings
