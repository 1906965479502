import React, {FC} from "react";
import "./GuestsForm.scss";
import FieldCounter from "../../../../../../components/ui/forms/fields/FieldCounter/FieldCounter";
import HotelKidsAgeList from "../HotelKidsAgeList/HotelKidsAgeList";
import { useTranslation } from "react-i18next";

interface Props {
  adults: number
  kids: Array<any>
  rooms: number
  handleChange: (name: string, value: string | number) => any
  selectChildren: (value: number) => any
  changeKidsAge: (value: any, index: number) => any
}

const GuestsForm: FC<Props> = (props) => {
  const {adults, kids, rooms, handleChange, selectChildren, changeKidsAge} = props;
  const kidsLength = kids.length;
  const [t] = useTranslation();

  return (
    <div className="hotel-people-dropdown">
      <ul className="hotel-people-dropdown__counters">
        <li>
          <div className="kids-list-wrapper">
            <label>{t("Номери")}</label>
            <FieldCounter value={rooms} min={1} max={20} onChange={(value) => {
              handleChange('rooms', value)
            }}/>
          </div>
        </li>

        <li>
          <div className="kids-list-wrapper">
            <label>{t("Дорослі")}</label>
            <FieldCounter value={adults} min={1} max={10} onChange={(value) => {
              handleChange('adults', value)
            }}/>
          </div>
        </li>

        <li>
          <div className="kids-list-wrapper">
            <label>{t("Діти")}</label>
            <FieldCounter value={kidsLength} min={0} max={10} onChange={selectChildren}/>
          </div>

          <HotelKidsAgeList kids={kids} onChange={changeKidsAge}/>
        </li>
      </ul>
    </div>
  )
}

export default GuestsForm