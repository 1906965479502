import React from 'react';
import './ShowMoreButton.scss';
import { useTranslation } from "react-i18next";

type Props = {
    onClick: () => void;
    isShowingMore: boolean;
    className?: string;
    texts?: [string, string];
};

const ShowMoreButton: React.FC<Props> = (props) => {
    const [t] = useTranslation();

    const {
        className = '',
        onClick,
        isShowingMore,
        texts = [t('Показати більше'), t("Показати менше")],
    } = props;

    return (
        <button className={`show-more-button ${className} `} onClick={onClick}>
            {!isShowingMore ? texts[0] : texts[1]}
        </button>
    );
};
export default ShowMoreButton;
