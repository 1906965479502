import React, { useEffect, useRef } from 'react';
import './MobileHotelDetailsFooter.scss';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';
import FeedbackButton from '../HotelDetailPhoneOrder/components/FeedbackButton';
import { isClient } from '../../../../../helpers/common';
import { useTranslation } from "react-i18next";

type Props = {
    onSelectRooms: () => void
    showFeedbackButton?: boolean
    showRoomsButton?: boolean
}

const MobileHotelDetailsFooter: React.FC<Props> = ({onSelectRooms, showFeedbackButton, showRoomsButton}) => {
    //if (!showRoomsButton && !showFeedbackButton) return null;
    const [t] = useTranslation();

    const footerRef = useRef<HTMLDivElement>();

    const handleFooterHeight = () => {
        if (footerRef.current && isClient) {
            const footer = document.getElementById('generalFooter');

            setTimeout(() => {
                if (footer) {
                    footer.style.paddingBottom = footerRef.current.getBoundingClientRect().height + 20 + 'px';
                }
            }, 400);
        }
    }

    useEffect(() => {
        handleFooterHeight();
    }, []);

    useEffect(() => {
        handleFooterHeight();
    }, [footerRef.current])

    return (
        <div className={'mobile-hotel-details-footer'} ref={footerRef}>
            {showRoomsButton && <AppButton onClick={onSelectRooms} theme={'primary'}>{t("Переглянути номери")}</AppButton>}
            {/*{showFeedbackButton && <FeedbackButton/>}*/}
        </div>
    )
};

export default MobileHotelDetailsFooter;
