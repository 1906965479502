import React from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from "react-i18next";

type Props = {};

const HotelDetailHeaderNav: React.FC<Props> = () => {
  const buttonOptions = {
    className: 'hotel-detail-header__navigation-button',
    duration: 300,
    offset: -100,
    smooth: true,
    //containerId: 'layoutContainer'
  };
  const [t] = useTranslation();

  return (
    <nav className={'hotel-detail-header__navigation'}>
      <ul>
        <li>
          <Link to={'photosScrollElement'} {...buttonOptions}>
            <>
              {t("Фотографії")}
            </>
          </Link>
          {/*<button className={'hotel-detail-header__navigation-button'}>Фотографії</button>*/}
        </li>

        <li>
          <Link to={'amenitiesScrollElement'} {...buttonOptions}>
            <>
              {t("Зручності")}
            </>
          </Link>
        </li>

        <li>
          <Link to={'mapScrollElement'} {...buttonOptions}>
            <>
              {t("Розташування")}
            </>
          </Link>
        </li>

        <li>
          <Link to={'reviewsScrollElement'} {...buttonOptions}>
            <>
              {t("Відгуки")}
            </>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default HotelDetailHeaderNav;
