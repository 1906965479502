import React from 'react';
import './HotelRoomItemWhenPhoneOnBook.scss';
import '../HotelRoomItem/HotelRoomItem.scss';

import { RoomItemPrepared } from '../../../../../../../types/rooms';
import RoomItemPersons from '../HotelRoomItem/components/RoomItemPersons/RoomItemPersons';
import { useTranslation } from "react-i18next";

type Props = {
    roomItem: RoomItemPrepared;
    onShowRoomProperties: () => void;
};

const HotelRoomItemWhenPhoneOnBook: React.FC<Props> = ({roomItem, onShowRoomProperties}) => {
    const roomProperties = roomItem.properties;
    const [t] = useTranslation();

    return (
        <>
            <div className='hotel-room-item__top-row'>
                <span className={'hotel-room-item__name hotel-room-item--phone-book__name'}>{roomItem.name}</span>
                {roomItem.square &&
                   <span className={'hotel-room-item__room-size'}>{roomItem.square} m²</span>}
            </div>

            <div className={'hotel-room-item--phone-book__content-sections'}>
                <div>
                    <span className={'hotel-room-item--phone-book__content-sections-title'}>{t("Розміщення у номері")}</span>
                    <div>{roomItem.rooms}, {roomItem.bathrooms}</div>
                    <div>
                        <RoomItemPersons basePlaces={roomItem.basePlaces} extraPlaces={roomItem.extraPlaces}/>
                    </div>
                </div>

                <div>
                    <span className={'hotel-room-item--phone-book__content-sections-title'}>{t("Зручності у номері")}</span>

                    <div>
                        {roomProperties.length ? (
                            <button onClick={onShowRoomProperties}>
                                <span>{roomProperties.slice(0, 10).map(property => property.name).join(', ')}</span>
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HotelRoomItemWhenPhoneOnBook;
