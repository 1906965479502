import { createAsyncThunk } from '@reduxjs/toolkit';
import favoriteApi from '../../../api/favoriteApi';
import { CreateWishGroupParams } from '../../../types/favorites';
import appToastr from '../../../services/appToastr';
import i18n from '../../../i18n';

export const getFavoriteGroupThunk = createAsyncThunk(
   'favorite/getFavoriteGroupThunk',
   async (_: void, { rejectWithValue }) => {
      try {
         const response = await favoriteApi.fetchFavoriteGroupList();

         return response.data;
      } catch (error) {
         throw rejectWithValue(error);
      }
   },
);

export const createNewWishGroupThunk = createAsyncThunk(
   'favorite/createNewWishGroupThunk',
   async (data: CreateWishGroupParams, { rejectWithValue }) => {
      try {
         const res = await favoriteApi.createGroup(data);
         appToastr.success(i18n.t('Група була успішно створена'))
         return res.data;
      } catch (error) {
         appToastr.error(i18n.t('Не вдалось створити групу'))
         throw rejectWithValue(error);
      }
   },
);

interface UpdateParams {
   name: string;
   id: number;
}

export const updateWishGroupThunk = createAsyncThunk(
   'favorite/updateNewWishGroupThunk',
   async (data: UpdateParams, { rejectWithValue }) => {
      try {
         const res = await favoriteApi.updateGroup({ name: data.name }, data.id);
         appToastr.success(i18n.t('Успішно змінили назву групи'))

         return res.data;
      } catch (error) {
         appToastr.error(i18n.t('Не вдалось змінити назву групи'))
         throw rejectWithValue(error);
      }
   },
);

export const deleteWishGroupThunk = createAsyncThunk(
   'favorite/deleteWishGroupThunk',
   async (id: number, { rejectWithValue }) => {
      try {
         await favoriteApi.deleteGroup(id);
         appToastr.success(i18n.t('Група була успішно видалена'))
         return id;
      } catch (error) {
         appToastr.error(i18n.t('Не вдалось видалити групу'))
         throw rejectWithValue(error);
      }
   },
);
