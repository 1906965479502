import React from 'react';
import './HotelListItemTopInfo.scss';
import StarsRating from '../../../../../../components/ui/components/StarsRating/StarsRating';
import { useTranslation } from "react-i18next";

type Props = {
    reviewsCount?: number;
    stars: number;
    distance?: string;
    placeName?: string
};

const HotelListItemTopInfo: React.FC<Props> = ({reviewsCount, stars, distance, placeName}) => {
   const [t] = useTranslation();

    return (
      <div
        className={`hotel-list-item-top-info ${(!!stars || !!reviewsCount) && distance ? 'hotel-list-item-top-info--full' : ''}`}
      >
          {stars > 0 && (
            <div className="hotel-list-item-top-info__stars">
                <StarsRating stars={stars}/>
            </div>
          )}

          {reviewsCount > 0 && (
            <div className="hotel-list-item-top-info__reviews">
                <span>{t("Відгуки")} • </span> {reviewsCount}
            </div>
          )}

          <div className="hotel-list-item-top-info__distance">
              {distance && <span>{distance}</span>}

              {placeName && <span>{placeName}</span>}
          </div>
      </div>
    );
}
export default HotelListItemTopInfo;
