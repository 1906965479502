import React, {FC} from "react";
import "./AccountEditModal.scss";
import ModalLayoutPrimary
  from "../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary";
import ModalButtons from "../../../../components/ui/modals/Modal/components/ModalButtons/ModalButtons";
import Modal from "../../../../components/ui/modals/Modal/Modal";
import {Form, Formik} from "formik";
import {uploadPhotoSchema} from "../../../../helpers/validation";
import AppFormUpload from "../../../../components/ui/forms/controls/AppFormUpload";
import {useDispatch, useSelector} from "react-redux";
import {editProfileThunk} from "../../../../store/profile/profile-thunks";
import {profileSelector} from "../../../../store/profile/profile-selector";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

interface Props {

}

const AccountEditModal: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const profile = useSelector(profileSelector);
  const {photo} = profile.data.data;
  const backPath = "/profile";
  const [t] = useTranslation();

  return (
    <Modal backPath={backPath} className="avatar-edit-modal">
      <Formik
        onSubmit={(data) => {
          //@ts-ignore
          dispatch(editProfileThunk(data)).then(()=>{
            history.push(backPath)
          })
        }}
        validationSchema={uploadPhotoSchema}
        initialValues={{
          // photo
          photo,
        }}
        enableReinitialize
      >
        <Form>
          <ModalLayoutPrimary title={t("Фотографія профілю")} Footer={() => {
            return (
              <ModalButtons backPath={'/profile'} isSending={profile.isSending}/>
            );
          }}>
            <AppFormUpload name="photo"/>
          </ModalLayoutPrimary>
        </Form>
      </Formik>
    </Modal>
  )
}

export default AccountEditModal