import React, { FC } from 'react';
import './NextTrips.scss';

import NextTripItem from './NextTripItem/NextTripItem';
import { formatDate } from '../../../../../helpers/date';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../../../routes';
import { OrdersList } from "../../../../../types/orders";
import { useTranslation } from "react-i18next";

interface Props {
    reservations: OrdersList;
}

const NextTrips: FC<Props> = ({reservations}) => {
  const [t] = useTranslation();

    return (
        <div className="next-trips">
            <div className="next-trips__title">{t("Скасовані подорожі")}</div>
            <div className="next-trips__list">
                {reservations.map(el => {
                    const durationDates = `${formatDate(new Date(el.arrivalDate), 'dd MMMM yyyy')} р-${formatDate(
                        new Date(el.departureDate),
                        'dd MMMM yyyy',
                    )} р`;
                    const route = RouteNames.PROFILE_ORDERS_DETAIL.replace(':id', String(el.id));

                    return <Link to={route} key={el.id}><NextTripItem date={durationDates} place={el.hotel}
                                                                      image={el.photo?.source['302x170']}/></Link>;
                })}
            </div>
        </div>
    );
};

export default NextTrips;
