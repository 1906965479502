import React, { useCallback, useEffect, useState } from 'react';
import './SignUp.scss';
import { Link, Redirect } from 'react-router-dom';
import FormPhone from '../forms/FormPhone/FormPhone';
import {
  actionSignUpComplete,
  signUpConfirmCodeThunkCreator,
  signUpInitialThunkCreator,
  signUpResendCodeThunkCreator,
} from '../../../store/auth/auth-actions';
import { useDispatch, useSelector } from 'react-redux';
import FormCode from '../forms/FormCode/FormCode';
import FormComplete from './FormComplete/FormComplete';
import { authSelector, authStageSelector, isAuthorizedSelector } from '../../../store/auth/auth-selector';
import { REGISTRATION_COMPLETE, REGISTRATION_CONFIRM, REGISTRATION_INITIAL } from '../../../store/auth/auth-types';
import { SignUpCompleteRequest } from '../../../types/auth';
import AuthLayout from '../common/AuthLayout/AuthLayout';
import useInitial from '../../../hooks/useInitial';
import { unwrapResult } from '@reduxjs/toolkit';
import FormSocialGroup from './FormSocialGroup/FormSocialGroup';
import { registrationStage } from '../../../store/auth/auth-reducer';
import { FormikHelpers } from 'formik/dist/types';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";


interface PhoneData {
  login: string;
}

interface CodeData {
  code: string;
}

const SignUp = () => {
  useInitial();
  const [t] = useTranslation();

  const dispatch = useDispatch<any>();
  const isAuthorized = useSelector(isAuthorizedSelector);
  const { isSending } = useSelector(authSelector);
  const stage = useSelector(authStageSelector);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [login, setLogin] = useState<string>('');

  useEffect(() => {
    dispatch(registrationStage(REGISTRATION_INITIAL));
  }, []);

  const confirmPhone = (data: CodeData, onSubmitProps: FormikHelpers<CodeData>) => {
    //status_code: 406
    const { setErrors } = onSubmitProps;

    dispatch(signUpConfirmCodeThunkCreator({ login, ...data }))
      .then(unwrapResult)
      .catch(error => {
        console.log('e', error);
        if (error.status === 406) {
          setErrors({
            code: t("Неправильний код підтвердження"),
          });
        } else {
          setErrors({
            code: t("Не валідне поле"),
          });
        }
      });
  };

  const completeRegistration = (data: SignUpCompleteRequest, onSubmitProps: FormikHelpers<SignUpCompleteRequest>) => {
    const { setErrors } = onSubmitProps;
    dispatch(actionSignUpComplete(data))
      .then(unwrapResult)
      .catch(error => {
        if (error?.data?.errors) {
          setErrors(error.data.errors);
        }
      });
  };

  const resendCode = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');
    if (token) {
      dispatch(signUpResendCodeThunkCreator({ 'g-recaptcha-response': token, login }));
    }
  }, [executeRecaptcha, login]);


  // Відправлення телефона
  // Create an event handler so you can call the verification on button click event or form submit
  const phoneSubmit = useCallback(async (data: PhoneData, formikHelpers: FormikHelpers<PhoneData>) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');

    if (token) {
      const { setErrors } = formikHelpers;

      dispatch(signUpInitialThunkCreator({ ...data, 'g-recaptcha-response': token }))
        .then(unwrapResult)
        .catch(error => {
          // TODO Винести цей код в функцію
          if (error.status === 409) {
            setErrors({
              login: t("Користувач з таким номером телефону вже зареєстрований"),
            });
          } else if(error.status === 408){
            setErrors({
              login: error.message,
            });
          } else {
            if (error?.data?.errors) {
              setErrors(error.data.errors);
            } else {
              setErrors({
                login: t("Телефон не є валідним"),
              });
            }
          }
        });

      setLogin(data.login);
    }

  }, [executeRecaptcha]);


  let activeStep;

  switch (stage) {
    case REGISTRATION_INITIAL:
      activeStep = 1;
      break;
    case REGISTRATION_CONFIRM:
      activeStep = 2;
      break;
    case REGISTRATION_COMPLETE:
      activeStep = 3;
      break;
    default:
      activeStep = 1;
  }

  return (
    <AuthLayout
      image="/images/backgrounds/2.jpg"
      title={t("Реєстрація")}
      AuthHeader={() => <>{t("Вже є аккаунт?")} <Link to="/auth/signin"><>{t("Увійдіть")}</></Link></>}
      activeStep={activeStep}
      className="sign-up"
    >
      {isAuthorized && <Redirect to="/profile"/>}

      {stage === REGISTRATION_INITIAL && (
        <>
          <FormPhone onSubmit={phoneSubmit} isSending={isSending}/>
          <div>
            {/*<FormSocialGroup/>*/}

          </div>
        </>
      )}
      {stage === REGISTRATION_CONFIRM && (
        <FormCode onSubmit={confirmPhone} onResend={resendCode} isSending={isSending}/>
      )}
      {stage === REGISTRATION_COMPLETE && <FormComplete onSubmit={completeRegistration} isSending={isSending}/>}
    </AuthLayout>
  );
};

export default SignUp;
