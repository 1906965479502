import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './HotelAmenities.scss';

import { HotelPropertyGroup, PopularProperty } from '../../../../../types/hotel';
import HotelIconText from '../../../../../components/ui/icons/hotels/HotelIconText/HotelIconText';
import useSwitcher from '../../../../../hooks/useSwitcher';
import HotelAmenitiesModal from './HotelAmenitiesModal/HotelAmenitiesModal';
import ShowMoreButton from '../ShowMoreButton/ShowMoreButton';
import HotelDetailContentBlock from '../HotelDetailContentBlock/HotelDetailContentBlock';
import HotelAmenitiesCheckIcon from './components/HotelAmenitiesCheckIcon';

interface Props {
    popularProperties: {
        data: PopularProperty[]
    },
    amenities: {
        data: HotelPropertyGroup[];
    };
    hotelName: string;
}

const HotelAmenities: FC<Props> = ({popularProperties, amenities, hotelName}) => {
    const [t] = useTranslation();

    const {onClose, onOpen, isOpen} = useSwitcher(false);
    //const {isMobile} = useWindowSize();

    //const countForShow = isMobile ? 6 : -1;
    const showMoreButton = true;//amenitiesArrayLength > countForShow;

    return (
        <div className="hotel-amenities">
            <HotelDetailContentBlock title={t('Зручності в готелі', {name: hotelName})}>
                <div className="hotel-amenities__list-title">
                    <i>
                        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.2827 2.45307C10.5131 1.98613 10.6284 1.75266 10.7848 1.67807C10.9209 1.61317 11.0791 1.61317 11.2152 1.67807C11.3717 1.75266 11.4869 1.98613 11.7174 2.45307L13.9041 6.88304C13.9721 7.02089 14.0061 7.08982 14.0558 7.14333C14.0999 7.19071 14.1527 7.22911 14.2113 7.25638C14.2776 7.28718 14.3536 7.2983 14.5057 7.32053L19.397 8.03546C19.9121 8.11075 20.1696 8.14839 20.2888 8.27419C20.3925 8.38365 20.4412 8.53405 20.4215 8.68353C20.3988 8.85533 20.2124 9.03694 19.8395 9.40014L16.3014 12.8462C16.1912 12.9536 16.136 13.0073 16.1004 13.0712C16.0689 13.1278 16.0487 13.19 16.0409 13.2543C16.0321 13.3269 16.0451 13.4027 16.0711 13.5545L16.906 18.4219C16.994 18.9352 17.038 19.1919 16.9553 19.3443C16.8833 19.4768 16.7554 19.5697 16.6071 19.5972C16.4366 19.6288 16.2061 19.5076 15.7451 19.2652L11.3724 16.9656C11.2361 16.8939 11.168 16.8581 11.0962 16.844C11.0327 16.8316 10.9673 16.8316 10.9038 16.844C10.832 16.8581 10.7639 16.8939 10.6277 16.9656L6.25492 19.2652C5.79392 19.5076 5.56341 19.6288 5.39297 19.5972C5.24468 19.5697 5.11672 19.4768 5.04474 19.3443C4.962 19.1919 5.00603 18.9352 5.09407 18.4219L5.92889 13.5545C5.95491 13.4027 5.96793 13.3269 5.95912 13.2543C5.95132 13.19 5.93111 13.1278 5.89961 13.0712C5.86402 13.0073 5.80888 12.9536 5.69859 12.8462L2.16056 9.40014C1.78766 9.03694 1.60121 8.85533 1.57853 8.68353C1.55879 8.53405 1.60755 8.38365 1.71125 8.27419C1.83044 8.14839 2.08797 8.11075 2.60304 8.03546L7.49431 7.32053C7.64642 7.2983 7.72248 7.28718 7.78872 7.25638C7.84736 7.22911 7.90016 7.19071 7.94419 7.14333C7.99391 7.08982 8.02793 7.02089 8.09597 6.88304L10.2827 2.45307Z"
                                stroke="#6D7188" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </i>
                    <span>{t('Найпопулярніші зручності')}</span>
                </div>

                <div
                    className="hotel-amenities__list"
                    //style={{paddingBottom: amenitiesArray.length <= countForShow ? 10 : 0}}
                >
                    {popularProperties?.data?.map((el, index) => (
                        <div
                            className="hotel-amenities__list-item"
                            key={'hotel-amenities-item' + index}
                        >
                            <HotelIconText text={el.name} icon={<HotelAmenitiesCheckIcon/>}/>
                        </div>
                    ))}
                </div>

                {showMoreButton && (
                    <ShowMoreButton
                        onClick={onOpen}
                        isShowingMore={false}
                        //texts={[`Переглянути всі ${pluralize('зручність', amenitiesArrayLength, true)}`, '']}
                        texts={[t('Переглянути всі зручності'), '']}
                    />
                )}

                {isOpen && <HotelAmenitiesModal amenities={amenities?.data} onClose={onClose} hotelName={hotelName}/>}
            </HotelDetailContentBlock>
        </div>
    );
};

export default HotelAmenities;
