import React, { FC } from 'react';
import './ProfileFormLayout.scss';
import { useTranslation } from "react-i18next";

interface Props {
   title: string;
   onChangeView: (isOpen: boolean) => void;
   viewForm: boolean;
}

const ProfileFormLayout: FC<Props> = props => {
   const { children, title, onChangeView, viewForm } = props;
   const [t] = useTranslation()

   const showEditBlock = e => {
      e.preventDefault();

      onChangeView(true);
   };

   const hideEditBlock = e => {
      e.preventDefault();

      onChangeView(false);
   };

   return (
      <div className="profile-form-layout">
         <div className="profile-form-layout__header">
            <h2>{title}</h2>
            {!viewForm ? <button onClick={showEditBlock}>{t("Редагувати")}</button> : <button onClick={hideEditBlock}>{t("Скасувати")}</button>}
         </div>

         {children}
      </div>
   );
};

export default ProfileFormLayout;
