import React from 'react';
import './NoRoomsMessage.scss';
import classNames from 'classnames';
import AppButton from '../../../../../../../../../components/ui/components/Buttons/AppButton/AppButton';
import useWindowSize from '../../../../../../../../../hooks/useWindowSize';
import { useTranslation } from "react-i18next";

type Props = {
    text?: string,
    variant?: 'with-button',
    onButtonClick?: () => void
}

const NoRoomsMessage: React.FC<Props> = ({text, variant, onButtonClick}) => {
    const classes = classNames('no-rooms-message', {[variant]: variant});
    const {isMobile} = useWindowSize();
    const [t] = useTranslation();

    return (
        <div className={classes}>
            <div>
                <span
                    className={'no-rooms-message__text'}>{text || t("На вказані дати немає вільних місць")}</span>
            </div>

            {variant === 'with-button' && (
                <div>
                    <AppButton
                        onClick={onButtonClick} theme={'outline'} size={!isMobile ? 'small' : null}
                        color={'red'}
                    >
                        {t("Змінити період")}
                    </AppButton>
                </div>
            )}
        </div>
    )
};

export default NoRoomsMessage;
