import React, { useEffect, useState } from "react";
import "./PhoneFixedButton.scss";

const PhoneFixedButton = ({onClick, showMessage}) => {
  const [bottomPosition, setBottomPosition] = useState(30);

  // Якщо існує меседж тоді позиціоную іконку вище меседжу і додаю 30 пікселів в іншому випадку тільки 30 пікселів
  useEffect(()=>{
    const $node = document.querySelector('#hotel-detail-message');

    if($node && $node.getBoundingClientRect){
      setBottomPosition($node.getBoundingClientRect().height + 30)
    } else {
      setBottomPosition(30)
    }
  },[showMessage])

  return (
    <div className="phone-fixed-button" onClick={onClick} style={{bottom: bottomPosition}}>
      <svg width="76" height="76" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43.9999 82.4419C65.2307 82.4419 82.4417 65.2309 82.4417 44C82.4417 22.7692 65.2307 5.55815 43.9999 5.55815C22.769 5.55815 5.55803 22.7692 5.55803 44C5.55803 65.2309 22.769 82.4419 43.9999 82.4419Z" fill="#5ECBAA" stroke="#D8F3EB" strokeWidth="10.6047"/>
        <path d="M71.5368 50.4865C68.5322 63.2121 57.194 72.226 44.1238 72.2791L30.0991 58.2544L35.8168 56.4251C42.6921 60.9232 51.9182 58.9967 56.4163 52.1125C60.2252 46.2888 59.4917 38.6093 54.6489 33.6074L71.5368 50.4865Z" fill="url(#paint0_linear_9859_47421)"/>
        <path d="M56.4339 52.1924C51.9093 59.0501 42.6744 60.9501 35.8167 56.4254L31.4865 57.8129L30.0991 58.2547H30.0902L31.946 52.7226C30.1167 50.1864 29.127 47.1375 29.127 44.0003C29.1181 35.7817 35.7814 29.1185 44 29.1096C46.9163 29.1096 49.7707 29.9668 52.2098 31.5752C53.1465 32.1938 53.9772 32.9008 54.7107 33.6696L54.9139 33.8729C59.4828 38.7864 60.287 46.351 56.4339 52.1924Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7156 40.1768C43.8061 39.71 44.2576 39.405 44.7242 39.4955C45.7396 39.6924 46.6572 40.1895 47.3846 40.9071L47.389 40.9114L47.3889 40.9114C48.1125 41.6353 48.6079 42.565 48.8041 43.5775C48.8946 44.0443 48.5897 44.4961 48.1232 44.5866C47.6566 44.6771 47.2051 44.3721 47.1146 43.9053C46.984 43.2313 46.6541 42.6122 46.1742 42.1311C45.6801 41.6445 45.0667 41.3159 44.3966 41.1859C43.93 41.0954 43.6251 40.6436 43.7156 40.1768Z" fill="#574BA2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7072 36.8106C43.7607 36.3381 44.1869 35.9985 44.6591 36.0521C46.5969 36.2717 48.4044 37.132 49.7916 38.52C51.1668 39.8959 52.0479 41.7041 52.2588 43.6507C52.31 44.1234 51.9685 44.5481 51.496 44.5994C51.0235 44.6506 50.599 44.3089 50.5478 43.8362C50.38 42.2874 49.6778 40.8412 48.5747 39.7376C47.4665 38.6288 46.0213 37.9394 44.4654 37.763C43.9931 37.7095 43.6537 37.2831 43.7072 36.8106Z" fill="#574BA2"/>
        <path d="M50.3718 51.9886L48.7899 53.5793C47.1197 55.2406 42.7011 53.4113 38.7862 49.4876C34.8625 45.5551 33.1127 41.1453 34.6946 39.5016L36.2764 37.9197C36.9127 37.3276 37.8937 37.3276 38.5299 37.9197L40.863 40.2439C41.4816 40.8448 41.4992 41.8258 40.8983 42.4355C40.7304 42.6123 40.5183 42.7448 40.2797 42.8244C39.5109 43.0718 39.069 43.8848 39.2811 44.6625C39.6964 46.4123 41.9499 48.5862 43.6202 49.0016C44.3978 49.1606 45.1667 48.7276 45.4406 47.9853C45.7057 47.1723 46.5895 46.7304 47.4025 46.9955C47.6499 47.0751 47.8709 47.2165 48.0476 47.402L50.3806 49.7439C50.9639 50.3802 50.9639 51.3523 50.3718 51.9886Z" fill="#5ECBAA"/>
        <defs>
          <linearGradient id="paint0_linear_9859_47421" x1="42.3033" y1="45.8558" x2="62.1782" y2="65.7307" gradientUnits="userSpaceOnUse">
            <stop stopColor="#51AF93"/>
            <stop offset="1" stopColor="#3B8770" stopOpacity="0"/>
          </linearGradient>
        </defs>
      </svg>

    </div>
  )
}
export default PhoneFixedButton