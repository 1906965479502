import React, { useEffect, useRef, useState } from 'react';
import './MobileRoomsFlow.scss';
import { useHistory, useLocation, } from 'react-router-dom';

import MobileHotelDetailsFooter from '../components/MobileHotelDetailsFooter/MobileHotelDetailsFooter';
import Modal from '../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary
    from '../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import MobileRoomsList from './components/MobileRoomsList/MobileRoomsList';
import { Hotel } from '../../../../types/hotel';
import { RoomItemPrepared } from '../../../../types/rooms';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { getRoomsWithMealSelector } from '../../../../store/rooms/rooms-selectors';
import MobileRoomDetail from './components/MobileRoomDetail/MobileRoomDetail';
import { setShowMobileRoomsFlow } from '../../../../store/rooms/rooms-reducer';
import useRoomsLogic from '../useRoomsLogic';
import HotelDetailPhoneOrder from '../components/HotelDetailPhoneOrder/HotelDetailPhoneOrder';
import { scrollToTop } from '../../../../helpers/common';
import HotelBookSection from '../components/HotelBookSection/HotelBookSection';
import { useTranslation } from "react-i18next";

type Props = {
    hotel: Hotel,
    showFooter?: boolean
    onClose: () => void
}

const MobileRoomsFlow: React.FC<Props> = (props) => {
    const {hotel, showFooter} = props;
    const rooms = useAppSelector(getRoomsWithMealSelector);
    const dispatch = useAppDispatch();
    const [t] = useTranslation();

    const history = useHistory();
    const {pathname, search, hash} = useLocation();
    const {focusOnEditForm} = useRoomsLogic();

    const [selectedRoom, setSelectedRoom] = useState<RoomItemPrepared>();
    const [listScrollPosition, setListScrollPosition] = useState(0);
    const modalRef = useRef<any>();

    useEffect(() => {
        const el = document.getElementsByClassName('modal-layout-primary__body');

        if (selectedRoom) {
            scrollToTop(el?.[0]);
        } else {
            if (typeof modalRef.current.setScrollPosition === 'function') {
                modalRef.current.setScrollPosition(listScrollPosition);
            }
        }
    }, [selectedRoom]);

    const onClose = () => {
        if (selectedRoom) {
            //history.replace(pathname + search + '#show-rooms');
            setSelectedRoom(null);
        } else props.onClose();
    }

    const onSelectRoom = (room: RoomItemPrepared) => {
        setSelectedRoom(room);
        history.push(pathname + search + '#show-rooms/room-detail');

        if (typeof modalRef.current.getCurrentScrollPosition === 'function') {
            setListScrollPosition(modalRef.current.getCurrentScrollPosition());
        }
    }

    const modalContent = (
        <Modal onClose={onClose} className={'mobile-rooms-list-modal' + (selectedRoom ? '' : '-list')}>
            <ModalLayoutPrimary
                title={selectedRoom ? selectedRoom.name : t('Виберіть номер')}
                subTitle={t("hotel_with_name", {name: hotel.name})}
                // noPaddingTop
                headerScrollContent={!selectedRoom && <HotelBookSection isMobile/>}
                Footer={showFooter ? () => <HotelDetailPhoneOrder isMobile={true}/> : undefined}
                footerVariant={3}
                noFooterPadding
                onScrollUpdate={!selectedRoom ? () => {} : null}
                ref={modalRef}
            >
                {selectedRoom ?
                    (
                        <MobileRoomDetail
                            hotelName={hotel.name}
                            room={selectedRoom}
                            onGoBack={onClose}
                            onFocusOnSearchForm={() => {
                                setSelectedRoom(null);
                                props.onClose();
                                focusOnEditForm(true, hotel.alias);
                            }}
                        />
                    ) :
                    (
                        <MobileRoomsList
                            rooms={rooms}
                            onSelectRoom={onSelectRoom}
                            onFocusOnSearchForm={() => {
                                focusOnEditForm(true, hotel.alias);
                            }}
                        />
                    )
                }
            </ModalLayoutPrimary>
        </Modal>
    );

    return (
        <div>
            {modalContent}

            <MobileHotelDetailsFooter
                onSelectRooms={() => dispatch(setShowMobileRoomsFlow(true))}
            />
        </div>
    )
};

export default MobileRoomsFlow;
