import React, { FC } from 'react';
import './NoTrips.scss';
import IconCar from '../../../../../../components/ui/icons/account/IconCar';
import AppButton from '../../../../../../components/ui/components/Buttons/AppButton/AppButton';
import { useTranslation } from "react-i18next";

interface Props {}

const NoTrips: FC<Props> = props => {
  const [t] = useTranslation();


   return (
      <div className="no-trips">
         <div className="no-trips__block-information">
            <div className="no-trips__title">{t("У вас немає заброньованих подорожей... поки що")}</div>
            <div className="no-trips__info">{t("Час пакувати валізи й планувати нову пригоду")}</div>
            <AppButton to="/" theme={'fillable'} size={'middle'} className="no-trips__button">
              {t("Почніть пошук")}
            </AppButton>
         </div>
         <IconCar />
         <AppButton to="/" theme={'fillable'} className="no-trips__button-mobile" size={'middle'}>
           {t("Почніть пошук")}
         </AppButton>
      </div>
   );
};

export default NoTrips;
