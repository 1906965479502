import React, { FC, useState } from 'react';
import './ProfileContacts.scss';
import AppFormInput from '../../../../../../components/ui/forms/controls/AppFormInput';
import { Form, Formik, FormikHelpers } from 'formik';
import FormButtons from '../../FormButtons/FormButtons';
import AppFormCode from '../../../../../../components/ui/forms/controls/AppFormCode';
import AppButton from '../../../../../../components/ui/components/Buttons/AppButton/AppButton';
import { useTranslation } from "react-i18next";
import { phoneMask } from "../../../../../../config";

interface InitialValue {
   phone: string,
   code: string,
   email: string,
   password: string,
   showPassword: boolean,
   showCode: boolean,
}

interface Props {
   isSending: boolean;
   cancelEdit: (d) => void;
   submitData: (data: any, propsMethods: FormikHelpers<InitialValue>) => void;
   initialValues: InitialValue;
   validationSchema: any;
   // resendCode: () => void;
   setPhone: (data: { phone: string; code: string }) => Promise<any>;
   submitPhoneWithPassword: (data: { phone: string; password: string }) => Promise<any>;
}

const ProfileContacts: FC<Props> = props => {
   const {
      isSending,
      cancelEdit,
      submitData,
      validationSchema,
      initialValues,
      // resendCode,
      setPhone,
      submitPhoneWithPassword,
   } = props;
   const [step, setStep] = useState<'contactForm' | 'codeForm'>('contactForm');
   const [t] = useTranslation();

   return (
      <div className="profile-contacts-form">
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitData}
            enableReinitialize
            // validateOnMount={true}
         >
            {({ getFieldProps, setErrors, values, errors, setFieldTouched, setFieldValue, ...rest }) => {
               const phoneHasBeenChanged = initialValues.phone !== values.phone;

               const handleChange = () => {
                  if (phoneHasBeenChanged) {
                     setFieldValue('showPassword', true);
                  } else {
                     setFieldValue('showPassword', false);
                  }
               };

               const submitPhoneAndPassword = (type: string) => {
                  setFieldTouched('password');


                  if (!errors.phone && !errors.password) {
                     submitPhoneWithPassword({
                        phone: values.phone,
                        password: values.password,
                     }, type)
                        .then(() => {
                           setFieldValue('showCode', true);
                           setStep('codeForm');
                        })
                        .catch(error => {
                           if (error?.data?.errors) {
                              setErrors(error.data.errors);
                           }

                           if (error.status === 408) {
                              setErrors({
                                 password: error.data?.message,
                              });
                           }
                        });
                  }
               };

               const submitCodeAndPhone = () => {
                  if (!errors.code && !errors.phone) {
                     setPhone({
                        code: values.code,
                        phone: values.phone,
                     })
                        .then(() => {
                           setFieldValue('showCode', false);
                           setStep('contactForm');
                        })
                        .catch(error => {
                           if (error.status === 406) {
                              setErrors({
                                 code: t("Неправильний код підтвердження"),
                              });
                           }
                        });
                  }
               };

               return (
                  <Form>
                     {step === 'contactForm' && (
                        <>
                           <AppFormInput
                              role="phone"
                              name="phone"
                              label={t("Телефон")}
                              unmask
                              mask={phoneMask}
                              onChange={handleChange}
                           />

                           {phoneHasBeenChanged && (
                              <div className="profile-contacts-form__phone-button">
                                 <p className="profile-contacts-form__info">
                                    {t("Ми надішлемо вам код для підтвердження номера телефону")}
                                 </p>
                                 <br />
                                 <AppFormInput name="password" label={t("Пароль")} type="password" />
                                 <AppButton
                                    size="small"
                                    type="button"
                                    showIndicator={isSending}
                                    onClick={submitPhoneAndPassword}
                                 >
                                    {t("Надіслати SMS-код")}
                                 </AppButton>
                              </div>
                           )}
                        </>
                     )}

                     {step === 'codeForm' && (
                        <div>
                           <AppFormInput type="hidden" name="showCode" />
                           <AppFormInput role="phone" name="phone" label={t("Телефон")} mask={phoneMask} disabled />
                           <div style={{ maxWidth: 380 }}>
                              <AppFormCode
                                 name="code"
                                 label={t("Введіть код, який ми надіслали через SMS")}
                                 {...getFieldProps('code')}
                              />
                           </div>
                           {phoneHasBeenChanged && (
                              <div className="profile-contacts-form__phone-button">
                                 <p className="profile-contacts-form__resend">
                                    {t("Не отримали SMS-повідомлення")} <span onClick={()=> submitPhoneAndPassword('resend')}>{t("Надіслати повторно")}</span>
                                 </p>
                                 <AppButton
                                    size="small"
                                    type="button"
                                    showIndicator={isSending}
                                    onClick={submitCodeAndPhone}
                                 >
                                    {t("Підтвердити")}
                                 </AppButton>
                              </div>
                           )}
                        </div>
                     )}

                     <AppFormInput
                        name="email"
                        label="E-mail"
                        {...getFieldProps('email')}
                        disabled={phoneHasBeenChanged}
                     />
                     {!phoneHasBeenChanged && (
                        <FormButtons onCancel={() => cancelEdit(false)} isSending={isSending} onSave={() => {}} />
                     )}
                  </Form>
               );
            }}
         </Formik>
      </div>
   );
};

export default ProfileContacts;
