import React, { FC, forwardRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import de from 'date-fns/locale/de';
import pl from 'date-fns/locale/pl';
import ro from 'date-fns/locale/ro';
import en from 'date-fns/locale/en-GB';
import { useSelector } from 'react-redux';
import './FieldDatepicker.scss';
import { AppState } from '../../../../../store';
import MuiFieldInput from '../../muiFields/MuiFieldInput';

registerLocale('uk', uk);
registerLocale('de', de);
registerLocale('en', en);
registerLocale('ro', ro);
registerLocale('pl', pl);

interface IProps extends ReactDatePickerProps {
    label?: string;
    isError?: boolean;
    onChange: (date: Date | [Date, Date]) => void;
}

const FieldDatepicker: FC<IProps> = forwardRef((props, ref) => {
    const {isError, label} = props;
    const {language} = useSelector((state: AppState) => state.initial);

    // @ts-ignore
    const CustomInput = forwardRef(({value, onClick}, ref) => {
        return (
            // @ts-ignore
            <MuiFieldInput label={label} isError={isError} onClick={onClick} ref={ref}/>
        );
    });

    const onChange = values => {
        props.onChange(values);
    };

    return (
        <div className="field-datepicker">
            <DatePicker
                customInput={<CustomInput/>}
                onChange={onChange}
                locale={language}
                //@ts-ignore
                ref={ref}
                {...props}
            />
        </div>
    );
});

export default FieldDatepicker;
