import React, { FC } from 'react';
import './HotelPeople.scss';
import OutsideClick from '../../../../../../components/common/OutsideClick/OutsideClick';
import useSwitcher from '../../../../../../hooks/useSwitcher';
import FieldInput from '../../../../../../components/ui/forms/fields/FieldInput/FieldInput';
import classNames from 'classnames';
import GuestsForm from '../GuestsForm/GuestsForm';
import { useTranslation } from 'react-i18next';

interface QueryParams {
   kids?: Array<string | number>;
   adults?: number;
   rooms?: number;
}

interface Props {
   onChange?: (data: QueryParams) => void;
   arrow?: any,
   guestsParams: any
   guestsMethods: {
      selectChildren: (value: number) => void
      changeKidsAge: (value: string, index: number) => void
      changeGuestForm: (name: string, value: number) => void
   }
}

const HotelPeople: FC<Props> = (props) => {
   const { arrow, guestsParams, guestsMethods } = props;
   const [t] = useTranslation();
   const { isOpen, onClose, onToggle } = useSwitcher();
   const { kids, adults, rooms } = guestsParams;
   const { changeGuestForm, changeKidsAge,selectChildren} = guestsMethods;
   const classesIcon = classNames('hotel-people-input__icon', { 'hotel-people-input__icon--rotate': isOpen });
   const label = kids?.length > 0 ? t('guest_string', {adults:adults, rooms:rooms, kids: kids.length}) : t('guest_string_no_kids', {adults:adults, rooms:rooms});

   return (
      <div style={{ position: 'relative' }}>
         <OutsideClick onOutsideClick={onClose}>
            <div className='hotel-people-input'>
               <FieldInput size='large'
                           onClick={onToggle}
                           readOnly
                           value={label}
               />
               <div className={classesIcon}>
                  {arrow ? arrow : ''}
               </div>
            </div>

            {
               isOpen &&
               <GuestsForm
                  changeKidsAge={changeKidsAge}
                  handleChange={changeGuestForm}
                  selectChildren={selectChildren}
                  adults={adults}
                  kids={kids}
                  rooms={rooms}
               />
            }
         </OutsideClick>
      </div>
   );
};

export default HotelPeople;
