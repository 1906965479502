import React, { FC } from 'react';
import './AccountRules.scss';
import PageHeader from '../common/PageHeader/PageHeader';
import Container from '../../../components/ui/layouts/Container/Container';
import Page from '../../../components/common/Page/Page';
import { RouteNames } from '../../../routes';
import { useTranslation } from "react-i18next";

interface Props {}

const AccountRules: FC<Props> = props => {
   const [t] = useTranslation();
   const breadcrumbs = [
      {
         url: RouteNames.PROFILE_INDEX,
         title: t("Акаунт"),
      },
      {
         title: t("Умови обслуговування"),
      },
   ];
   return (
      <Page>
         <div className="account-rules">
            <Container>
               <PageHeader breadcrumbs={breadcrumbs} title="Умови обслуговування" />
               <div className="account-rules__body">
                  <div className="account-rules__date">Оновлено 14 лютого 2022</div>
                  <h2>Зміст</h2>
                  <ul>
                     <li><a>A. Усі Туристичні продукти</a></li>
                     <li><a>Б. Готельні об'єкти</a></li>
                     <li><a>В. Варіанти дозвілля</a></li>
                     <li><a>Г. Оренда авто</a></li>
                     <li><a>Е. Приватний і Громадський транспорт</a></li>
                  </ul>
                  <h2>A. Усі Туристичні продукти</h2>

                  <p>A20. Організовані туристичні послуги</p>
                  <p>A1. Визначення</p>

                  <p>
                     1. Деякі слова, які ви побачите, мають дуже конкретне значення, тому перегляньте словник
                     Booking.com у кінці цих Умов.
                  </p>

                  <p>A2. Про ці умови</p>

                  <p>
                     1. Під час виконання Бронювання ви приймаєте ці Умови та будь-які інші умови, надані вам у процесі
                     бронювання.
                  </p>

                  <p> 2. Якщо будь-яке положення в цих Умовах є (або стає) недійсним або незастосовним:</p>

                  <p>воно, як і раніше, буде застосовуватися максимальною мірою, дозволеною законом;</p>
                  <p>на вас і далі будуть поширюватися всі інші положення Умов. 3. Ці Умови викладені таким чином:</p>

                  <p>Розділ A: загальні умови для всіх типів Туристичних продуктів.</p>

                  <p>
                     Розділи Б–Е: конкретні умови тільки для одного типу Туристичного продукту: Розділ Б: Готельні
                     об'єкти; Розділ В: Варіанти дозвілля; Розділ Г: Оренда авто; Розділ Д: Авіаквитки; Розділ Е:
                     Приватний і Громадський транспорт. Якщо є якась невідповідність між загальними та конкретними
                     умовами, будуть застосовуватися конкретні умови. 4. Англомовна версія цих Умов є оригіналом. У разі
                     виникнення розбіжностей щодо Умов або будь-якої невідповідності між Умовами англійською та іншою
                     мовами застосовуватимуться Умови у вигляді, в якому вони представлені англійською мовою. (Ви можете
                     змінити мову вгорі цієї сторінки.)
                  </p>
               </div>
            </Container>
         </div>
      </Page>
   );
};

export default AccountRules;
