import React from 'react';
import './TopInfoRow.scss';
import { formatDateFromString } from '../../../../../../../helpers/date';
import { useTranslation } from "react-i18next";

type Props = {
    endDate?: string
}

const TopInfoRow: React.FC<Props> = ({endDate}) => {
  const [t] = useTranslation();

    return (
        <div className={'order-success-payment-data__top-info-row'}>
            <div>
                <svg width="46" height="50" viewBox="0 0 46 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.00461 27.2202C2.00461 15.4643 11.5717 5.89719 23.3276 5.89719C35.0833 5.89719 44.6494 15.4642 44.6494 27.2202C44.6494 38.975 35.0834 48.5421 23.3276 48.5421C11.5717 48.5421 2.00461 38.975 2.00461 27.2202Z"
                        stroke="#2F2C37" strokeWidth="2.70064"/>
                    <path
                        d="M25.1523 27.6966V14.6879C25.1523 13.8139 24.4439 13.1064 23.5709 13.1064C22.698 13.1064 21.9895 13.8139 21.9895 14.6879V27.6966C21.9895 28.5706 22.698 29.278 23.5709 29.278C24.4439 29.278 25.1523 28.5706 25.1523 27.6966Z"
                        fill="#2F2C37"/>
                    <path
                        d="M23.9404 29.2447L36.598 26.2428C37.4484 26.0412 37.9732 25.1886 37.7718 24.3392C37.5704 23.4898 36.7186 22.9637 35.8681 23.1654L23.2105 26.1672C22.3601 26.3689 21.8353 27.2215 22.0367 28.0709C22.2381 28.9202 23.0899 29.4463 23.9404 29.2447Z"
                        fill="#5ECBAA"/>
                    <path
                        d="M29.0096 0.10791H17.7552C16.8812 0.10791 16.1738 0.816385 16.1738 1.68933C16.1738 2.56227 16.8812 3.27074 17.7552 3.27074H29.0096C29.8836 3.27074 30.5911 2.56227 30.5911 1.68933C30.5911 0.816385 29.8836 0.10791 29.0096 0.10791Z"
                        fill="#5ECBAA"/>
                </svg>

            </div>

            <div className={'order-success-payment-data__top-info-row__texts'}>
                <b>{t("Оплатіть бронювання")}</b>
                <span>
                    {t("до")} {formatDateFromString(endDate, 'HH:mm, DD MMMM')}
                </span>
            </div>
        </div>
    )
};

export default TopInfoRow;
