import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { completeSchema } from '../../../../helpers/validation';
import AppFormInput from '../../../../components/ui/forms/controls/AppFormInput';
import AppButton from '../../../../components/ui/components/Buttons/AppButton/AppButton';
import AppFormSelect from '../../../../components/ui/forms/controls/AppFormSelect';
import { FormikHelpers } from 'formik/dist/types';
import { SignUpCompleteRequest } from '../../../../types/auth';
import { genderOptions } from "../../../Account/Profile/helpers/common";
import { useTranslation } from "react-i18next";

interface IProps {
   onSubmit: (data: SignUpCompleteRequest, formikHelpers: FormikHelpers<SignUpCompleteRequest>) => void;
   isSending: boolean;
}

const initialValues: SignUpCompleteRequest = {
   name: '',
   lastname: '',
   email: '',
   birthday: '',
   gender: '1',
   password: '',
   password_confirmation: '',
};

const FormComplete: FC<IProps> = ({ onSubmit, isSending }) => {
   const [t] = useTranslation();

   return (
      <Formik
         initialValues={initialValues}
         validationSchema={completeSchema}
         onSubmit={onSubmit}
         // enableReinitialize
      >
         <Form>
            <div>
               <AppFormInput name="name" label={t("Ім'я")} />
               <AppFormInput name="lastname" label={t("Прізвище")} />
               <AppFormInput name="email" label="E-mail" />
               <AppFormInput mask="99.99.9999" label={t("Дата народження")} name="birthday" />
               <AppFormSelect options={genderOptions} name="gender" label={t("Стать")} />
               <AppFormInput type="password" name="password" label={t("Пароль")} />
               <AppFormInput type="password" name="password_confirmation" label={t("Пароль")} />
            </div>
            <div style={{ alignSelf: 'flex-end' }}>
               <AppButton type="submit" theme="primary" size="middle" className="sign-up__submit sign-up__full" showIndicator={isSending}>
                  {t("Зареєструватися")}
               </AppButton>
            </div>
         </Form>
      </Formik>
   );
};

export default FormComplete;
