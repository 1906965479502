import React from 'react';
import AppText from '../../../../../../components/ui/typography/AppText/AppText';
import './HotelItemNoData.scss';
// @ts-ignore
import image from '../../../../../../assets/images/hotels-no-data.svg';
import { useTranslation } from "react-i18next";

const HotelItemNoData = () => {
  const [t] = useTranslation();

  return (
    <div className="hotels-no-data">
      <img src={image} alt=""/>
      <AppText variant={'H200'} weight={'600'}>{t("За вашим пошуком нічого не знайдено")}</AppText>
      <AppText variant={'B400'}>{t("Будь ласка, змініть дати або уточніть параметри фільтрів")}</AppText>
    </div>
  );
}

export default HotelItemNoData;
