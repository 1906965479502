import React, { FC } from 'react';
import './ModalButtons.scss';
import { Link } from 'react-router-dom';
import AppButton from '../../../../components/Buttons/AppButton/AppButton';
import { useTranslation } from "react-i18next";

interface Props {
   backPath: string;
   submit?: () => void;
   isSending?: boolean;
}

const ModalButtons: FC<Props> = props => {
   const { backPath, submit, isSending = false, ...rest } = props;
  const [t] = useTranslation();

   return (
      <div className="modal-buttons">
         <Link className="modal-buttons__cancel" to={backPath}>
           <>{t("Скасувати")}</>
         </Link>
         <AppButton size="middle" onClick={submit} {...rest} showIndicator={isSending}>
           {t("Зберегти")}
         </AppButton>
      </div>
   );
};

export default ModalButtons;
