import React from 'react';
import './Footer.scss';
import Container from '../../../ui/layouts/Container/Container';
import FooterPlacesList from './components/FooterPlacesList';
import { MobileOnly, TabletAndUpper } from '../../../common/Media/MediaHelpers';
import FooterContactsSection from './components/FooterContactsSection/FooterContactsSection';
import FooterSiteLogo from './components/FooterSiteLogo';
import AppButton from '../../../ui/components/Buttons/AppButton/AppButton';
import { RouteNames } from '../../../../routes';
import { useLocation } from 'react-router-dom';
import classNames from "classnames";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const Footer = () => {
   const location = useLocation();
   const isHomePage = location.pathname === '/';
   const footerClasses = classNames('footer', {'footer-light': !isHomePage})
   const [t] = useTranslation();

    return (
      <footer className={footerClasses} id={'generalFooter'}>
         <Container>
            <div className={'footer__content'}>
               <div className={'footer__content-block footer__content-block--list'}>
                  <span className={'footer__content-block__text'}>{t("Готелі в Україні")}</span>

                  <div className={'footer__content-block__content'}>
                     <TabletAndUpper>
                        <FooterPlacesList />
                        <div style={{ marginTop: '43px' }}>
                           <FooterSiteLogo />
                           <div>{t("Бронюй тут")}</div>
                        </div>
                     </TabletAndUpper>

                     <MobileOnly>
                        <FooterPlacesList isMobile />
                     </MobileOnly>
                  </div>
               </div>

               <div className={'footer__content-block footer__content-block--contacts'}>
                  <span className={'footer__content-block__text'}>{t("Контакти")}</span>

                  <div className={'footer__content-block__content'}>
                     <div className={'footer__content-block footer__content-block--contacts__contacts-text'}>
                        <FooterContactsSection />
                     </div>

                     <div className={'footer__content-block footer__content-block--contacts__rights-text'}>
                        © {format(new Date(), 'yyyy')} <br />
                        {t("Всі права захищено. Використання матеріалів цього сайту можливе тільки з посиланням на джерело")}
                     </div>

                     <div className={'footer__register-link-container'}>
                        <AppButton theme={'outline'} fullWidth to={RouteNames.HOTEL_REGISTRATION}>
                           {t("Реєстрація готелів")}
                        </AppButton>
                     </div>
                     <div className={'footer__extranet-link'}>
                        <AppButton
                           target="_blank"
                           theme={'outline-primary'}
                           fullWidth
                           href={'https://extranet.bronui.com/'}
                        >
                           {t("Вхід в екстранет")}
                        </AppButton>
                     </div>
                     <MobileOnly>
                        <div style={{ marginTop: '20px' }}>
                           <FooterSiteLogo />
                           <div>{t("Бронюй тут")}</div>
                        </div>
                     </MobileOnly>
                  </div>
               </div>
            </div>
         </Container>
      </footer>
   );
};

export default Footer;
