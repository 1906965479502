import React, { FC, useState } from 'react';
import { Element } from 'react-scroll';
import './HotelDescription.scss';
import striptags from 'striptags';

import Stars from '../../../../../components/ui/icons/hotels/Stars';
import HotelRoomsSearch from '../HotelRoomsSearch/HotelRoomsSearch';
import HotelDetailGallery from '../HotelDetailGallery/HotelDetailGallery';
import useIsOverflow from '../../../../../hooks/useIsOverflow';
import ShowMoreButton from '../ShowMoreButton/ShowMoreButton';
import HotelRoomSearchMobile from '../HotelRoomSearchMobile/HotelRoomSearchMobile';
import { Hotel } from '../../../../../types/hotel';
import { MobileOnly, TabletAndUpper } from '../../../../../components/common/Media/MediaHelpers';
import Modal from '../../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary
    from '../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import HotelDetailUserActions from '../HotelDetailUserActions/HotelDetailUserActions';
import HotelBookSection from '../HotelBookSection/HotelBookSection';
import { useTranslation } from "react-i18next";

interface Props {
    hotel: Hotel
}

const HotelDescription: FC<Props> = ({hotel}) => {
    const {
        id,
        isFavorite,
        address,
        name,
        photo,
        photos,
        description,
        stars = 0,
        accommodationType,
        placeName,
        distanceToPlaceFormatted
    } = hotel;
    const [t] = useTranslation();
    const [showMore, setShowMore] = useState(false);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const ref = React.useRef();

    useIsOverflow(ref, (isOverflow: boolean) => {
        setShowMoreButton(isOverflow);
    });
    const title = <h1 className="hotel-description__name">{name}</h1>;

    const titleAddressRow = (
        <div className="hotel-description__block-stars-address">
            <div className="hotel-description__top-row">
                {stars ? <div className="hotel-description__top-row-stars-wrapper"><Stars stars={stars}/></div> : null}

                {/*<AppMedia.Media at='sm' className={'hotel-description__top-row-texts-wrapper'}>*/}
                {/*    <span className="hotel-description__top-row-texts">*/}
                {/*        /!*<span>Відгуки • {reviewsCount}</span>*!/*/}

                {/*        <span>{distanceToPlaceFormatted}</span>*/}
                {/*    </span>*/}
                {/*</AppMedia.Media>*/}

                <div className="hotel-description__info-texts">
                    {accommodationType?.data?.name && (
                        <div className={'hotel-description__accommodation-content'}>
                            {accommodationType?.data?.icon && <img src={accommodationType?.data?.icon} alt={''}/>}
                            <span className="hotel-description__info-text">{accommodationType?.data?.name}</span>
                        </div>
                    )}

                    <TabletAndUpper>
                        <span className="hotel-description__info-text">
                            Адреса: {address}
                        </span>
                    </TabletAndUpper>

                    {(distanceToPlaceFormatted || placeName) && (
                        <span className="hotel-description__info-text hotel-description__info-text--with-dot">
                            {distanceToPlaceFormatted}{placeName && `, ${placeName}`}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <div className="hotel-description">
            <div className="hotel-description__header">
                <MobileOnly>
                    <HotelDetailUserActions
                        hotelId={id}
                        isFavorite={isFavorite}
                        name={name}
                        hotelPhoto={photo?.source?.['302x170']}
                    />
                </MobileOnly>

                <TabletAndUpper>
                    {title}

                    <div className="hotel-description__header-info-row">
                        {titleAddressRow}

                        <HotelDetailUserActions
                            hotelId={id}
                            isFavorite={isFavorite}
                            name={name}
                            hotelPhoto={photo?.source?.['302x170']}
                        />
                    </div>
                </TabletAndUpper>

                <HotelDetailGallery photos={photos} hotelAlias={hotel.alias}/>

                <MobileOnly>
                    {title}
                    {titleAddressRow}
                </MobileOnly>
            </div>

            <div className="hotel-description__description-and-form-row">
                <div className="hotel-description__description-wrap">
                    <div className={`hotel-description__description`} ref={ref}>
                        <MobileOnly>
                            {striptags(description)}
                        </MobileOnly>

                        <TabletAndUpper>
                            <div dangerouslySetInnerHTML={{__html: description}}/>
                        </TabletAndUpper>
                    </div>

                    {(showMoreButton) ? (
                        <div className={`hotel-description__show-more-wrapper ${showMore ? 'is-open-more' : ''}`}>
                            <ShowMoreButton
                                onClick={() => setShowMore(!showMore)}
                                isShowingMore={showMore}
                            />
                        </div>
                    ) : null}
                </div>

                {showMore && (
                    <Modal
                        className={'hotel-description-about-modal'}
                        onClose={() => setShowMore(false)}
                    >
                        <ModalLayoutPrimary title={t("Про цей готель")}>
                            <h3 className={'hotel-description-about-modal__title'}>{t("Про готель")} {name}</h3>
                            <div className={'hotel-description-about-modal__text'}>
                                <div dangerouslySetInnerHTML={{__html: description}}/>
                            </div>
                        </ModalLayoutPrimary>
                    </Modal>
                )}

                <Element name='hotelDetailSearchForm'>
                    <MobileOnly>
                        <HotelRoomSearchMobile hotelName={hotel?.name}/>
                        {/*<HotelBookSection isMobile/>*/}
                    </MobileOnly>

                    <TabletAndUpper>
                        <HotelRoomsSearch/>
                    </TabletAndUpper>
                </Element>
            </div>
        </div>
    );
};

export default React.memo(HotelDescription);
