import { createAsyncThunk } from '@reduxjs/toolkit';
import accountOrderDetailApi from '../../../api/account-orders-detail-api';
import appToastr from '../../../services/appToastr';
import i18n from "../../../i18n";

export const getAccountOrdersDetailThunk = createAsyncThunk(
   'accountOrdersDetail/getAccountOrdersDetailThunk',
   async (id: number, { rejectWithValue }) => {
      try {
         const response = await accountOrderDetailApi.fetchOrdersDetail(id);
         return response.data;
      } catch (error) {
         throw rejectWithValue(error);
      }
   },
);


export const cancelAccountOrdersDetailThunk = createAsyncThunk(
   'accountOrdersDetail/cancelAccountOrdersDetailThunk',
   async (id: number, { rejectWithValue }) => {
      try {
         const response = await accountOrderDetailApi.cancelOrder(id);
         appToastr.success(i18n.t('Успішно скасовано'))
         return response.data;
      } catch (error) {
         appToastr.error(i18n.t('Не вдалось скасувати'))
         throw rejectWithValue(error);
      }
   },
);


export const cancelAccountOrdersReservationDetailThunk = createAsyncThunk(
   'accountOrdersDetail/cancelAccountOrdersReservationDetailThunk',
   async ({reservationId, orderId}: {reservationId: number, orderId: number}, { rejectWithValue }) => {
      try {
         const response = await accountOrderDetailApi.cancelReservation(orderId, reservationId);
         appToastr.success(i18n.t('Успішно скасовано'))
         return response.data;
      } catch (error) {
         appToastr.error(i18n.t('Не вдалось скасувати'))
         throw rejectWithValue(error);
      }
   },
);