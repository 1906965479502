import React, {FC} from "react";
import {numberToArray} from "../../../../../../helpers/common";
import MuiFieldSelect from "../../../../../../components/ui/forms/muiFields/MuiFieldSelect";
import { useTranslation } from "react-i18next";

interface Props {
  kids: Array<string | number>
  onChange: (value: string, index: number) => void;
}

const HotelKidsAgeList: FC<Props> = (props) => {
  const {kids, onChange} = props;
  const [t] = useTranslation()

  if (kids.length) {
    return (
      <div className="kids-age-select">
        <ul>
          {
            kids.map((item, index) => {
              // console.log("item",item);
              return (
                <li key={index}>
                  <MuiFieldSelect
                    label={`${t("Вік дитини")} ${index + 1}`}
                    // @ts-ignore
                    value={item}
                    onChange={(e) => {
                      onChange(e.target.value, index)
                    }}>
                    {
                      numberToArray(10).map((age,index) => {
                        return (
                          <option key={index} value={age}>{t("n рік", {count: Number(age)})}</option>
                        )
                      })
                    }
                  </MuiFieldSelect>
                </li>
              )
            })
          }
        </ul>

        <div className="kids-age-select__info">
          {t("Щоб знайти відповідне для всієї вашої групи помешкання з коректними цінами, укажіть вік ваших дітей на момент виїзду")}
        </div>
      </div>
    )
  }
  return null
}

export default HotelKidsAgeList
