import React, { useState } from 'react';
import { FieldArray } from 'formik';
import './FiltersCheckboxesSection.scss';

import HotelFiltersRow from '../HotelFiltersRow/HotelFiltersRow';
import FieldCheckbox from '../../../../../../../components/ui/forms/fields/FieldCheckbox/FieldCheckbox';
import HotelFilterContentBlock from '../HotelFilterContentBlock/HotelFilterContentBlock';
import ShowMoreButton from '../../../../../HotelDetail/components/ShowMoreButton/ShowMoreButton';
import { FilterGroupItemPrepared } from '../../../../../../../types/hotel-filters';
import { useTranslation } from "react-i18next";

type Props = {
    items: FilterGroupItemPrepared[],
    title: string,
    values: any[],
    arrayName: string
}

const perPage = 10;

const FiltersCheckboxesSection: React.FC<Props> = ({items, title, values, arrayName}) => {
    const [isFullListShown, setIsFullListShown] = useState(false);
    const [itemsToShow, setItemsToShow] = useState(4);
    const [t] = useTranslation();

    const listItemsToShow = isFullListShown ? items : items.slice(0, itemsToShow);
    const showMoreButton = listItemsToShow.length < items.length;
    const difference = items.length - listItemsToShow.length;
    const showMoreCount = difference >= perPage ? perPage : difference;

    return (
        <div className="filter-checkboxes-section">
            <FieldArray
                name={arrayName}
                render={({push, remove}) => (
                    <HotelFilterContentBlock title={title}>
                        <div className='hotels-filter__content'>
                            {listItemsToShow.map((item, index) => {
                                if (!item) return null;

                                const id = String(item?.id);
                                const labelId = `${arrayName}.properties[${index}]`;

                                return (
                                    <HotelFiltersRow
                                        label={item.label}
                                        htmlFor={labelId}
                                        key={'restriction' + id + index}
                                    >
                                        <FieldCheckbox
                                            id={labelId}
                                            value={id}
                                            onChange={() => {
                                                if (values.includes(id)) remove(values.indexOf(id))
                                                else push(id);
                                            }}
                                            checked={values.includes(id)}
                                        />
                                    </HotelFiltersRow>
                                )
                            })}
                        </div>

                        {showMoreButton && (
                            <ShowMoreButton
                                onClick={() => {
                                    setItemsToShow(prevVal => prevVal + perPage);
                                }}
                                texts={[t("Показати ще", {count: showMoreCount}), '']}
                                isShowingMore={false}
                            />
                        )}
                    </HotelFilterContentBlock>
                )}
            />
        </div>
    )
};

export default FiltersCheckboxesSection;
