import React from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import FavoriteItem from './FavoriteItem/FavoriteItem';
import Container from '../../../components/ui/layouts/Container/Container';
import Page from '../../../components/common/Page/Page';
import PageHeader from '../common/PageHeader/PageHeader';
import './AccountFavorite.scss';
import { wishListSelector } from '../../../store/account/favorite-group-list/favorite-group-list-selectors';
import { getFavoriteGroupThunk } from '../../../store/account/favorite-group-list/favorite-group-list-actions';
import AccountListIsEmpty from '../common/AccountListIsEmpty/AccountListIsEmpty';
import { RouteNames } from '../../../routes';
import FavoriteContentLoader from "./components/FavoriteContentLoader/FavoriteContentLoader";
import { useTranslation } from "react-i18next";

const AccountFavorite = () => {

   const wishList = useSelector(wishListSelector);
   const [t] = useTranslation();

   const breadcrumbs = [
      {
         url: RouteNames.PROFILE_INDEX,
         title: t("Акаунт"),
      },
      {
         title: t("Обране"),
      },
   ];

   const renderView = () => {
      if (!wishList.isFetching && wishList.data.length) {
         return (
            <div className="favorite__list">
               {wishList.data.map((item, index) => {
                  return <FavoriteItem key={index} favoriteItem={item} />;
               })}
            </div>
         );
      } else if (!wishList.isFetching && !wishList.data.length) {
         return <AccountListIsEmpty title="" description={t("Створіть свій перший список обраного")} additional={t("Під час пошуку натискайте значок серця, щоб зберігати улюблені готелі до обраного")}/>;
      } else {
         return  <FavoriteContentLoader/>;
      }
   };

   return (
      <Page>
         <Container>
            <PageHeader breadcrumbs={breadcrumbs} title={t("Обране")} />

            <div className="favorite">{renderView()}</div>
         </Container>


         {/*<FavoriteRoutes />*/}
      </Page>
   );
};

AccountFavorite.getInitialProps = ({ store, ...rest }) => {

   return store
      .dispatch(getFavoriteGroupThunk())
      .then(unwrapResult)
      .catch(error => {
         // this code will redirect to 404 page after any error
         return { statusCode: 404 };
      });
};

export default AccountFavorite;
