import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/uk';
import 'dayjs/locale/ru';

import { format } from "date-fns";
// import {store} from "../../client";
import ukLocale from "date-fns/locale/uk";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-GB";
import roLocale from "date-fns/locale/ro";
import plLocale from "date-fns/locale/pl";
import ruLocale from "date-fns/locale/ru";
import i18n from "../../common/i18n";
import { defaultLanguage } from '../config';

// import {preloadedInitialLanguage} from "../../client";
dayjs.locale(i18n.language);
dayjs.extend(customParseFormat)

// const locale = 'uk';
//
// import(`dayjs/locale/${locale}`)
//     .then(() => {
//         dayjsInstance.locale(locale);
//     });

export function formatDate(date: Date | number, dateFormat: string, options?: any) {
    const localString = i18n.language || defaultLanguage;

    let locale;

    switch (localString) {
        case 'uk':
            locale = ukLocale;
            break;
        case 'de':
            locale = deLocale;
            break;
        case 'en':
            locale = enLocale;
            break;
        case 'ro':
            locale = roLocale;
            break;
        case 'pl':
            locale = plLocale;
            break;
        case 'ru':
            locale = ruLocale;
            break;
        default:
            locale = enLocale;
    }

    return format(date, dateFormat, {
        locale: locale,
        ...options
    })
}

export const _formatDate = (dateString: any, format: string, options?: any) => {
    const localString = i18n.language || defaultLanguage;
    dayjs.locale(localString);
    return dayjs(dateString, options).format(format);
}

export const formatDateStringToDateObject = (date: string) => {
    return dayjs(date).toDate();
}

export const formatDateFromString = (date: string, formatTo): string => {
    dayjs.locale(i18n.language);
    return dayjs(date, 'YYYY-MM-DDTHH:mm:ss').format(formatTo);
}

export const getDifferenceInNights = (date1, date2) => {
    return dayjs(date2).diff(dayjs(date1), 'day');
}

export const durationFormat = (start: string, end: string) => {
    return `${formatDate(new Date(start), 'dd')}-${formatDate(new Date(end), 'dd MMMM')}`
}
