import React, { useState } from 'react';
import ClickableBlock from '../../../../components/ui/components/ClickableBlock/ClickableBlock';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import ReservationConditionsModal from './ReservationConditionsModal';
import { useTranslation } from "react-i18next";

type Props = {}

const OrderPageInfoClickableBlocks: React.FC<Props> = () => {
    const [showRulesModal, setShowRulesModal] = useState(false);
    const order = useAppSelector(state => state.order);
    const conditionsText = order.serverOrderData.data.reservationConditions;
    const [t] = useTranslation();

    return (
        <>
            <div className={'order-page__order-info-clickable-blocks'}>
                {conditionsText && (
                    <ClickableBlock
                        onClick={() => setShowRulesModal(true)}
                        title={t("Умови бронювання")}
                    />
                )}

                {/*<ClickableBlock*/}
                {/*    onClick={() => {}}*/}
                {/*    title={'Політика відміни'}*/}
                {/*    description={'Ви зможете частково повернути кошти. Якщо \n' +*/}
                {/*    'ви скасуєте замовлення з вас буде стягнуто \n' +*/}
                {/*    'вартість першої ночі'}/>*/}

                {/*<ClickableBlock*/}
                {/*    onClick={() => {}}*/}
                {/*    title={'Умови бронювання та правила готелі'}*/}
                {/*    description={'Ви зможете частково повернути кошти. Якщо \n' +*/}
                {/*    'ви скасуєте замовлення з вас буде стягнуто \n' +*/}
                {/*    'вартість першої ночі'}*/}
                {/*/>*/}
            </div>

            {conditionsText && showRulesModal && (
                <ReservationConditionsModal onClose={() => setShowRulesModal(false)}/>
            )}
        </>
    )
};

export default OrderPageInfoClickableBlocks;
