import React from 'react';
import './HotelDetailOrderSummarySearchInfo.scss';

import { createBemClassProp } from '../../../../../../../helpers/common';
import useSearchForm from '../../../../../common/HotelSearchForm/hooks/useSearchForm';
import { DATE_FORMAT_CLIENT_SHORT, DATE_FORMAT_CLIENT_SHORT_DATEFNS } from '../../../../../../../config';
import { formatDate } from '../../../../../../../helpers/date';
import IconEdit from '../../../../../../../components/ui/icons/icons/IconEdit';
import IconCalendar from '../../../../../../../components/ui/icons/icons/IconCalendar';
import useWindowSize from '../../../../../../../hooks/useWindowSize';
import IconPerson from '../../../../../../../components/ui/icons/icons/IconPerson';
import { pluralize } from '../../../../../../../helpers/translatioHelpers';
import { useTranslation } from "react-i18next";

type Props = {
    onClickOnEdit: () => void,
    fromPhonesSection?: boolean
}

const HotelDetailOrderSummarySearchInfo: React.FC<Props> = ({onClickOnEdit, fromPhonesSection}) => {
    const {guestsParams, startDate, endDate} = useSearchForm();
    const cn = createBemClassProp(`hotel-detail-order-summary-search-info`);
    const [t] = useTranslation();
    const startDateFormatted = formatDate(startDate, DATE_FORMAT_CLIENT_SHORT_DATEFNS);
    const endDateFormatted = formatDate(endDate, DATE_FORMAT_CLIENT_SHORT_DATEFNS);
    const {adults, kids, rooms} = guestsParams;
    const {isMobile} = useWindowSize();

    return (
        <div {...cn('', fromPhonesSection ? 'from-phones-section' : '')}>
            <div {...cn('title')}>
              {t("Ваш пошук")}

                <button onClick={onClickOnEdit} {...cn('edit-button')}>
                    <IconEdit size={isMobile ? 24 : 14}/>
                </button>
            </div>

            <div {...cn('info-row')}>
                <IconCalendar/>

                <span>{startDateFormatted} - {endDateFormatted}</span>
            </div>


            <div {...cn('info-row')}>
                <IconPerson/>

                <span>
                    {pluralize('дорослий', adults)}

                    {kids.length ? ', ' + pluralize('дитина', kids.length) : ''}

                    {!fromPhonesSection ? ', ' + pluralize('номер', rooms) : ''}
                </span>
            </div>
        </div>
    )
};

export default HotelDetailOrderSummarySearchInfo;
