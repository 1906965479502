import React, { FC } from 'react';
import './ModalEditGroupName.scss';
import Modal from '../../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary from '../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import { Form, Formik } from 'formik';
import { editFavoriteGroupName } from '../../../../../helpers/validation';
import AppFormInput from '../../../../../components/ui/forms/controls/AppFormInput';
import { FormikHelpers } from 'formik/dist/types';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';
import { useSelector } from 'react-redux';
import {
   createNewWishGroupThunk,
   deleteWishGroupThunk,
   updateWishGroupThunk,
} from '../../../../../store/account/favorite-group-list/favorite-group-list-actions';
import { wishListSelector } from '../../../../../store/account/favorite-group-list/favorite-group-list-selectors';
import { setToWishListThunk } from '../../../../../store/hotels/hotels-thunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../../../hooks/reduxHooks';
import { useTranslation } from "react-i18next";

interface Props {
   onClose?: () => void;
   id?: number;
   hotelId?: number;
}

interface Values {
   name: string;
}

//updateWishGroupThunk

const ModalEditGroupName: FC<Props> = ({ onClose, id, hotelId }) => {
   // const { id } = useParams<{ id?: string }>();
   const wishList = useSelector(wishListSelector);
   const [t] = useTranslation();
   const { isSending, isDeleting } = wishList;
   const current = wishList.data.find(item => item.id === Number(id));
   const initialValues: Values = {
      name: current?.name,
   };

   const dispatch = useAppDispatch();

   const handleSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => {
      if (id) {
         dispatch(updateWishGroupThunk({ ...values, id: Number(id) })).then(() => {
            if (onClose) onClose();
         });
      } else {
         dispatch(createNewWishGroupThunk(values))
            .then(unwrapResult)
            .then(data => {
               toWishList(data.id);
               // save to this list
               // if (onClose) onClose();
            });
      }
   };

   const handleRemove: React.MouseEventHandler = e => {
      e.preventDefault();
      //@ts-ignore
      dispatch(deleteWishGroupThunk(Number(id))).then(() => {
         if (onClose) onClose();
         // console.log('remove');
      });
   };

   // select
   const toWishList = (groupId: number) => {
      dispatch(
         setToWishListThunk({
            favorites_group_id: groupId,
            hotel_id: hotelId,
            isFavorite: true,
         }),
      );

      // якщо запрос успішний тоді закриваю вікно
      onClose();
   };

   return (
      <Modal className="modal-favorite-edit" onClose={onClose}>
         <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={editFavoriteGroupName}
            enableReinitialize
         >
            <Form>
               <ModalLayoutPrimary
                  title={t("Налаштування")}
                  Footer={() => {
                     return (
                        <div>
                           {id ? (
                              <div className="modal-buttons">
                                 <AppButton
                                    onClick={handleRemove}
                                    className="modal-buttons__cancel"
                                    showIndicator={isDeleting}
                                 >
                                    {t("Видалити")}
                                 </AppButton>
                                 <AppButton size="middle" showIndicator={isSending}>
                                    {t("Зберегти")}
                                 </AppButton>
                              </div>
                           ) : (
                              <AppButton size="middle" showIndicator={isSending} style={{ width: '100%' }}>
                                 {t("Створити")}
                              </AppButton>
                           )}
                        </div>
                     );
                  }}
               >
                  <div className="modal-favorite-edit__body">
                     <AppFormInput name="name" label={t("Назва списку обраного")} />
                  </div>
               </ModalLayoutPrimary>
            </Form>
         </Formik>
      </Modal>
   );
};

export default ModalEditGroupName;
