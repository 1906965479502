import React, { FC } from 'react';
import '../ui/icons/hotels/HotelsIcons.scss';
import Stars from '../ui/icons/hotels/Stars';
import { useTranslation } from "react-i18next";

interface Props {
   stars: number;
   reviews: number;
   distance?: string;
}

/**
 @deprecated
*/
const StarReviewDistance: FC<Props> = props => {
   const { stars, reviews, distance } = props;
  const [t] = useTranslation();

   return (
      <div className="star-review-distance-block">
         <div className="star-review-distance-block__stars">
            <Stars stars={stars} />
         </div>
         <div className="star-review-distance-block__reviews">{t("Відгуки")}・{reviews}</div>
         <div className="star-review-distance-block__distance">{distance && <span>{distance} {t("км від центра")}</span>}</div>
      </div>
   );
};

export default StarReviewDistance;
