import React, { useState } from 'react';
import './MobileRoomListItem.scss';

import { RoomItemPrepared } from '../../../../../../../../types/rooms';
import CustomCarousel from '../../../../../../../../components/ui/components/CustomCarousel/CustomCarousel';
import { createBemClassProp } from '../../../../../../../../helpers/common';
import AppButton from '../../../../../../../../components/ui/components/Buttons/AppButton/AppButton';
import useSearchForm from '../../../../../../common/HotelSearchForm/hooks/useSearchForm';
import { pluralize } from '../../../../../../../../helpers/translatioHelpers';
import MobileRoomsInfoLineTexts from '../../../MobileRoomsInfoLineTexts/MobileRoomsInfoLineTexts';
import useOrderLogic from '../../../../../../../../hooks/useOrderLogic';
import MobileRoomSelect from '../../../MobileRoomSelect/MobileRoomSelect';
import { ReservationItem } from '../../../../../../../../types/order';
import RoomItemPersons
    from '../../../../../components/HotelRooms/components/HotelRoomItem/components/RoomItemPersons/RoomItemPersons';
import MobileRoomListItemPrice from './components/MobileRoomListItemPrice/MobileRoomListItemPrice';
import useRoomsLogic from '../../../../../useRoomsLogic';
import NoRoomsMessage
    from '../../../../../components/HotelRooms/components/HotelRoomItem/components/NoRoomsMessage/NoRoomsMessage';
import { BookingSectionState } from '../../../../../../../../types/hotel';
import { useTranslation } from "react-i18next";

type Props = {
    room: RoomItemPrepared,
    onSelectRoom: () => void,
    onFocusOnEditForm,
    bookingState: BookingSectionState
}

const MobileRoomListItem: React.FC<Props> = ({room, onSelectRoom, onFocusOnEditForm, bookingState}) => {
    const cn = createBemClassProp('mobile-room-list-item');
    const {nights} = useSearchForm();
    const {showDisabledPricesWithMessage, showNoPricesMessageWithButton} = useRoomsLogic(room);
    const [t] = useTranslation();
    const {addRoomToOrder, getReservationsSum, getRoomReservations} = useOrderLogic();
    const [showMobileRoomsSelect, setShowMobileRoomsSelect] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState<any>();

    const roomReservations = getRoomReservations(room.id);

    const PhotoItem = React.memo((data: any) => (
        <div className={'gallery-image'}
             style={{backgroundImage: `url(${data?.data?.source?.["302x170"]})`}}
             onClick={onSelectRoom}/>
    ));

    const Carousel = React.memo(() => (
        <CustomCarousel
            Item={PhotoItem}
            data={room.photos}
            settings={{}}
        />), () => true)

    const defaultPrice = room?.prices?.data?.[0];
    const showReservations = !!roomReservations.length && (bookingState === 'cart' || bookingState === 'both');

    return (
        <div {...cn()}>
            <Carousel/>

            <h3 {...cn('title')} onClick={onSelectRoom}>{room.name}</h3>

            <MobileRoomsInfoLineTexts
                roomsText={room.rooms}
                bathroomsText={room.bathrooms}
                square={room.square}
                roomPersonsData={<RoomItemPersons basePlaces={room.basePlaces} extraPlaces={room.extraPlaces}/>}
                isInList
            />

            {showDisabledPricesWithMessage ? <NoRoomsMessage text={room?.message?.data?.text}/> : null}

            {showNoPricesMessageWithButton && bookingState === 'cart' ?
                <NoRoomsMessage
                    text={room?.message?.data?.text}
                    onButtonClick={onFocusOnEditForm}
                    //variant={'with-button'}
                /> : null
            }

            {showNoPricesMessageWithButton && bookingState === 'cart' && (
                <AppButton
                    theme={'outline'}
                    onClick={onSelectRoom}
                    {...cn('show-room-button')}
                >
                  {t("Переглянути номер")}
                </AppButton>
            )}

            <div {...cn('footer')}>
                {/*{!showNoPricesMessageWithButton && (*/}
                {/*    <MobileRoomListItemPrice*/}
                {/*        totalPrice={defaultPrice.totalPrice}*/}
                {/*        nights={nights}*/}
                {/*        oldPrice={defaultPrice.fullPrice}*/}
                {/*        disabled={showDisabledPricesWithMessage}*/}
                {/*        mealTypeName={defaultPrice.mealType.name}*/}
                {/*        onSelectRoom={onSelectRoom}*/}
                {/*    />*/}
                {/*)}*/}

                {showReservations ? (
                    <div {...cn('footer-prices-list')}>
                        {roomReservations.map((reservation: ReservationItem, index) => (
                            <div key={'roomReservationsMobileItem' + reservation.id + index} className={''}>
                                <MobileRoomListItemPrice
                                    totalPrice={reservation.price.totalPrice}
                                    nights={nights}
                                    mealTypeName={reservation.mealTypeName}
                                />

                                <AppButton theme={'outline'} onClick={() => {
                                    setShowMobileRoomsSelect(true);
                                    setSelectedPrice(reservation.price);
                                }}>
                                    {pluralize('номер', getReservationsSum(room.id, reservation.price.rateId))}
                                </AppButton>
                            </div>
                        ))}
                    </div>
                ) : (
                    <>
                        {defaultPrice && (
                            <MobileRoomListItemPrice
                                totalPrice={defaultPrice.totalPrice}
                                nights={nights}
                                oldPrice={defaultPrice.fullPrice}
                                disabled={showDisabledPricesWithMessage}
                                mealTypeName={defaultPrice.mealType.name}
                                onSelectRoom={onSelectRoom}
                            />
                        )}
                    </>
                )}
            </div>

            {showMobileRoomsSelect && (
                <MobileRoomSelect
                    maxRooms={room.availableRoomsCount}
                    selectedCount={getReservationsSum(room.id, selectedPrice?.rateId)}
                    onClose={() => setShowMobileRoomsSelect(false)}
                    onSelect={(count) => {
                        addRoomToOrder(room, selectedPrice, count);
                        setSelectedPrice(null);
                        setShowMobileRoomsSelect(false);
                    }}
                />
            )}
        </div>
    )
};

export default React.memo(MobileRoomListItem);
