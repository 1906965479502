import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import OrderIndexDesktop from './OrderIndexDesktop';
import './OrderPage.scss';

import { useAppSelector } from '../../../hooks/reduxHooks';
import AppLoader from '../../../components/ui/loaders/AppLoader/AppLoader';
import Page from '../../../components/common/Page/Page';
import Container from '../../../components/ui/layouts/Container/Container';
import { MobileOnly, TabletAndUpper } from '../../../components/common/Media/MediaHelpers';
import { getOrderThunk } from '../../../store/order/order-thunk';
import OrderIndexMobile from './mobile/OrderIndexMobile';
import OrderCollisionModal from './components/OrderCollisionModal/OrderCollisionModal';
import IconArrowLeft from '../../../components/ui/icons/account/IconArrowLeft';
import useWindowSize from '../../../hooks/useWindowSize';
import { isClient } from '../../../helpers/common';
import { useDispatch } from 'react-redux';
import { clearPaymentServerData, setCatGetCartAfterPost } from '../../../store/order/order-reducer';
import OrderIsEmpty from './components/OrderIsEmpty/OrderIsEmpty';
import { RouteNames } from '../../../routes';
import appToastr from '../../../services/appToastr';
import { useTranslation } from "react-i18next";

const OrderPageBase = () => {
    const {serverOrderData, storingOnServerData, isLoading, canGetCartAfterPost} = useAppSelector(state => state.order);
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const {isMobile} = useWindowSize();
    const [mobilePageStep, setMobilePageStep] = useState<number>();
    const {paymentServerData, paymentServerDataError} = useAppSelector(state => state.order);
    const [t] = useTranslation();

    useEffect(() => {
        dispatch(getOrderThunk());
    }, [])

    useEffect(() => {
        if (!isLoading && !loaded) setLoaded(true);
    }, [isLoading])

    useEffect(() => {
        setMobilePageStep(isMobile ? 1 : null);
    }, [isMobile]);

    useEffect(() => {
        if (isClient) window.scrollTo({top: 0});
    }, [mobilePageStep]);

    const formRef = useRef();

    useEffect(() => {
        console.log(paymentServerData);

        if (paymentServerData?.redirect?.link) {
            if (paymentServerData?.redirect?.method === 'post') {
                setTimeout(() => {
                    if (formRef?.current?.submit) {
                        formRef.current.submit();
                    }
                }, 100)
            } else {
                window.location.href = paymentServerData.redirect.link;
            }
        } else {
            if (paymentServerData?.success) {
                history.push(`${RouteNames.ORDER_SUCCESS}?can-confirm=${paymentServerData?.canConfirm}`);
                sessionStorage.setItem('paymentServerData', JSON.stringify(paymentServerData));
            }
        }
    }, [formRef, paymentServerData]);

    useEffect(() => {
        if(paymentServerDataError) {
            const message = paymentServerDataError?.message;
            appToastr.clear();
            appToastr.error(`Помилка при оплаті ${message && ': ' + message}`);
            dispatch(clearPaymentServerData());
        }
    }, [paymentServerDataError])

    useEffect(() => {
        if (!canGetCartAfterPost) {
            setTimeout(() => {
                dispatch(setCatGetCartAfterPost(true));
            }, 3000);
        }
    }, [canGetCartAfterPost])

    const onMobileSubmit = () => {
        if (mobilePageStep === 1) setMobilePageStep(2);
    }


    const orderIsEmpty = !serverOrderData || typeof serverOrderData !== 'object';

    return (
        <Page
            className={`order-page ${isMobile ? 'order-page--mobile' : ''}`}
            hideSearchForm
            hideSearchHeader={isMobile}
        >
            {paymentServerData && paymentServerData?.redirect?.link && (
                <form action={paymentServerData?.redirect?.link} method="post" target="_self" ref={formRef}>
                    <input type="hidden" name="bodyRequest"
                           value={JSON.stringify(paymentServerData?.redirect?.data?.bodyRequest)}/>
                    <input type="hidden" name="typeRequest" value='json'/>

                    <input type="submit" value="Submit" style={{display: 'none'}}/>
                </form>
            )}

            <Container className={'order-page__container'}>
                {isLoading || !loaded ?
                    <div className={'order-page__container-loading'}><AppLoader full fixed={isMobile}/></div> : (
                        <>
                            {storingOnServerData && storingOnServerData?.success === false &&
                               <OrderCollisionModal storingOnServerData={storingOnServerData}/>}

                            <MobileOnly>
                                <div className={'order-page__mobile-header'}>
                                    <div className={'order-page__mobile-header-arrow'}>
                                        <IconArrowLeft onClick={() => {
                                            if (mobilePageStep === 2) setMobilePageStep(1);
                                            else history.goBack();
                                        }}/>
                                    </div>

                                    <h1>{t('Оформлення бронювання')}</h1>
                                </div>
                            </MobileOnly>

                            {orderIsEmpty
                                ? <OrderIsEmpty/>
                                : (
                                    <>
                                        <MobileOnly>
                                            <OrderIndexMobile
                                                step={mobilePageStep}
                                                serverOrderData={serverOrderData}
                                                onSubmit={onMobileSubmit}
                                            />
                                        </MobileOnly>

                                        <TabletAndUpper>
                                            <OrderIndexDesktop serverOrderData={serverOrderData}/>
                                        </TabletAndUpper>
                                    </>
                                )}
                        </>
                    )}
            </Container>
        </Page>
    )
};

OrderPageBase.getInitialProps = ({store}) => {
    // if (store?.getState()?.order?.canGetCartAfterPost) {
    //     return store
    //         .dispatch(getOrderThunk())
    //         .then(unwrapResult)
    //         .catch(error => {});
    // }
}

export default OrderPageBase;
