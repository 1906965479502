import React, { FC, useState } from 'react';
import './HotelRoomSearchMobile.scss';
import CustomDatepickerField from '../HotelRoomsSearch/components/CustomDatepickerField/CustomDatepickerField';
import useSearchForm from '../../../common/HotelSearchForm/hooks/useSearchForm';
import { formatDate } from '../../../../../helpers/date';
import SearchModalDates from '../../../common/mobile/SearchModalDates/SearchModalDates';
import SearchModalMain from '../../../common/mobile/SearchModalMain/SearchModalMain';
import { useHistory, useParams } from 'react-router-dom';
import FieldInput from '../../../../../components/ui/forms/fields/FieldInput/FieldInput';
import { useTranslation } from "react-i18next";

type Step = '' | 'dates' | 'guests';
type Props = {
    hotelName?: string
}

const HotelRoomSearchMobile: FC<Props> = ({hotelName}) => {
    const {
        placeObject,
        startDate,
        endDate,
        query,
        changeDates,
        guestsParams,
        changeKidsAge,
        selectChildren,
        changeGuestForm,

    } = useSearchForm();
    const [t] = useTranslation();
    const history = useHistory();
    const {alias = ''} = useParams<{ alias: string }>();

    const rangeEndString = endDate ? `${formatDate(new Date(endDate), 'EEEEEE, d MMMM')}` : '';
    const rangeStartString = startDate ? formatDate(new Date(startDate), 'EEEEEE, d MMMM') : '';
    const guestString = `${guestsParams.adults} ${t("дорослий", {count: Number(guestsParams.adults)})}, ${guestsParams.kids?.length} ${t("дитина", {count: guestsParams.kids?.length})}, ${guestsParams.rooms} ${t("номер", {count: Number(guestsParams.rooms)})}`;

    const [step, setStep] = useState<Step>('');

    // open modals
    const openDatesModal = () => {
        setStep('dates');
    };
    const openGuestsModal = () => {
        setStep('guests');
    };

    // close modal
    const onClose = () => {
        setStep('');
    };

    // submit
    const submit = () => {
        if (!startDate) {
            setStep('dates');
        } else if (!endDate) {
            setStep('dates');
        } else {
            history.replace(`/hotels/${alias}${query}`);
        }
        onClose();
    };

    return (
        <div className="hotel-rooms-search-mobile">
            <CustomDatepickerField
                start={rangeStartString}
                end={rangeEndString}
                onClickStart={openDatesModal}
                onClickEnd={openDatesModal}
            />

            <div className="hotel-rooms-search-mobile-form" onClick={openGuestsModal}>
                <label className="hotel-rooms-search-mobile-form__label">{t("Гості")}</label>
                <FieldInput
                    style={{zIndex: -1}}
                    value={guestString}
                    className="hotel-rooms-search-mobile-form__field"
                    disabled
                />
            </div>

            {step === 'dates' && (
                <SearchModalDates
                    start={startDate}
                    title={placeObject?.label}
                    end={endDate}
                    onSubmit={submit}
                    changeDates={changeDates}
                    onCloseModal={onClose}
                />
            )}

            {step === 'guests' && (
                <SearchModalMain
                    start={startDate}
                    end={endDate}
                    place={hotelName || placeObject?.label}
                    onClose={onClose}
                    onSubmit={submit}
                    guestsParams={guestsParams}
                    guestsMethods={{
                        changeKidsAge,
                        selectChildren,
                        changeGuestForm,
                    }}
                />
            )}
        </div>
    );
};

export default HotelRoomSearchMobile;
