import React, { FC } from 'react';
import IconHotelBackground from '../icons/IconHotelBackground';
import './NoFoundHotel.scss';
import { useTranslation } from "react-i18next";

interface IProps{
  searchPhone: string;
}

const NoFoundHotel:FC<IProps> = ({searchPhone}) => {
  const [t] = useTranslation();

   return (
      <div className="no-found-hotel">
         <IconHotelBackground />
         <h4>{t('Зареєструвати готель')} </h4>
         <h6>{t('Ми нічого не знайшли за номером', {phone: searchPhone})}</h6>
      </div>
   );
};

export default NoFoundHotel;
