import React, { FC } from 'react';
import './AccountOrdersDetailReservations.scss';
import ClickableArrowItem from '../../../../../../../components/common/ClickableArrowItem/ClickableArrowItem';
import TextWithDescription from '../../TextWithDescription/TextWithDescription';
import Accordion from '../../../../../../../components/ui/components/Accordion/Accordion';
import AccountOrdersDetailHotelItem from '../../AccountOrdersDetailHotelItem/AccountOrdersDetailHotelItem';
import { AccountOrdersReservationItem } from '../../../../../../../types/account-orders/account-orders-detail';
import { useAppDispatch } from '../../../../../../../hooks/reduxHooks';
import {
  cancelAccountOrdersReservationDetailThunk,
  getAccountOrdersDetailThunk
} from '../../../../../../../store/account/account-orders-detail/account-orders-detail-thunks';
import { useTranslation } from "react-i18next";


interface Props {
  reservations: Array<AccountOrdersReservationItem>;
  canCancel: boolean;
  status: {
    name: string;
    alias: 'cart' | 'confirmed' | 'partial_paid' | 'paid' | 'cancelled';
  };
  id: number;
}

const AccountOrdersDetailReservations: FC<Props> = props => {
  const { reservations, status, id, canCancel } = props;
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const handleCancel = reservationId => {
    dispatch(cancelAccountOrdersReservationDetailThunk({ reservationId, orderId: id })).then(() => {
      dispatch(getAccountOrdersDetailThunk(id))
    });
  };

  return (
    <div>
      {/* block */}
      <h4 className="account-orders-detail-subtitle">{t("Деталі бронювання")}</h4>
      <ul className="account-orders-detail-info-list">
        <li>
          <Accordion
            BodyComponent={() => {
              return (
                <>
                  {reservations.map(item => {
                    return (
                      <>
                        <AccountOrdersDetailHotelItem reservation={item} onCancel={handleCancel} canCancel={canCancel} key={item.id}/>
                      </>
                    );
                  })}
                </>
              );
            }}
            HeaderComponent={({ isOpen }) => {
              return (
                <ClickableArrowItem
                  arrowDirection={isOpen ? 'bottom' : 'right'}
                  content={
                    <TextWithDescription
                      text={t("Ви забронювали")}
                      description={`${reservations.length} ${t('номер', { count: reservations.length })}`}
                    />
                  }
                  onClick={() => {
                  }}
                />
              );
            }}
          />
        </li>

        <li style={{
          gap: 10,
          padding: '20px 0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <TextWithDescription text={t("Номер бронювання")} description={id}/>
          <div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default AccountOrdersDetailReservations;
