import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { setAxiosInstanceHeader, setContactToken } from '../helpers/network';
import { initialSelector } from '../store/initial/initial-selectors';
import {
  HTTP_HEADER_AUTHORIZATION,
  HTTP_HEADER_CONTACT_TOKEN,
  HTTP_HEADER_CURRENCY,
  HTTP_HEADER_LANGUAGE
} from '../config';

const useInitial = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['auth', 'Contact-Token']);
  const initial = useSelector(initialSelector);

  if (initial.language) {
    setAxiosInstanceHeader(HTTP_HEADER_LANGUAGE, initial.language);
  }


  if (initial.currency) {
    setAxiosInstanceHeader(HTTP_HEADER_CURRENCY, initial.currency);
  }

  if (cookies.auth) {
    setAxiosInstanceHeader(HTTP_HEADER_AUTHORIZATION, cookies.auth);
  }

  if (cookies?.[HTTP_HEADER_CONTACT_TOKEN]) {
    setContactToken(cookies[HTTP_HEADER_CONTACT_TOKEN], false);
    setAxiosInstanceHeader(HTTP_HEADER_CONTACT_TOKEN, cookies[HTTP_HEADER_CONTACT_TOKEN]);
  } else {
    setContactToken(null, false, false);
  }

  return {};
};

export default useInitial;
