import React, { FC, useState } from 'react';
import './ConversationsTabs.scss';
import AppButton from '../../../../components/ui/components/Buttons/AppButton/AppButton';

interface Props {
   activeTab?: 'left' | 'right'
   leftTextButton: string;
   rightTextButton: string;
   onClickRight: (e: HTMLDivElement) => void
   onClickLeft: (e: HTMLDivElement) => void
}

const ConversationsTabs: FC<Props> = props => {
   const {activeTab = 'left', leftTextButton, rightTextButton} = props;
   const [active, setActive] = useState<'left' | 'right'>(activeTab);


   const onClickLeft = (e) => {
      setActive('left')
      props.onClickLeft(e)
   }

   const onClickRight = (e) => {
      setActive('right')
      props.onClickRight(e)
   }
   return (
      <div className="conversations-navigation">
         {/*<div className="conversations-navigation__buttons">*/}
            <AppButton theme={active === 'left' ? 'primary' : 'link'} onClick={onClickLeft}>
               {leftTextButton}
            </AppButton>
            <AppButton
               theme={active === 'right' ? 'primary' : 'link'}
               onClick={onClickRight}
            >
               {rightTextButton}
            </AppButton>
         {/*</div>*/}
         {/*<button className="conversations-navigation__filter-button">*/}
         {/*   <IconFilter />*/}
         {/*</button>*/}
      </div>
   );
};

export default ConversationsTabs;
