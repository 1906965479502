import React from 'react';
import './NoReviews.scss';
import NoReviewsIcon from './NoReviewsIcon';
import { useTranslation } from "react-i18next";

type Props = {}

const NoReviews: React.FC<Props> = () => {
  const [t] = useTranslation();

    return (
        <div className={'no-reviews'}>
            <NoReviewsIcon/>

            <span>{t("Відгуки відсутні")}</span>
        </div>
    )
};

export default NoReviews;
